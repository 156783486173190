import React, { useCallback, useState, useEffect } from 'react';
import { Switch } from 'antd';

import {
  MachinesView,
  ContentContainer,
  Text,
  SelectDropDown,
} from './styles';
import {
  Title,
  SearchHeader,
  Modal,
  PaginatedList,
  MachineCard,
  SlotsFormList,
} from '../../components';
import { stringifyFilter } from '../../utils';
import { useAxios } from '../../services/api';
import { MachineProvider, MachineConsumer } from '../../state/MachineContext';
import { useUserInfo } from '../../state/UserContext';

function Machines() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);

  const [machineName, setMachineName] = useState('');
  const [code, setCode] = useState('');
  const [group, setGroup] = useState(null);
  const [company, setCompany] = useState(null);
  const [machineType, setMachineType] = useState(null);
  const [disponibility, setDisponibility] = useState(false);
  const [slots, setSlots] = useState([]);

  const [userInfo] = useUserInfo();

  const [reloadList, setReloadList] = useState(() => () => { });

  const [axiosGet, axiosPost] = useAxios('get', 'post');

  const handleSubmit = async () => {
    if (
      machineName === ''
      || code === ''
      || group === null
      || company === null
      || machineType === null
      || slots.length === 0
    ) {
      throw new Error('Campos faltando');
    }
    await axiosPost({
      url: '/machines',
      body: {
        name: machineName,
        code,
        groupId: group.value,
        companyId: company.value,
        type: machineType.value,
        available: disponibility,
        pageTopInterval: 15,
        fullscreenInterval: 30,
        slots: slots.map(({ name, size }) => ({
          name, size: size, itemId: null, quantity: 0,
        })),
      },
      success: reloadList,
    });
  };

  useEffect(() => {
    if (company !== null) {
      setGroup(null);
    }
  }, [company]);

  const loadOptions = useCallback((companyId) => (async (inputValue, callback) => {
    const res = await axiosGet({ url: `/groups?filter=${JSON.stringify({ companyId })}` });

    const options = res.data?.data.map(({ _id, name }) => ({ label: name, value: _id }));

    callback(options);
  }), [axiosGet]);

  const filterArr = stringifyFilter(filter || []);
  const filterUrl = filter ? `${filterArr.join('&')}` : '';

  const url = `/machines?${filterUrl}&search=${search}`;

  const { create } = userInfo?.permissions.machine || {};

  return (
    <MachineProvider>
      <MachinesView>
        <Title title="Operações" />
        <ContentContainer>
          <SearchHeader
            filter={filter}
            setFilter={setFilter}
            setSearch={setSearch}
            filterOptions={['Ordenar A-Z']}
            type="machine"
            buttonValue="Criar Novo"
            hasButton={create}
            q
          >
            {(active, setActive) => (
              <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
                <Modal.Title>Criação de Operação</Modal.Title>
                <Modal.Input value={machineName} setValue={setMachineName} placeholder="Nome da Operação" />
                <Modal.Input value={code} setValue={setCode} placeholder="Código" />
                <MachineConsumer>
                  {([machineInfo]) => (
                    <>
                      <Modal.Select
                        value={company}
                        setValue={setCompany}
                        options={machineInfo?.companies}
                        placeholder="Selecione a empresa"
                      />
                      <SelectDropDown active={company !== null}>
                        {company && (
                          <Modal.SearchSelect
                            isSearchable={false}
                            value={group}
                            onChange={setGroup}
                            loadOptions={loadOptions(company.value)}
                            defaultOptions={loadOptions(company.value)}
                            placeholder="Nome do Grupo"
                          />
                        )}
                      </SelectDropDown>
                      <Modal.Select
                        value={machineType}
                        setValue={setMachineType}
                        options={[{ label: 'Totemach', value: 'Totemach' }, { label: 'Vendmach', value: 'Vendmach' }]}
                        placeholder="Selecione o modelo"
                      />
                    </>
                  )}
                </MachineConsumer>
                <div style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '.9rem 0',
                }}
                >
                  <Text>
                    Available
                  </Text>
                  <Switch checked={disponibility} onClick={setDisponibility} />
                </div>
                <SlotsFormList slots={slots} setSlots={setSlots} />
                <Modal.Button>Finalizar</Modal.Button>
              </Modal>
            )}
          </SearchHeader>
          <PaginatedList
            component={MachineCard}
            url={url}
            itemsPerPage={10}
            options
            setReloadList={setReloadList}
            process={useCallback(async (data) => {
              const groupIds = data.map(({ groupId }) => axiosGet({ url: `/groups/${groupId}` }));
              const companyIds = data.map(({ companyId }) => axiosGet({ url: `/companies/${companyId}` }));

              const [groupRes, companyRes] = await Promise.all([
                Promise.all(groupIds),
                Promise.all(companyIds),
              ]);

              const groupNames = groupRes.map((res) => res.data.data.name);
              const companyNames = companyRes.map((res) => res.data.data.name);

              const newData = data.map((machine, i) => ({
                ...machine,
                groupName: groupNames[i],
                companyName: companyNames[i],
                machineTypeName: machine.type,
              }));

              return newData;
            }, [axiosGet])}
          />
        </ContentContainer>
      </MachinesView>
    </MachineProvider>
  );
}

export default Machines;
