import styled from 'styled-components';
import { Collapse, Select, InputNumber } from 'antd';

import Colors from '../../styles/Colors';

const { Panel } = Collapse;

const MobileCollapse = styled(Collapse)`
  width: 100%;
  display: none;

  @media(max-width: 1024px) {
    display: block;
  }
`;

const MobilePanel = styled(Panel)`
  .ant-collapse-header {
    font-weight: bolder;
    font-size: 1rem;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const WebNavigation = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media(max-width: 1024px) {
    display: none;
  }
`;

const MachineSection = styled.section`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5.5rem;
  padding: .8rem 1.2rem;
  position: relative;
  z-index: 101;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: ${({ link }) => (link ? 'none' : 'unset')};

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

const Image = styled.img`
  margin: .6rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: ${Colors.secondaryBlack};
  height: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  ${({ machineName }) => machineName && `
    width: 95%;
    display: flex;
    align-items: center;
  `}
`;

const Dash = styled.div`
  width: 6.6rem;
  height: .2rem;
  background-color: ${Colors.secondaryBlue};
`;


const Container = styled.section`
  height: calc(100vh - 5.5rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-flow: column;
`;

const Alternator = styled.section`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-flow: column;
  flex-grow: 1;
  flex: 1;
  overflow-y: auto;
  box-shadow: 0 0 .5rem 0 ${Colors.mainBlack};
`;

const NavHeader = styled.header`
  border-bottom: 1px solid ${Colors.blue};
  width: 100%;
  padding: 0 2.5%;
  display: flex;
  height: 4rem;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`;

const Tab = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  background-color: transparent;
  flex: 1;
  max-width: 10rem;
  border-left: solid 1px ${Colors.mainBlack};
  outline: none;

  &:first-child {
    border-left: none;
  }

  @media only screen and (max-width: 600px) {
    max-width: unset;
  }
`;

const Text = styled.p`
  color: black;
  padding: 1.3rem;
  font-weight: ${({ active }) => active && 'bolder'};
  width: 100%;
  margin: 0;
`;

const Indicator = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${Colors.blue};
  height: .2rem;
  transition: max-height .1s ease-in-out;
  max-height: ${({ active }) => (active ? '.2rem' : '0rem')};
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export {
  MachineSection,
  Button,
  Header,
  Image,
  Title,
  TitleContainer,
  Dash,
  Container,
  Alternator,
  NavHeader,
  Tab,
  Text,
  Indicator,
  MobileCollapse,
  MobilePanel,
  WebNavigation,
};
