import React, { useState, useRef, useEffect } from 'react';

import {
  Header,
  NewButton,
  OptionsContainer,
  InputContainer,
  Input,
  Button,
  Image,
  Text,
  Options,
  OptionButton,
  ContentContainer,
  Filter,
  FilterItem,
} from './styles';
import SectionFilters from '../SectionFilters';
import { useUserInfo } from '../../state/UserContext';

import searchIcon from './Assets/search.svg';
import dotsIcon from './Assets/dots.svg';
import sortIcon from './Assets/sort.svg';
import { ExportModal } from '..';

function SearchHeader({
  setSearch,
  children,
  options = true,
  filterOptions = ['Ordenar A-Z', 'Cargo', 'Grupo', 'Empresa', 'Data de Criação'],
  exportFields = ['name', 'email', 'phone', 'company', 'role', 'group'],
  filter,
  setFilter,
  buttonValue = 'Novo',
  hasButton = true,
  currentData,
  type,
}) {
  const [focus, setFocus] = useState(false);
  const [active, setActive] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [chosenFilter, setChosenFilter] = useState(null);
  const [activeKeys, setActiveKeys] = useState([]);
  const [exportFiles, setExportFiles] = useState(false);

  const [userInfo] = useUserInfo();

  const input = useRef(null);

  const labels = {
    'Ordenar A-Z': 'sort',
    Cargo: 'roleId',
    Grupo: 'groups',
    Empresa: 'companyId',
    'Data de Criação': 'createdAt',
  };

  const handleFocus = () => {
    setFocus(true);
    input.current.focus();
  };

  const handleBlur = () => {
    if (inputValue === '') setFocus(false);
  };

  const handleChange = (e) => {
    const text = e.target.value;
    setInputValue(text);
    if (typingTimeout) clearInterval(typingTimeout);
    setTypingTimeout(setTimeout(() => {
      setSearch(text);
    }, 500));
  };

  useEffect(() => {
    const checkClick = (e) => {
      if (e.target.tagName !== 'IMG') setShowOptions(false);
    };
    window.addEventListener('click', checkClick);
    return () => window.removeEventListener('click', checkClick);
  }, []);

  return (
    <ContentContainer>
      <Header>
        <NewButton
          hasButton={hasButton}
          show={userInfo?.permissions[type].create}
          onClick={() => (hasButton && setActive(true))}
        >
          {buttonValue}
        </NewButton>

        {children(active, setActive)}

        <OptionsContainer>
          <InputContainer active={focus}>
            <Input
              type="text"
              ref={input}
              value={inputValue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

            <Button onClick={handleFocus}>
              <Image src={searchIcon} alt="Botão de Pesquisa" />
            </Button>
          </InputContainer>

          {options && (
            <>
              <Button onClick={() => setShowOptions(true)}>
                <Image src={dotsIcon} alt="Outras Opções" />
              </Button>

              <Options className="options-container" active={showOptions}>
                <OptionButton className="option" onClick={() => setExportFiles('pdf')}>Exportar PDF</OptionButton>

                <OptionButton className="option" onClick={() => setExportFiles('csv')}>Exportar CSV</OptionButton>

                {/* <OptionButton
                  className="option"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  {showFilter ? 'Esconder Filtros' : 'Exibir Filtros'}
                </OptionButton> */}

                <ExportModal
                  fields={exportFields}
                  active={exportFiles}
                  setActive={setExportFiles}
                  data={currentData}
                />
              </Options>
            </>
          )}
        </OptionsContainer>
      </Header>

      <Filter active={true}>
        {/* <div style={{ display: 'flex' }}>
          <Image src={sortIcon} />
          <Text black>Filtros</Text>
        </div> */}

        {filterOptions.map((option) => (
          <FilterItem
            key={option}
            active={activeKeys.includes(labels[option])}
            onClick={() => setChosenFilter(labels[option])}
          >
            {option}
          </FilterItem>
        ))}
      </Filter>

      <SectionFilters
        active={chosenFilter}
        setActive={setChosenFilter}
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        option={chosenFilter}
        query={filter}
        setQuery={setFilter}
      />
    </ContentContainer>
  );
}

export default SearchHeader;
