export default (filter) => {
  const filterArr = Object.entries(filter).map(([key, val]) => {
    if (!val) return null;
    if (val?.$and?.length === 0) return null;

    if (!val?.$and) return `${key}=${JSON.stringify(val)}`;

    const objectArray = {
      roleId: [],
      companyId: [],
      groups: [],
      createdAt: [],
    };

    val.$and.forEach((obj) => {
      const property = Object.keys(obj)[0];
      objectArray[property].push(obj[property]);
    });

    const result = Object.entries(objectArray).map(([keyResult, valResult]) => {
      const filterArray = valResult.map((each) => ({
        [keyResult]: each,
      }));

      if (filterArray.length === 0) return null;

      return {
        $or: filterArray,
      };
    }).filter((i) => i);

    return `${key}=${JSON.stringify({ $and: result })}`;
  }).filter((i) => i);

  return filterArr;
};
