import React, { useState, useEffect } from 'react';

import {
  Card, Text, Button, Image, HiddenDelete,
} from './styles';
import { useAxios } from '../../services/api';
import WarnModal from '../WarnModal';
import { UserConsumer } from '../../state/UserContext';

import deleteIcon from './assets/delete.svg';
import deleteBlackIcon from './assets/delete_black.svg';
import linkIcon from './assets/link.svg';

function GroupCard({ id, name, setRedirect }) {
  const [deleting, setDeleting] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const [machines, setMachines] = useState([]);

  const [axiosGet, axiosDelete] = useAxios('get', 'delete');

  useEffect(() => {
    axiosGet({
      url: `/machines?filter=${JSON.stringify({ groupId: id })}`,
      setState: setMachines,
    });
  }, [id, axiosGet]);

  const [isMobile, setIsMobile] = useState(null);
  const [showHidden, setShowHidden] = useState(false);

  useEffect(() => {
    const handleSize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    handleSize();
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);

  if (deleted) return null;

  return (
    <UserConsumer>
      {([userInfo]) => (
        (
          <Card
            key={id}
            onClick={(e) => {
              if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'IMG') {
                if (isMobile && userInfo?.permissions.group.remove) setShowHidden(!showHidden);
                if (!isMobile) setRedirect(`/operacoes/${id}`);
              }
            }}
            showHidden={showHidden}
          >
            {userInfo?.permissions.group.remove && (
              <HiddenDelete
                onClick={() => setDeleting(true)}
                showHidden={showHidden}
              >
                <Image src={deleteBlackIcon} />
              </HiddenDelete>
            )}
            <div>
              <Text isTitle>{name}</Text>
              <Text italic opacity={0.5} black>
                {machines.length}
                {' '}
                {machines.length === 1 ? 'Operação' : 'Operações'}
              </Text>
            </div>
            {isMobile ? (
              <Button
                link
                className="shown"
                onClick={() => setRedirect(`/operacoes/${id}`)}
              >
                <Image link src={linkIcon} />
              </Button>
            ) : (
              userInfo?.permissions.group.remove && (
                <Button
                  link
                  className="shown"
                  onClick={() => setDeleting(true)}
                >
                  <Image link deleteIcon src={deleteIcon} />
                </Button>
              )
            )}
            <WarnModal
              active={deleting}
              setActive={setDeleting}
              title="Excluir Grupo?"
              text={(
                <>
                  Tem certeza que deseja excluir o Grupo
                  {' '}
                  <span>
                    {name}
                    {' '}
                    e todos os seus integrantes da sua lista de Grupos?
                  </span>
                </>
              )}
              accept={async () => {
                try {
                  await axiosDelete({
                    url: `/groups/${id}`,
                    body: { groupId: id },
                  });
                  setDeleted(true);
                } catch (err) {
                  console.log(err);
                }
              }}
            />
          </Card>
        )
      )}
    </UserConsumer>
  );
}

export default GroupCard;
