import styled from 'styled-components';

import Colors from '../../styles/Colors';

const List = styled.table`
  display: flex;
  flex-flow: column;
  width: 95%;
  border-collapse: separate;
  table-layout: fixed;
  box-shadow: 0 0 .3rem 0 ${Colors.shadow};
  flex-grow: 1;
  flex-shrink: 1;
`;

const BodyScroll = styled.tbody`
  display: block;
  width: 100%;
  height: 10rem;
  overflow: auto;
  padding: 1px;
  flex-grow: 1;
  flex-shrink: 1;
`;

const Text = styled.p`
  color: black;
  text-align: center;
  margin: 0;
  opacity: 0.6;

  @media only screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const Button = styled.button`
  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  background-color: transparent;
  margin: 0 1rem;

  img {
    opacity: ${({ disabled }) => (disabled && '0.25')};
  }
`;

const Image = styled.img`
  background-color: transparent;
  width: 1.4rem;

  @media only screen and (max-width: 600px) {
    width: 1rem;
  }
`;

const TableBottom = styled.td`
  display: block;
  text-align: center;
  color: black;
  column-span: 1;
  width: 100%;
  background-color: ${Colors.background};

  @media only screen and (max-width: 600px) {
    column-span: 3;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0;
`;

const TableFoot = styled.tfoot`
  display: block;
  width: 100%;
`;

const Container = styled.tr`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  List,
  Text,
  Button,
  Image,
  TableBottom,
  NavbarContainer,
  BodyScroll,
  TableFoot,
  Container,
};
