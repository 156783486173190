import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const List = styled.div`
  display: flex;
  width: min-content;
  flex-direction: column;
  margin: 10px 50px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
`;

export const Icon = styled.img`
  width: 200px;
  height: 200px;
  margin: 20px;

  @media only screen and (max-width: 900px) {
    min-width: 20px;
    min-height: 20px;
  }
`;

export const Text = styled.p`
  margin: .3rem 0;
  font-size: 1rem;
  color: ${Colors.secondaryBlue};
  width: min-content;
  white-space: nowrap;
`;
