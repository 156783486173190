import styled from 'styled-components';
import {
  Collapse, Button,
} from 'antd';

import colors from '../../styles/Colors';

const { Panel } = Collapse;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledCollapse = styled(Collapse)`
  border: 1px solid ${colors.secondaryBlue};
  width: 100%;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 20px;
`;

export const StyledPanel = styled(Panel)`
  background: ${colors.mainWhite};
  border-bottom: 0;

  .ant-collapse-content-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;

export const FinalButton = styled(Button)`
  float: right;
  margin: 0;
  margin-left: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48%;
`;

export const Label = styled.p`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 2;
  letter-spacing: 0.2px;
  opacity: 0.5;
  color: black;
  margin: 0;
`;

export const Input = styled.input`
  width: 100%;
  height: 32px;
  padding: 7px;
  border-radius: 4px;
  border: solid 1px ${colors.secondaryBlue};
  outline: none;
`;
