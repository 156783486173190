import React, { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';

import {
  ModalBase,
  Modal,
  FilterItem,
  ImageContainer,
  ImageFrame,
  Text,
  CheckboxContainer,
  StyledCheckbox,
  colourStyles,
  DateContainer,
  DateSubContainer,
  DatePicker,
} from './styles';
import { useAxios } from '../../services/api';
import LazyLoader from '../LazyLoader';

import zToA from './Assets/z_a.svg';
import aToZ from './Assets/a_z.svg';

function Order({ setAnswer, query }) {
  const options = [
    {
      image: zToA,
      value: { name: -1 },
    },
    {
      image: aToZ,
      value: { name: 1 },
    },
  ];

  const evalAnswer = (sort) => {
    if (sort.name === query?.sort?.name) {
      setAnswer(null);
      return;
    }

    setAnswer(sort);
  };

  return (
    <>
      <FilterItem>Ordenar A-Z</FilterItem>
      <div style={{ display: 'flex' }}>
        {options.map(({ image, value }) => (
          <ImageContainer
            active={value.name === query?.sort?.name}
            key={JSON.stringify(value)}
            onClick={() => evalAnswer(value)}
          >
            <ImageFrame src={image} />
            <Text black italic>Ordem alfabética</Text>
          </ImageContainer>
        ))}
      </div>
    </>
  );
}

function Role({ setAnswer, query }) {
  const [roles, setRoles] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [axiosGet] = useAxios('get');

  useEffect(() => {
    axiosGet({
      url: '/roles',
      setState: setRoles,
      process: (data) => {
        const prevSelected = query?.filter?.$and;
        return data.map(({ name, _id }) => {
          if (!prevSelected) return { name, id: _id, defaultChecked: null };
          const ids = prevSelected.map(({ roleId }) => roleId).filter((i) => i);
          return {
            name,
            id: _id,
            defaultChecked: ids.includes(_id),
          };
        });
      },
    });
  }, [query, axiosGet]);

  const handleChange = (event, roleId) => {
    let currentRoles = [...selectedRoles];
    if (event.target.checked) {
      currentRoles.push(roleId);
    } else {
      currentRoles = currentRoles.filter((id) => (roleId !== id));
    }
    setSelectedRoles(currentRoles);
    setAnswer(currentRoles.map((role) => ({ roleId: role })), 'roleId');
  };

  return (
    <>
      <FilterItem>Cargo</FilterItem>
      <CheckboxContainer>
        {roles?.map(({ name, id, defaultChecked }) => (
          <StyledCheckbox
            defaultChecked={defaultChecked}
            key={id}
            className={name}
            onChange={(e) => handleChange(e, id)}
          >
            {name}
          </StyledCheckbox>
        ))}
      </CheckboxContainer>
    </>
  );
}

function Group({ setAnswer, query }) {
  const [items, setItems] = useState([]);
  const [prevSelected, setPrevSelected] = useState(null);
  const [ready, setReady] = useState(false);

  const [axiosGet] = useAxios('get');

  const loadOptions = useCallback(async (inputValue, callback) => {
    const searchStr = (inputValue && inputValue === '') ? '' : `?search=${inputValue}`;

    const res = await axiosGet({ url: `/groups${searchStr}` });

    const groups = res.data.data;

    const options = groups.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));

    const selection = query?.filter?.$and;
    if (selection) {
      const prevGroups = selection.filter((filter) => filter.groups);
      if (prevGroups.length > 0) {
        const groupOptions = prevGroups.map(({ groups: groupId }) => {
          const option = options.find((filter) => filter.value === groupId);
          return option;
        });
        setPrevSelected(groupOptions);
      }
    }

    callback(options);
    setReady(true);
  }, [query, axiosGet]);

  useEffect(() => {
    loadOptions('', (options) => {
      setItems(options);
    });
  }, [loadOptions]);

  const handleChange = (e) => {
    const answer = e ? e.map((group) => ({ groups: group.value })) : [];
    setAnswer(answer, 'groups');
  };

  return (
    <>
      <FilterItem>Operações</FilterItem>
      {ready ? (
        <AsyncSelect
          isSearchable
          menuPlacement="auto"
          defaultValue={prevSelected}
          isMulti
          defaultOptions={items}
          onChange={handleChange}
          loadOptions={loadOptions}
          styles={colourStyles}
          placeholder="Nome da Operação"
        />
      ) : <LazyLoader />}
    </>
  );
}

function Company({ setAnswer, query }) {
  const [items, setItems] = useState([]);
  const [prevSelected, setPrevSelected] = useState(null);
  const [ready, setReady] = useState(false);

  const [axiosGet] = useAxios('get');

  const loadOptions = useCallback(async (inputValue, callback) => {
    const searchStr = (inputValue && inputValue === '') ? '' : `?search=${inputValue}`;

    const res = await axiosGet({ url: `/companies${searchStr}` });

    const companies = res.data.data;

    const options = companies.map(({ _id, name }) => ({ value: _id, label: name }));

    const selection = query?.filter?.$and;
    if (selection) {
      const prevCompanies = selection.filter((filter) => filter.companyId);
      if (prevCompanies.length > 0) {
        const companyOptions = prevCompanies.map(({ companyId }) => {
          const option = options.find((filter) => filter.value === companyId);
          return option;
        });
        setPrevSelected(companyOptions);
      }
    }

    callback(options);
    setReady(true);
  }, [query, axiosGet]);

  useEffect(() => {
    loadOptions('', (options) => {
      setItems(options);
    });
  }, [loadOptions]);

  const handleChange = (e) => {
    const answer = e ? e.map((company) => ({ companyId: company.value })) : [];
    setAnswer(answer, 'companyId');
  };

  return (
    <>
      <FilterItem>Empresa</FilterItem>
      {ready ? (
        <AsyncSelect
          isSearchable
          menuPlacement="auto"
          isMulti
          defaultValue={prevSelected}
          defaultOptions={items}
          onChange={handleChange}
          loadOptions={loadOptions}
          styles={colourStyles}
          placeholder="Nome da Empresa"
        />
      ) : <LazyLoader />}
    </>
  );
}

function Dates({ setAnswer, query }) {
  const [initDate, setInitDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [prevDates, setPrevDates] = useState([]);
  const [ready, setReady] = useState(false);

  const dateFormat = 'DD/MM/YYYY';

  useEffect(() => {
    if (initDate && endDate) {
      setAnswer([{
        createdAt: {
          $gte: initDate,
          $lt: endDate,
        },
      }], 'createdAt');
    } else {
      setAnswer([], 'createdAt');
    }
  }, [initDate, endDate]);

  useEffect(() => {
    const prevSelected = query?.filter?.$and;
    if (prevSelected) {
      const selectedDates = prevSelected.filter(({ createdAt }) => createdAt);
      if (selectedDates.length > 0) {
        const { $gte, $lt } = prevSelected[0]?.createdAt;
        setPrevDates([
          moment($gte),
          moment($lt),
        ]);
      }
    }
    setReady(true);
  }, [query]);

  return (
    <>
      <FilterItem>Data</FilterItem>
      {ready ? (
        <div style={{
          display: 'flex', margin: '2rem 0', flexDirection: 'column', width: '100%',
        }}
        >
          <DateContainer>
            <DateSubContainer>
              Data Inicial
              <DatePicker
                defaultValue={prevDates[0]}
                onChange={(d) => setInitDate(d?.toISOString())}
                locale={locale}
                format={dateFormat}
              />
            </DateSubContainer>
            <DateSubContainer>
              Data Final
              <DatePicker
                defaultValue={prevDates[1]}
                onChange={(d) => setEndDate(d?.toISOString())}
                locale={locale}
                format={dateFormat}
              />
            </DateSubContainer>
          </DateContainer>
        </div>
      ) : <LazyLoader />}
    </>
  );
}

function SectionFilters({
  active,
  setActive,
  option,
  query,
  setQuery,
  activeKeys,
  setActiveKeys,
}) {
  const handleSort = (result) => {
    setQuery({
      ...(query || {}),
      sort: result,
    });
    setActive(false);
    if (result) {
      setActiveKeys([...activeKeys, 'sort']);
    } else {
      setActiveKeys(activeKeys.filter((k) => k !== 'sort'));
    }
  };

  const handleFilter = (result, key) => {
    setQuery({
      ...(query || {}),
      filter: {
        $and: [
          ...(query?.filter?.$and.filter((cond) => cond[key] === undefined) || []),
          ...result,
        ],
      },
    });
    if (result.length > 0) {
      setActiveKeys([...activeKeys, key]);
    } else {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    }
  };

  const handleCloseModal = (e) => {
    if (e.target.id === 'shade') setActive(false);
  };

  const sections = {
    sort: [Order, handleSort],
    roleId: [Role, handleFilter],
    groups: [Group, handleFilter],
    companyId: [Company, handleFilter],
    createdAt: [Dates, handleFilter],
    default: [null, null],
  };

  const sortOption = {
    type: 'aToZ',
    value: { name: 1 }
  }

  const [Section, setAnswer] = sections[option || 'default'];

  if (option === 'sort') {
    if (sortOption.value.name === query?.sort?.name) {
      handleSort(null);
      return null;
    }

    handleSort(sortOption.value);
    return null;
  } else {
    return (
      <ModalBase id="shade" active={active} onClick={handleCloseModal}>
        <Modal>
          {Section && <Section query={query} setAnswer={setAnswer} />}
        </Modal>
      </ModalBase>
    );
  }
}

export default SectionFilters;
