export default (num) => {
  let min = Math.floor(num / 60);
  let hours = 0;

  while (min >= 60) {
    min -= 60;
    hours += 1;
  }

  return `${hours}h`;
};
