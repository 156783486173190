import styled from 'styled-components';

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14.6rem, 1fr));
  width: 95%;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(7.3rem, 1fr));
  }
`;

const Image = styled.img`
  margin: .6rem 0;
  height: 14rem;
  @media only screen and (max-width: 600px) {
    height: 7rem;
  }
`;

const Card = styled.div`
  padding: 1.3rem 0;
  text-align: center;
  max-width: 14rem;
  max-height: 20rem;
  cursor: pointer;
`;

const Text = styled.p`
  color: black;
`;

export {
  List,
  Image,
  Card,
  Text,
};
