import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router';

import {
  Header,
  Button,
  Image,
  TitleContainer,
  Title,
  Dash,
  ContentContainer,
  GroupList,
  GroupsSection,
} from './styles';
import {
  SearchHeader,
  Modal,
  LazyLoader,
  GroupCard,
} from '../../components';
import { useUserInfo } from '../../state/UserContext';
import { useAxios } from '../../services/api';
import NotFound from '../404';
import { stringifyFilter } from '../../utils';

import backIcon from './Assets/back.svg';

function CompanyDetail({ location }) {
  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [companyName, setCompanyName] = useState('');

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [groupName, setGroupName] = useState('');
  const [filter, setFilter] = useState(null);

  const [userInfo] = useUserInfo();

  const [axiosGet, axiosPost] = useAxios('get', 'post');

  useEffect(() => {
    if (userInfo?.isAdmin) {
      const navbarImg = document.querySelector('#logoM');
      navbarImg.style.display = 'none';
      return () => { navbarImg.style.display = 'block'; };
    }
    return () => {};
  }, [userInfo]);

  const loadList = () => {
    const companyId = location.pathname.split('/')[2];

    const searchStr = search === '' ? '' : `&search=${search}`;

    const newFilter = {
      ...(filter || {}),
      filter: {
        ...(filter?.filter || {}),
        $and: [
          ...(filter?.filter?.$and || []),
          { companyId },
        ],
      },
    };

    const filterArr = stringifyFilter(newFilter);
    const filterUrl = `${filterArr.join('&')}`;

    axiosGet({ url: `/groups?${filterUrl}${searchStr}`, setState: setGroups });
  };

  useEffect(loadList, [search, location.pathname, filter]);

  useEffect(() => {
    const companyId = location.pathname.split('/')[2];
    axiosGet({
      url: `/companies/${companyId}`,
      setState: setCompanyName,
      process: ({ name }) => name,
      success: setSuccess,
      error: setError,
    });
  }, [location.pathname, axiosGet]);

  const handleNewGroup = async () => {
    if (groupName === '') throw new Error('Campos faltando');
    await axiosPost({
      url: '/groups',
      body: {
        name: groupName,
        companyId: location.pathname.split('/')[2],
      },
      success: loadList,
    });
  };

  if (redirect) {
    const companyId = location.pathname.split('/')[2];

    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { companyId },
        }}
      />
    );
  }

  if (!error && !success) return <LazyLoader page />;

  if (error) return <NotFound />;

  return (
    <GroupsSection>
      <Header admin={userInfo?.isAdmin}>
        {userInfo?.isAdmin && (
          <Button type="button" onClick={() => setRedirect('/empresas')}>
            <Image src={backIcon} />
          </Button>
        )}
        <TitleContainer>
          <Title>{companyName}</Title>
          <Dash />
        </TitleContainer>
      </Header>
      <ContentContainer>
        <SearchHeader
          filter={filter}
          setFilter={setFilter}
          setSearch={setSearch}
          filterOptions={['Ordenar A-Z']}
          currentData={groups}
          buttonValue="Novo"
          type="group"
        >
          {(active, setActive) => (
            <Modal handleSubmit={handleNewGroup} active={active} setActive={setActive}>
              <Modal.Title>Novo Grupo</Modal.Title>
              <Modal.Input placeholder="Nome do Grupo" value={groupName} setValue={setGroupName} />
              <Modal.Button>Criar Grupo</Modal.Button>
            </Modal>
          )}
        </SearchHeader>
        {groups ? (
          <GroupList>
            {groups.map(({ _id, name }) => (
              <GroupCard key={_id} id={_id} name={name} setRedirect={setRedirect} />
            ))}
          </GroupList>
        ) : <LazyLoader />}
      </ContentContainer>
    </GroupsSection>
  );
}

export default withRouter(CompanyDetail);
