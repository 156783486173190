import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Group = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 83vh;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: .8rem 1.2rem;
  position: relative;
  z-index: 101;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  @media only screen and (min-width: 600px) {
    display: ${({ link }) => link && 'none'};
  }
`;

const Image = styled.img`
  margin: .6rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: ${Colors.secondaryBlack};
  height: 2rem;
`;

const Dash = styled.div`
  width: 6.6rem;
  height: .2rem;
  background-color: ${Colors.secondaryBlue};
`;

export {
  Group,
  Header,
  Button,
  Image,
  TitleContainer,
  Title,
  Dash,
};
