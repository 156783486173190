import styled from 'styled-components';


export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 16rem));
  justify-content: center;
  justify-items: center;
  align-items: start;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f5f5;
  padding: 16px;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 1rem;
  }
`;

