import React, {
  useEffect,
} from 'react';

import { useAxios } from '../../services/api';

import {
  Container,
  Logo,
  Success,
  BackgroundCorners,
  Content,
  Title,
} from '../../components/Auth';

import { useWindowSize } from '../../hooks/useWindowSize';

function Redirect() {
  const size = useWindowSize();
  const [axiosPost] = useAxios('post');

  useEffect(() => {

    const siteUrl = window.location.search;
    const sendLog = async () => {
      await axiosPost({ url: '/webhook/log', body: {log: siteUrl} });
    };

    sendLog();
  }, []);

  return (
    <>
      <Container>
        <Content>
          <Logo />
          <Success />
          <Title>Autorização realizada com sucesso.</Title>
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default Redirect;
