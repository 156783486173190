/* eslint-disable import/no-cycle */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';

import {
  ErrorHandler,
} from '../components';

const ErrorContext = createContext(false);
const { Consumer: ErrorConsumer } = ErrorContext;

function ErrorProvider({ children }) {
  const [error, setError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);

  useEffect(() => {
    if (error) {
      const id = Math.floor((Math.random() * (99999999999 - 10000000000 + 1)) + 10000000000);
      setErrorArray((currentErrors) => [
        ...currentErrors,
        {
          id,
          ...error,
        },
      ]);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      console.log(`==== Error \`${error.type}\` ====\nMessage: ${error.message}\nStatus: ${error.status}\nUrl: ${error.url}`);
    }
  }, [error]);

  return (
    <ErrorContext.Provider value={[error, setError]}>
      <ErrorHandler errorArray={errorArray} />
      {children}
    </ErrorContext.Provider>
  );
}

export const useErrorContext = () => useContext(ErrorContext);

export default ErrorContext;

export {
  ErrorProvider,
  ErrorConsumer,
};
