import React, { useState } from 'react';

import SlotsCardCreate from '../SlotsCardCreate';

import deleteBorderlessIcon from './assets/ic-delete.svg';
import createBorderlessIcon from './assets/ic-edit.svg';
import {
  SlotCardShow,
  SlotCardTitle,
  SlotCardQuantity,
  ButtonContainer,
  Button,
  Image,
  SlotList,
} from './styles';

const OptionButton = ({
  src,
  dropdown,
  active,
  onClick,
}) => (
  <Button onClick={onClick} active={active} dropdown={dropdown}>
    <Image noMargin src={src} />
  </Button>
);

function SlotCard({
  slot,
  setSlots,
  index,
}) {
  const {
    name,
    size,
  } = slot;

  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <SlotsCardCreate
        editing
        defaultSlot={slot}
        setSlots={(setStateAction, newSlot) => {
          setEditing(false);
          setSlots((prevSlots) => prevSlots.map((prevSlot, i) => (index === i ? newSlot : prevSlot)));
        }}
        onCancel={() => {
          setEditing(false);
        }}
      />
    );
  }

  return (
    <SlotCardShow key={name}>
      <SlotCardTitle>{name}</SlotCardTitle>
      <SlotCardQuantity>
        Capacidade
        {' '}
        {size}
      </SlotCardQuantity>
      <ButtonContainer>
        <OptionButton
          src={createBorderlessIcon}
          onClick={() => setEditing(true)}
          active
        />
        <OptionButton
          src={deleteBorderlessIcon}
          onClick={() => {
            setSlots((prevSlots) => {
              const newSlots = [...prevSlots];

              newSlots.splice(index, 1);

              return newSlots;
            });
          }}
          active
        />
      </ButtonContainer>
    </SlotCardShow>
  );
}

function SlotsFormList({ slots, setSlots }) {
  return (
    <>
      <SlotsCardCreate setSlots={setSlots} />
      <SlotList>
        {slots.map((slot, i) => (
          <SlotCard
            slot={slot}
            setSlots={setSlots}
            index={i}
          />
          
        ))}
      </SlotList>
    </>
  );
}

export default SlotsFormList;
