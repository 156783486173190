import React from 'react';

import Routes from './routes/index';
import GlobalStyle, { Background } from './styles/globalStyle';
import { ErrorProvider } from './state/ErrorContext';

import 'antd/dist/antd.css';
import { ConfigProvider } from "antd";
import locale from 'antd/lib/locale/pt_BR';

function App() {
  return (
    <ErrorProvider>
      <Background>
        <ConfigProvider locale={locale}>
          <Routes />
        </ConfigProvider>
      </Background>
      <GlobalStyle />
    </ErrorProvider>
  );
}

export default App;
