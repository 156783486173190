import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import Colors from '../../styles/Colors';

import Canto from './assets/canto.png';
import CantoBig from './assets/canto@2x.png';
import Canto2 from './assets/canto2.png';
import Canto2Big from './assets/canto2@2x.png';

import logo from '../../assets/logo.png';
import success from '../../assets/success-green-check-mark-icon.png';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: ${Colors.background};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  height: 80%;

  transition: 500ms;
`;

const positions = {
  TopLeft: {
    style: `
      top: 0;
      left: 0;
    `,
    img: [Canto, CantoBig],
  },
  TopRight: {
    style: `
      top: 0;
      right: 0;
    `,
    img: [Canto2, Canto2Big],
  },
  BottomLeft: {
    style: `
      bottom: 0;
      left: 0;
      transform: rotate(180deg);
    `,
    img: [Canto2, Canto2Big],
  },
  BottomRight: {
    style: `
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    `,
    img: [Canto, CantoBig],
  },
};

export const BackgroundCorners = styled.img.attrs(({ position, size }) => ({
  src: size >= 2 ? positions[position].img[0] : positions[position].img[1],
  alt: 'Canto',
}))`
  position: absolute;

  ${({ position }) => positions[position].style};
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'Logo',
})`
  width: 85px;
  height: auto;
`;

export const Success = styled.img.attrs({
  src: success,
  alt: 'Success',
})`
  width: 85px;
  height: auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 300px;
`;

export const Input = styled.input`
  padding: 10px;

  background-color: ${Colors.background};

  border-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.blue};

  outline-style: none;

  color: ${Colors.blue};

  margin: 20px 0;

  &::placeholder {
    color: ${Colors.blue};
  }
`;

export const SubmitButton = styled(Button).attrs({
  color: Colors.blue,
})`
  background-color: ${Colors.blue};
  border-color: ${Colors.blue};

  margin-top: 15px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #67b6ef;
  }
`;

export const FowardPassword = styled(Link)`
  text-decoration: underline;

  color: ${Colors.blue};

  &:hover {
    text-decoration: underline;
  }
`;

export const Message = styled.p`
  padding: 5px;

  border-style: solid;
  border-color: ${({ error }) => (!error ? Colors.blue : 'red')};
  border-radius: 5px;
  border-width: 1px;
  font-size: .8rem;

  width: 300px;

  text-align: center;

  color: ${({ error }) => (!error ? Colors.blue : 'red')};
`;

export const Title = styled.h2`
  color: ${Colors.blue};
  font-weight: bolder;
  font-size: 1.8rem;
  width: 80%;
  text-align: center;
  margin-bottom: 0;
  margin-top: .6rem;
`;
