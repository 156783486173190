import React, { useState } from 'react';

import Modal from '../Modal';
import SwitchContainer from '../SwitchContainer';

function ExportModal({
  active,
  setActive,
  fields,
  data,
}) {
  const [exportOptions, setExportOptions] = useState(fields.map(() => false));

  const options = fields?.map((name, index) => ({
    name,
    state: exportOptions[index],
    setState: (value, i) => {
      const newState = [...exportOptions];
      newState[i] = value;
      setExportOptions(newState);
    },
  }));

  const handleSubmit = async () => {
    const columns = fields.filter((item, i) => exportOptions[i]);
    if (columns.length === 0) throw new Error('No fields to export');
    const result = data.map((item) => {
      const resultKeys = Object.keys(item).map((key) => {
        if (columns.indexOf(key) !== -1) {
          return item[key];
        }
        return null;
      }).filter((i) => i);
      return resultKeys;
    });

    result.unshift(columns);

    let csvContent = 'data:text/csv;charset=utf-8,';

    result.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += `${row}\r\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    link.click();
  };

  return (
    <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
      <Modal.Title>
        Exportar
        {' '}
        {active}
      </Modal.Title>
      <SwitchContainer options={options} />
      <Modal.Button>Exportar</Modal.Button>
    </Modal>
  );
}

export default ExportModal;
