import React, { useEffect, useState } from 'react';

import { useAxios } from '../../services/api';

import { SlotsCard } from '..';
import { Container } from './styles';

const Slots = ({ machine, slotConfig, reloadMachineDetails }) => {
  const [axiosGet] = useAxios('get');
  const [slots, setSlots] = useState(machine.slots);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const { data } = await axiosGet({ url: '/items' });
      const { data: fetchedItems } = data;

      const productsResponse = await Promise.all(
        fetchedItems.map(
          ({ productId }) => axiosGet({ url: `/products/${productId}` }),
        ),
      );

      const result = fetchedItems.map((fetchedItem, index) => ({
        ...fetchedItem,
        name: productsResponse[index].data.data.name,
      }));

      result.sort((a, b) => a.name.localeCompare(b.name))

      setItems(result);
    };

    fetchItems();
  }, []);

  return (
    <Container>
      {slotConfig.map((slot) => (
        <SlotsCard
          key={slot._id}
          machine={machine}
          slot={slot}
          slots={slots}
          setSlots={setSlots}
          items={items}
          reloadMachineDetails={reloadMachineDetails}
        />
      ))}
    </Container>
  );
};

export default Slots;
