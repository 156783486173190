import React, { useEffect, useState, useCallback } from 'react';

import { url, useAxios } from '../../../services/api';

import useDebounce from '../../../hooks/useDebounce';

import CategoryCardComponent from '../subComponents/CategoryCardComponent';
import {
  CategoriesContainer,
} from '../styles';

function Renderer({
  categories,
  setCategories,
  setTargetEditModal,
  setShouldCategoryModalShow,
  setCategoryName,
  setItemsToAdd,
  categoryIcons,
  setModalSelectedCategoryIcon,
}) {
  const [orderedCategories, setOrderedCategories] = useState(
    categories.sort((categoryA, categoryB) => {
      if (categoryA.position < categoryB.position) {
        return -1;
      }

      if (categoryA.position > categoryB.position) {
        return 1;
      }

      return 0;
    }),
  );
  const [axiosPatch] = useAxios('patch');
  const debouncedCategories = useDebounce(orderedCategories, 1500);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = orderedCategories[dragIndex];

      const newCards = [...orderedCategories];
      newCards.splice(dragIndex, 1);
      newCards.splice(hoverIndex, 0, dragCard);

      setOrderedCategories(newCards);
    },
    [orderedCategories],
  );

  useEffect(() => {
    categories.forEach((category, index) => {
      if (category._id !== debouncedCategories[index]._id) {
        const newIndex = debouncedCategories.findIndex(({ _id }) => category._id === _id );
        axiosPatch({ url: `/categories/${category._id}`, body: { position: newIndex } });
      }
    });
  }, [debouncedCategories]);

  return (
    <CategoriesContainer>
      {orderedCategories.map((category, index) => (
        <CategoryCardComponent
          key={category._id}
          category={category}
          url={url}
          setCategories={setCategories}
          setTargetEditModal={setTargetEditModal}
          setShouldCategoryModalShow={setShouldCategoryModalShow}
          setCategoryName={setCategoryName}
          setItemsToAdd={setItemsToAdd}
          categoryIcons={categoryIcons}
          setModalSelectedCategoryIcon={setModalSelectedCategoryIcon}
          index={index}
          moveCard={moveCard}
        />
      ))}
    </CategoriesContainer>
  );
}

export default Renderer;
