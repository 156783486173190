import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ProductsSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  height: 90vh;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  width: 100%;
  height: 70vh;
  background-color: ${Colors.background};
`;

const ProductsList = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
  justify-items: center;
  align-items: start;
  width: 95%;
  flex-grow: 1;
  height: min-content;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
`;

const Icon = styled.img`
  margin: 0;
`;

const Header = styled.header`
  display: flex;
  justify-content: ${({ isAdmin }) => (isAdmin ? 'flex-end' : 'space-between')};
  width: 100%;
  padding: 1.5rem 2rem;
  position: relative;
  z-index: 101;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  @media only screen and (min-width: 600px) {
    display: ${({ link }) => link && 'none'};
  }
`;

const Image = styled.img`
  margin: .6rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Dash = styled.div`
  width: 10rem;
  height: .2rem;
  background-color: ${Colors.secondaryBlue};
`;

const Title = styled.h1`
  color: ${Colors.secondaryBlack};
  height: 2rem;
  text-transform: uppercase;
`;

const Ghost = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  height: 14.6rem;
  max-height: min-content;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: .3rem;

  &:hover {
    outline: 1px solid ${Colors.blue};

    .price {
      margin-top: -1rem;
    }

    .delete-button {
      max-height: 2rem;
    }
  }
`;

const ModalContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 1080px){
    display: block;
  }
`;

export {
  ProductsSection,
  ProductsList,
  ContentContainer,
  Icon,
  Header,
  Button,
  Image,
  TitleContainer,
  Dash,
  Title,
  Ghost,
  ModalContainer,
};
