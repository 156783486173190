import styled from 'styled-components';
import {
  Modal,
  Tabs,
  Button,
  Input,
  AutoComplete,
  Tag,
  Dropdown,
} from 'antd';

import { CaretDownOutlined } from '@ant-design/icons';

import colors from '../../styles/Colors';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100px;

  .ant-tabs-tab-active {
    font-weight: bold !important;
  }

  .ant-tabs-tab {
    font-size: 1rem !important;
    color: #000;
    width: 10rem !important;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    .ant-tabs-nav {
      width: 100%;
      color: #000;
    }

    .ant-tabs-tab {
      width: 45% !important;
      display: flex;
      justify-content: center;
    }
  }
`;

const StyledTabPane = styled(TabPane)`
`;

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  height: 100vh;
`;

const ContentContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 50px;
  height: 50vh;
`;

const CategoriesContainer = styled.div`
  height: 50vh;
`;

const NewCategoryContainer = styled.div`
  height: 62px;
`;

const NewCategoryButton = styled(Button)`
  width: fit-content;
  margin-left: 5%;
  background: ${colors.blue};
  padding: .3rem 2.6rem;
  border-radius: .2rem;
  font-style: italic;
  white-space: nowrap;
  border: none;
`;

const CategoryModal = styled(Modal)`
  .ant-modal-title {
    color: ${colors.secondaryBlue};
    text-align: center;
  }

  .ant-btn {
    display: none;
  }
  
  .ant-btn-primary {
    display: block;
    width: 100%;
    background: ${colors.secondaryBlue};
    font-size: 1.2rem;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    border-radius: 5px;
    margin-top: 50px;
    margin: 10px 0;
  }
`;

const CategoryTextInput = styled(Input)`
  margin-bottom: 40px;
  border: 1px solid ${colors.secondaryBlue};
`;

const UploadInput = styled.input`
    margin-top: 20px;
    display: none;
`;

const UploadButton = styled(Button)`
  background: ${colors.background};
  width: 100% !important;
  display: flex !important;
  color: ${colors.secondaryBlue};
  border: none;
  align-items: center;
  height: 40px;
  margin-bottom: 30px;
`;

const UploadSpan = styled.span`
  margin-left: 10px;
  font-size: 0.8rem;
  font-weight: ${({ categoryFile }) => (categoryFile ? 'bold' : 'regular')};
`;

const TagsInstruction = styled.span`
  font-size: 0.75rem;
  color: ${colors.secondaryBlue};
`;

const TypeAhead = styled(AutoComplete)`
  margin-top: 20px;
  border: 1px solid ${colors.secondaryBlue};
`;

const ProductTag = styled(Tag)`
  background: ${colors.secondaryBlue};
  border: none;
  color: #fff;

  svg {
    fill: #fff;
    margin-left: 5px;
  }
`;

const DropdownButton = styled.button`
  display: flex;
  border: 1px solid ${colors.secondaryBlue};
  border-radius: 2px;
  padding: 4px 11px;
  font-size: 14px;
  color: #d9d9d9;

  ${({ isSelected }) => isSelected && (`
    font-weight: bold;
    color: ${colors.secondaryBlue};
  `)}
`;

const DropdownComponent = styled(Dropdown)`
  width: 100%;
  text-align: left;
  background: transparent;
  align-items: center;
`;

const DropdownMenu = styled.div`
  background: #fff;
  border: 1px solid ${colors.secondaryBlue};
  padding: 10px 0;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ArrowDown = styled(CaretDownOutlined)`
  margin-left: auto;
  
  svg {
    fill: ${colors.secondaryBlue};
  }
`;

const IconButton = styled.button`
  background: url('${({ image }) => image}');
  background-size: cover;
  background-repeat: no-repeat;

  width: 50px;
  height: 50px;
  border: none;
  margin: 5px;

`;

export {
  Panel,
  ContentContainer,
  CardsContainer,
  StyledTabs,
  StyledTabPane,
  NewCategoryButton,
  CategoriesContainer,
  NewCategoryContainer,
  CategoryModal,
  CategoryTextInput,
  UploadInput,
  UploadButton,
  UploadSpan,
  TagsInstruction,
  TypeAhead,
  ProductTag,
  DropdownComponent,
  DropdownMenu,
  DropdownButton,
  ArrowDown,
  IconButton,
};
