import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Card,
  Container,
  DropContainer,
  Text,
  TableText,
  Image,
  ButtonContainer,
  Button,
  DropDownButton,
  DropDownContainer,
} from './styles';
import { useAxios } from '../../services/api';
import { UserConsumer } from '../../state/UserContext';

import emailIcon from './Assets/phone.svg';
import companyIcon from './Assets/bolsa.svg';
import deleteIcon from './Assets/delete.svg';
import createIcon from './Assets/create.svg';
import machinesIcon from './Assets/machines.svg';
import paymentTypeIcon from './Assets/paymentType.svg';
import statusIcon from './Assets/status.svg';
import valueIcon from './Assets/value.svg';
import deleteBorderlessIcon from './Assets/ic-delete.svg';
import createBorderlessIcon from './Assets/ic-edit.svg';
import arrowIcon from './Assets/arrow.svg';
import detailIcon from './Assets/+.svg';
import WarnModal from '../WarnModal';
import { Modal } from '..';

const InfoText = ({
  text, src, dropdown, textWrap = true,
}) => (
  <TableText black opacity={0.8} dropdown={dropdown} textWrap={textWrap}>
    <Image src={src} />
    <p>
      {text}
    </p>
  </TableText>
);

const OptionButton = ({
  src,
  dropdown,
  active,
  onClick,
  width,
  freeWidth = true,
}) => (
  <Button onClick={onClick} active={active} dropdown={dropdown}>
    <Image freeWidth={freeWidth} $width={width} noMargin src={src} />
  </Button>
);

function SalesCard({ info }) {
  const {
    transactionId,
    companyName,
    groupName,
    machineName,
    items,
    paymentType,
    status,
    buyerEmail,
    buyerMobile,
    createdAt,
    _id,
    code,
  } = info;

  const [active, setActive] = useState(false);
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [showDropDown, setShowDropDown] = useState();
  const [mobile, setMobile] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [saleInfo, setSaleInfo] = useState({
    transactionId,
    companyName,
    groupName,
    machineName,
    items,
    paymentType,
    status,
    buyerEmail,
    buyerMobile,
  });

  const [axiosGet, axiosDelete] = useAxios('get', 'delete');

  const handleToggle = (event) => {
    if (mobile && event.target.tagName !== 'BUTTON') setShowDropDown(!showDropDown);
  };
  const handleEdit = () => setEditing(true);
  const handleDelete = () => setDeleting(true);

  const checkBreakpoint = () => setMobile(window.innerWidth < 600);

  useEffect(() => {
    checkBreakpoint();
    window.addEventListener('resize', checkBreakpoint);
    return () => {
      window.removeEventListener('resize', checkBreakpoint);
    };
  }, []);

  if (deleted) return null;

  const sum = saleInfo.items.reduce((acc, cur) => acc + cur.quantity * cur.priceAtTheMoment, 0);
  const stringMoney = `R$ ${sum}`.replace('.', ',');
  const createdDate = dayjs(createdAt).format('DD-MMM-YYYY HH:mm').toLowerCase();

  // console.log(info);

  return (
    <Card
      onMouseOver={() => setActive(true)}
      onFocus={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={handleToggle}
    >
      <Container>
        <div style={{
          height: 'inherit',
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
        }}
        >
          <div style={{ float: 'left', zIndex: 1, width: '100%' }}>
            <Text textWrap isTitle>
              {code}
            </Text>
            <Text black opacity={0.5} italic>
              {createdDate}
            </Text>
          </div>
          <DropDownButton>
            <Image src={arrowIcon} upside={showDropDown} />
          </DropDownButton>
        </div>
        <DropDownContainer show={showDropDown}>
          <div style={{ width: '100%' }}>
            <InfoText text={saleInfo.companyName} src={companyIcon} />
            <InfoText text={saleInfo.machineName} src={machinesIcon} />
            <InfoText text={stringMoney} src={valueIcon} />
            <InfoText text={saleInfo.paymentType} src={paymentTypeIcon} />
            <InfoText text={saleInfo.buyerMobile} src={emailIcon} />
            <InfoText text={saleInfo.status} src={statusIcon} />
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <UserConsumer>
              {([loggedUserInfo]) => {
                const { update, remove } = loggedUserInfo?.permissions?.user || {};
                return (
                  <>
                    {update && (
                      <OptionButton freeWidth width=".8rem" onClick={handleEdit} src={detailIcon} dropdown />
                    )}
                    {/* {remove && (
                      <OptionButton freeWidth width=".8rem" onClick={handleDelete} src={deleteBorderlessIcon} dropdown />
                    )} */}
                  </>
                );
              }}
            </UserConsumer>
          </div>
        </DropDownContainer>
      </Container>
      {!showDropDown && (
        <>
          <DropContainer>
            <InfoText text={saleInfo.companyName} src={companyIcon} />
            <InfoText text={saleInfo.machineName} src={machinesIcon} />
          </DropContainer>
          <DropContainer>
            <InfoText text={stringMoney} src={valueIcon} />
            <InfoText text={saleInfo.paymentType} src={paymentTypeIcon} />
          </DropContainer>
          <DropContainer>
            <InfoText text={saleInfo.buyerMobile} src={emailIcon} />
            <InfoText text={saleInfo.status} src={statusIcon} />
          </DropContainer>
          <ButtonContainer>
            <UserConsumer>
              {([loggedUserInfo]) => {
                const { update, remove } = loggedUserInfo?.permissions?.user || { };
                return (
                  <>
                    {update && (
                      <OptionButton onClick={handleEdit} active={true} src={detailIcon} width={'.8rem'}/>
                    )}
                    {/* {remove && (
                      <OptionButton onClick={handleDelete} active={true} src={deleteBorderlessIcon} width={'.8rem'}/>
                    )} */}
                  </>
                );
              }}
            </UserConsumer>
          </ButtonContainer>
        </>
      )}
      <td>
        <Modal active={editing} setActive={setEditing}>
          <Modal.Title>Detalhes da Venda</Modal.Title>
          {/* <Modal.Input disabled value={transactionId} />
          <Modal.Input disabled value={createdDate} />
          <Modal.Input disabled value={`R$ ${sum}`} />
          <Modal.Input disabled value={buyerMobile} />
          <Modal.Input disabled value={status} />
          <Modal.Input disabled value={paymentType} /> */}
          <div>
            <table style={{ width: '100%', border: 'solid 1px', color: '#3fa9f5' }}>
              <thead>
                <th style={{ width: '70%', border: 'solid 1px', fontSize: '14px' }}>Produto</th>
                <th style={{ width: '15%', border: 'solid 1px', fontSize: '14px' }}>Qtde</th>
                <th style={{ width: '15%', border: 'solid 1px', fontSize: '14px' }}>Preço</th>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td style={{ border: 'solid 1px', fontSize: '12px' }}>{item.productName}</td>
                    <td style={{ border: 'solid 1px', fontSize: '12px' }}>{parseFloat(item.quantity).toFixed(2).replace('.', ',')}</td>
                    <td style={{ border: 'solid 1px', fontSize: '12px' }}>{parseFloat(item.priceAtTheMoment).toFixed(2).replace('.', ',')}</td>
                  </tr>
))}
              </tbody>
            </table>
          </div>
        </Modal>
      </td>
      <td>
        <WarnModal
          active={deleting}
          setActive={setDeleting}
          title="Excluir Venda?"
          text={(
            <>
              Tem certeza que deseja excluir a venda
              {' '}
              <span>{saleInfo.transactionId}</span>
              ?
            </>
          )}
          accept={async () => {
            try {
              await axiosDelete({ url: `/sales/${_id}` });
              setDeleted(true);
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </td>
    </Card>
  );
}

export default SalesCard;
