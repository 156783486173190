import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Container = styled.section`
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-flow: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  height: 3rem;
`;

const Title = styled.h2`
  margin: 0;
  margin-right: 2rem;
  color: ${Colors.secondaryBlue};
`;

const Button = styled.button`
  background-color: ${Colors.blue};
  padding: .3rem 1.6rem;
  outline: none;
  border: none;
  border-radius: .2rem;
  font-style: italic;
  color: #fff;
  white-space: nowrap;
`;

const Alternator = styled.section`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  flex-flow: column;
  flex-grow: 1;
  overflow-y: auto;
  box-shadow: 0 0 .5rem 0 ${Colors.mainBlack};
`;

const Header = styled.header`
  border-bottom: 1px solid ${Colors.blue};
  width: 100%;
  padding: 0 2.5%;
  display: flex;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`;

const Tab = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  background-color: transparent;
  flex: 1;
  max-width: 10rem;
  border-left: solid 1px ${Colors.mainBlack};
  outline: none;

  &:first-child {
    border-left: none;
  }

  @media only screen and (max-width: 600px) {
    max-width: unset;
  }
`;

const Text = styled.p`
  color: black;
  padding: 1.3rem;
  font-weight: ${({ active }) => active && 'bolder'};
  width: 100%;
  margin: 0;
`;

const Indicator = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${Colors.blue};
  height: .2rem;
  transition: max-height .1s ease-in-out;
  max-height: ${({ active }) => (active ? '.2rem' : '0rem')};
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export {
  Container,
  TitleContainer,
  Title,
  Button,
  Alternator,
  Header,
  Tab,
  Text,
  Indicator,
};
