import styled, { css } from 'styled-components';
import { Checkbox, DatePicker as DatePickerComponent } from 'antd';

import Colors from '../../styles/Colors';

const ModalBase = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.16);
`;

const Modal = styled.div`
  position: relative;
  width: 100%;
  max-width: 25rem;
  background-color: white;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  z-index: 6;
  padding: 1.3rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FilterItem = styled.button`
  border-radius: 50px;
  padding: .1rem .5rem;
  background-color: ${Colors.secondaryBlue};
  color: ${Colors.mainWhite};
  height: min-content;
  margin: .6rem .3rem;
  border: 1px solid ${Colors.mainWhite};
  outline: none;
  font-size: .6rem;
`;

const ImageContainer = styled.button`
  display: flex;
  border: none;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 6rem;
  background-color: transparent;


  ${({ active }) => !active && css`
    img {
      opacity: 0.2;
    }
  `}

  p {
    text-align: center;
  }
`;

const ImageFrame = styled.img`
  border-radius: 4px;
  border: solid 1px ${Colors.secondaryBlue};
  padding: .6rem;
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  opacity: ${({ opacity }) => opacity || 1};

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 2rem;
  grid-row-gap: .8rem;
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
`;

const colourStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    border: 'none',
    borderBottom: `1px solid ${Colors.secondaryBlue}`,
    cursor: 'pointer',
    margin: '1.3rem 0',
    borderRadius: '0rem',
    width: '100%',
  }),
  option: (base, state) => ({
    ...base,
    color: Colors.secondaryBlue,
    backgroundColor: state.isSelected ? '#eee' : base.backgroundColor,
    cursor: 'pointer',
  }),
  indicatorSeparator: () => { },
  dropdownIndicator: (base) => ({ ...base, '& svg': { fill: Colors.secondaryBlue } }),
  singleValue: (base) => ({ ...base, color: Colors.secondaryBlue }),
  placeholder: (base) => ({ ...base, color: Colors.secondaryBlue }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '7rem',
    overflow: 'auto',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '0rem',
    borderRadius: '0rem',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: Colors.secondaryBlue,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: Colors.mainWhite,
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: Colors.mainWhite,
  }),
};

const DateContainer = styled.div`
  margin: 0 5px;
  color: ${Colors.secondaryBlue};
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const DateSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const DatePicker = styled(DatePickerComponent)`
  border: 1px solid ${Colors.secondaryBlue};
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;

  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 10px;

  .ant-picker-input > * {
    color: ${Colors.secondaryBlue};
  }

  .ant-picker-input > input::placeholder {
    color: ${Colors.secondaryBlue};
  }
`;

export {
  ModalBase,
  Modal,
  FilterItem,
  ImageContainer,
  ImageFrame,
  Text,
  CheckboxContainer,
  StyledCheckbox,
  colourStyles,
  DateContainer,
  DatePicker,
  DateSubContainer,
};
