import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Card = styled.div`
  border-radius: .3rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem;
  margin: .6rem;
  cursor: pointer;
  transition: box-shadow .3s ease-in-out;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.16);
  outline: 1px solid transparent;
  position: relative;
  transition: margin-left .2s ease-in-out;

  &:hover {
    box-shadow: 0 .4rem 1rem 0 rgba(0, 0, 0, 0.16);
    outline: 1px solid ${Colors.blue};
  }

  @media only screen and (max-width: 600px) {
    border-radius: 0rem;
    margin: 0;
    width: 100%;
    box-shadow: none;
    box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid #ccc;
    margin-left: ${({ showHidden }) => showHidden && '5rem'};
  }

  @media only screen and (min-width: 600px) {
    &:hover {
      button.shown {
        display: block;
      }
    }
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: ${({ link }) => (link ? 'none' : 'unset')};

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

const Image = styled.img`
  margin: .6rem 0;
`;

const HiddenDelete = styled.button`
  width: 5rem;
  height: 100%;
  margin: 0;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset -0.3rem 0rem .5rem rgba(0, 0, 0, 0.05);

  border: none;
  outline: none;
  background-color: #d8d8d8;

  left: 0;
  transform: translate(-100%);
  z-index: 0;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export {
  Card,
  Text,
  Button,
  Image,
  HiddenDelete,
};
