import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';

import { useAxios } from '../services/api';

const UserContext = createContext(false);
const { Consumer: UserConsumer } = UserContext;

function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useState(null);

  const [axiosGet] = useAxios('get');

  useEffect(() => {
    const match = document.cookie.match(/(^| )user_id=([^;]+)/);

    if (match && !userInfo) {
      axiosGet({
        url: `/users/${match[2]}`,
        setState: setUserInfo,
        process: async (user) => {
          const resRole = await axiosGet({ url: `/roles/${user.roleId}` });
          const myCompany = resRole.data.data.name !== 'admin'
            ? await axiosGet({ url: `/companies/${user.companyId}` })
            : null;

          const vendmach = resRole.data.data.name === 'admin'
            ? (await axiosGet({ url: '/companies' })).data.data.find(({ name }) => (name === 'vendmach'))
            : null;

          return {
            ...user,
            isAdmin: resRole.data.data.name === 'admin',
            permissions: resRole.data.data.permissions,
            myCompany: vendmach || myCompany.data.data,
            ...(vendmach ? { companyId: vendmach._id } : null),
          };
        },
      });
    }
  }, [userInfo, axiosGet]);

  return (
    <UserContext.Provider value={[userInfo, setUserInfo]}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserInfo = () => useContext(UserContext);

export default UserContext;

export {
  UserProvider,
  UserConsumer,
};
