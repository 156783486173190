import React from 'react';

import DataBox from './styles';

export default function ({
  title,
  data,
  children,
  width,
  ...props
}) {
  return (
    <DataBox
      width={`${width === 100 ? 100 : width - 1}%`}
      {...props}
    >
      <h3>{title}</h3>
      {data && <p>{data}</p>}
      {children}
    </DataBox>
  );
}
