/* eslint-disable import/no-cycle */
import React, {
  memo,
  useEffect,
  useState,
} from 'react';

import {
  AdSection,
  Text,
  TypeSection,
  Icon,
  Button,
  IntervalHeader,
  IntervalInput,
  IntervalInputContainer,
  Error,
  AdContainer,
  Unit,
} from './styles';
import AdModal from '../AdModal';
import LazyLoader from '../LazyLoader';
import StaticAdCard from '../StaticAdCard';
import { url, useAxios } from '../../services/api';
import { useMachineDetailContext } from '../../state/MachineDetailContext';
import { useUserInfo } from '../../state/UserContext';

import addIcon from './assets/add.svg';
import alarmIcon from './assets/alarm.svg';
import stringifyTime from '../../utils/stringifyTime';

function AdType({
  title,
  detailedAds,
  adTypes,
  value,
  companyName,
  machine,
}) {
  const [open, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);

  const [canChooseAd, setCanChooseAd] = useState(null);

  const [axiosGet, axiosPatch] = useAxios('get', 'patch');

  const [, reloadInfo] = useMachineDetailContext();
  const [userInfo] = useUserInfo();

  const relatedAds = detailedAds?.filter((ad) => ad?.type === adTypes[title])
 
  const handleDelete = (id) => {
    const sendAds = machine.ads.filter(({ adId }) => adId !== id);
    axiosPatch({
      url: `/machines/${machine._id}`,
      body: { ads: sendAds },
      success: reloadInfo,
    });
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const res = await axiosGet({ url: `/companies/${userInfo.companyId}` });
      setCanChooseAd(res.data.data.canChooseAd);
    };

    if (userInfo !== null) {
      if (!userInfo.isAdmin) {
        fetchCompany();
      } else {
        setCanChooseAd(true);
      }
    }
  }, [userInfo, axiosGet]);

  return (
    <TypeSection key={title}>
      <header style={{ display: 'flex' }}>
        <Button onClick={() => setOpen(!open)}>
          <Text isTitle>
            {title}
            {' '}
            (
            {relatedAds?.length}
            )
          </Text>
        </Button>
        {canChooseAd && (
          <Button onClick={() => setAdding(true)}>
            <Icon src={addIcon} />
          </Button>
        )}
      </header>
      <AdContainer maxHeight={(open && detailedAds) ? Math.ceil(relatedAds?.length / 2) : 0}>
        {relatedAds?.map((ad) => (
          <StaticAdCard
            key={ad._id}
            type={Object.entries(adTypes).find(([, type]) => type === ad.type)[0]}
            title={ad.name}
            subtitle={companyName}
            img={ad.media}
            verified={ad.verified}
            time={stringifyTime(ad?.displayTime || 0)}
            startDate={ad.startDate}
            endDate={ad.endDate}
            machine={machine}
            handleDelete={() => handleDelete(ad._id)}
          />
        ))}
      </AdContainer>
      <AdModal
        active={adding}
        setActive={setAdding}
        type={value}
        machine={machine}
      />
    </TypeSection>
  );
}

function AdList({ machine }) {
  const [detailedAds, setDetailedAds] = useState(null);
  const [companyName, setCompanyName] = useState('');

  const [pageTopIntervalValue, setPageTopIntervalValue] = useState(machine.pageTopInterval);
  const [
    fullscreenIntervalValue,
    setFullscreenIntervalValue,
  ] = useState(machine.fullscreenInterval);
  const [loadingTimeout, setLoadingTimeout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [axiosGet, axiosPatch] = useAxios('get', 'patch');

  const adTypes = {
    //Topscreen: 'pageTop',
    Fullscreen: 'fullscreen',
    // Billboard: 'billboard',
  };

  useEffect(() => {
    const loadDetailedAds = async () => {
      const adPromises = machine?.ads.map(({ adId }) => axiosGet({ url: `/ads/${adId}` }));
      const adRes = await Promise.all(adPromises);
      setDetailedAds(adRes.map((res) => res?.data?.data));
    };

    loadDetailedAds();
  }, [machine, axiosGet]);

  useEffect(() => {
    axiosGet({
      url: `/companies/${machine.companyId}`,
      setState: setCompanyName,
      process: ({ name }) => name,
    });
  }, [machine.companyId, axiosGet]);

  const handleChange = (e, type) => {
    const text = e.target.value;
    if (type === 'fullscreen') {
      setFullscreenIntervalValue(text);
    } else {
      setPageTopIntervalValue(text);
    }
    if (loadingTimeout) clearInterval(loadingTimeout);
    setLoadingTimeout(setTimeout(() => {
      if (text === '') return;

      if (/^\d+$/.test(text)) {
        setError(false);
        setIsLoading(true);

        axiosPatch({
          url: `/machines/${machine._id}`,
          body: { [type === 'fullscreen' ? 'fullscreenInterval' : 'pageTopInterval']: text },
          success: () => setIsLoading(false),
          error: (err) => setError(err.response.message),
        });
      } else {
        setError('O intervalo só pode conter números');
      }
    }, 500));
  };

  return (
    <AdSection>
      {/* <IntervalHeader>
        <Text italic>Intervalo dos anúncios de Topscreen</Text>
        <IntervalInputContainer>
          <Icon style={{ margin: 0 }} src={alarmIcon} />
          <IntervalInput
            value={pageTopIntervalValue}
            onChange={(e) => handleChange(e, 'pageTop')}
          />
          <Unit>s</Unit>
        </IntervalInputContainer>
        {isLoading && <LazyLoader width="min-content" size="small" />}
        {error && <Error>{error}</Error>}
      </IntervalHeader> */}
      <IntervalHeader>
        <Text italic>Intervalo dos anúncios Fullscreen</Text>
        <IntervalInputContainer>
          <Icon style={{ margin: 0}} src={alarmIcon} />
          <IntervalInput
            value={fullscreenIntervalValue}
            onChange={(e) => handleChange(e, 'fullscreen')}
          />
          <Unit>s</Unit>
        </IntervalInputContainer>
        {isLoading && <LazyLoader width="min-content" size="small" />}
        {error && <Error>{error}</Error>}
      </IntervalHeader>
      {Object.entries(adTypes).map(([title, value]) => (
        <AdType
          key={title}
          title={title}
          detailedAds={detailedAds}
          adTypes={adTypes}
          value={value}
          companyName={companyName}
          machine={machine}
        />
      ))}
    </AdSection>
  );
}

export default memo(AdList);
