export const heatMapData = [
    {
      name: '00h',
      data: [0, 1, 1, 1, 1, 2, 0],
    },
    {
      name: '03h',
      data: [1, 1, 2, 1, 3, 1, 1],
    },
    {
      name: '06h',
      data: [1, 2, 3, 1, 2, 1, 1],
    },
    {
      name: '09h',
      data: [1, 2, 2, 3, 3, 2, 1],
    },
    {
      name: '12h',
      data: [1, 2, 3, 2, 2, 2, 1],
    },
    {
      name: '15h',
      data: [3, 4, 4, 5, 4, 3, 2],
    },
    {
      name: '18h',
      data: [3, 3, 4, 5, 5, 3, 1],
    },
    {
      name: '21h',
      data: [1, 2, 3, 4, 2, 2, 1],
    },
  ]