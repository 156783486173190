import React, { useState, useEffect } from 'react';
import { useAxios } from '../../services/api';
import { Radio, Tooltip} from 'antd';
import Modal from '../Modal';
import {
    CardsContainer, 
    Container, 
    NewButton, 
    CouponContainer,
    DateContainer,
    DatesWrapper,
    RadioButton, 
    ButtonContainer,
    Label,
    DatePicker,
   } from './styles';
import DiscountCard from '../DiscountCard';
function DiscountTab({ groupId }){
    const [discount, setDiscount] = useState(null);
    const [shouldCreateModalShow, setShouldCreateModalShow] = useState(false);
    const [axiosPatch, axiosGet, axiosDelete, axiosPost] = useAxios('patch', 'get', 'delete', 'post');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [type, setType] = useState('');
    const [value, setValue] = useState(null);
    const [couponQuantity, setCouponQuantity] = useState('');

    useEffect(() => {
        axiosGet({
            url: `/groups/${groupId}`,
            setState: setDiscount,
            process: (data) => data?.discounts,
        });
      }, [groupId, axiosGet]);
    const createCoupon = async () => {
        if( !value || ((name || code || couponQuantity || type) === '') ){
            throw new Error('Campos faltando');
        }
        try {
          await axiosPost({
            url:`/groups/create-coupon/${groupId}`,
            body: { 
                'coupon': {
                    'couponQuantity': Number(couponQuantity),
                    'couponsAvailable': 0,
                    'name': name,
                    'code': code,
                    'type': type,
                    'value': Number(value),
                    'startDate': startDate,
                    'endDate': endDate,
                }
            }
          })
        } catch (error) {
          console.error(error);
        }
        window.location.reload();
      }
    const msgcodigo = "O código deve possuir até 20 caracteres, sem espaços e sem caracteres especiais!"
    return(
        <Container>
        <CardsContainer>
                <ButtonContainer>
                    <NewButton onClick={() => setShouldCreateModalShow(true)}>
                    Adicionar Cupom
                    </NewButton>
                </ButtonContainer>
          <CouponContainer>
             {discount?.map((coupon) => ( 
              <>
                <DiscountCard key={coupon._id} groupId={groupId} coupon={coupon} couponList={discount}/>
              </>
             ))} 
          </CouponContainer>
        </CardsContainer>

        <Modal
          handleSubmit={createCoupon}
          active={shouldCreateModalShow}
          setActive={setShouldCreateModalShow}
          >
            <Modal.Title>Criação de Cupom</Modal.Title>
            <Label>Nome do Cupom:</Label>
            <Modal.Input
              value={name}
              setValue={setName} 
            />
            <Tooltip title={msgcodigo}>
                <Label>Código do Cupom:</Label>
                <Modal.Input
                value={code}
                onChange={(e) => {
                    const codeInput = e.target.value.toUpperCase().replaceAll(/[^A-Z0-9]/g, '').slice(0, 20);
                    setCode(codeInput);
                }}
                //   setValue={setCode}
                />
            </Tooltip>
            <Label>Quantidade Inicial:</Label>
            <Modal.Input
              value={couponQuantity}
              setValue={setCouponQuantity}
            />
            <Label>Tipo de Desconto:</Label>
            <Radio.Group buttonStyle="solid" value={type} onChange={(e) => setType(e.target.value)} style={{ display: 'flex', }}>
                <RadioButton value="Absoluto">Absoluto</RadioButton>
                <RadioButton value="Percentual">Percentual</RadioButton>
            </Radio.Group>
            <Label>Valor do Desconto:</Label>
            <Modal.Input value={value} setValue={setValue}/>
        <DatesWrapper>
          <DateContainer>
            <Label>Data Inicial</Label>
            <DatePicker
              defaultValue={startDate}
              onChange={setStartDate}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </DateContainer>
          <DateContainer>
            <Label>Data Final</Label>
            <DatePicker
              defaultValue={endDate}
              onChange={setEndDate}
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
            />
          </DateContainer>
        </DatesWrapper>
        <Modal.Button>Finalizar</Modal.Button>

        </Modal>

      </Container>
    );
}

export default DiscountTab