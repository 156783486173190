import React, {
  useState, useEffect, memo, useCallback,
} from 'react';
import { Spin } from 'antd';

import {
  List,
  Text,
  Button,
  Image,
  TableBottom,
  NavbarContainer,
  BodyScroll,
  TableFoot,
  Container,
} from './styles';
import { useAxios } from '../../services/api';

import allNextIcon from './Assets/all_next.svg';
import singleNextIcon from './Assets/single_next.svg';
import allPrevIcon from './Assets/all_prev.svg';
import singlePrevIcon from './Assets/single_prev.svg';
import disabledAllNextIcon from './Assets/disabled_all_next.svg';
import disabledSingleNextIcon from './Assets/disabled_single_next.svg';
import disabledAllPrevIcon from './Assets/disabled_all_prev.svg';
import disabledSinglePrevIcon from './Assets/disabled_single_prev.svg';

function PaginatedList({
  component: Comp,
  url,
  process,
  itemsPerPage,
  options,
  otherProps,
  setReloadList,
}) {
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);

  const [axiosGet] = useAxios('get');

  const fetchData = useCallback(async () => {
    try {
      setData(null);

      const requestUrl = `${url}${options ? '&' : '?'}itemsPerPage=${itemsPerPage}&page=${page}`;
      const res = await axiosGet({ url: requestUrl });
      setPagination(res.data?.pagination);

      const trueData = process ? await process(res.data.data) : res.data.data;
      setData(trueData);
    } catch (err) {
      console.log(err);
    }
  }, [url, itemsPerPage, options, page, process, axiosGet]);

  useEffect(() => {
    setReloadList(() => fetchData);
  }, [fetchData, setReloadList]);

  const addNewItem = useCallback((user) => {
    setData([user, ...data]);
  }, [data]);

  useEffect(() => {
    fetchData();
  }, [url, process, itemsPerPage, page, options, fetchData]);

  const prevDisabled = page === 1;
  const nextDisabled = page === pagination?.totalPages;

  return (
    <List>
      <BodyScroll>
        {data ? data.map((item) => (
          <Comp addNewItem={addNewItem} info={item} key={item._id} {...otherProps} />
        )) : (
          <Container>
            <td>
              <Spin size="large" spinning />
            </td>
          </Container>
        )}
      </BodyScroll>
      <TableFoot>
        <tr style={{ display: 'block', width: '100%', height: '100%' }}>
          <TableBottom>
            <NavbarContainer>
              <Button disabled={prevDisabled} onClick={() => setPage(1)}>
                <Image src={prevDisabled ? disabledAllPrevIcon : allPrevIcon} />
              </Button>
              <Button
                disabled={prevDisabled}
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
              >
                <Image src={prevDisabled ? disabledSinglePrevIcon : singlePrevIcon} />
              </Button>
              <Text>
                Página
                {' '}
                {page}
                {' '}
                de
                {' '}
                {pagination?.totalPages}
              </Text>
              <Button
                disabled={nextDisabled}
                onClick={() => {
                  if (page < pagination?.totalPages) setPage(page + 1);
                }}
              >
                <Image src={nextDisabled ? disabledSingleNextIcon : singleNextIcon} />
              </Button>
              <Button disabled={nextDisabled} onClick={() => setPage(pagination?.totalPages)}>
                <Image src={nextDisabled ? disabledAllNextIcon : allNextIcon} />
              </Button>
            </NavbarContainer>
          </TableBottom>
        </tr>
      </TableFoot>
    </List>
  );
}

export default memo(PaginatedList);
