import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const Container = styled.div`
  margin: 50px;
  margin-right: 0;

  @media(max-width: 1024px) {
    margin: 0;
  }
`;

export const ConfirmationModal = styled(Modal)``;

export const AddButton = styled(Button)`
  margin-bottom: 50px;
  padding-left: 40px;
  padding-right: 40px;

  border-radius: 4px;

  font-style: italic;
`;

export const ListContainer = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;
