import React, { useRef, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import {
  CategoryCardContainer,
  CategoryCard,
  CategoryIcon,
  CategoryDelete,
  StyledModal,
  EditButton,
} from './styles';

import { useAxios } from '../../../../services/api';

import deleteIconIcon from './assets/delete-category.png';

const CategoryCardComponent = ({
  category,
  setCategories,
  url,
  setTargetEditModal,
  setShouldCategoryModalShow,
  setCategoryName,
  setItemsToAdd,
  setModalSelectedCategoryIcon,
  index,
  moveCard,
}) => {
  const deleteRef = useRef();
  const fragmentRef = useRef();
  const ref = useRef(null);

  const [shouldDeleteShow, setShouldDeleteShow] = useState(false);
  const [shouldModalShow, setShouldModalShow] = useState(false);

  const [icon, setIcon] = useState({});

  const [axiosGet, axiosDelete] = useAxios('get', 'delete');

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id: category._id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const fetchItems = async () => {
    const itemsResponse = await Promise.all(
      category.items.map((itemId) => axiosGet({ url: `/items/${itemId}` })),
    );

    const productsResponse = await Promise.all(
      itemsResponse.map((eachItem) => (
        axiosGet({ url: `/products/${eachItem.data.data.productId}` })
      )),
    );

    setItemsToAdd(itemsResponse.map((item) => {
      const productIndex = productsResponse.findIndex((product) => (
        product.data.data._id === item.data.data.productId
      ));

      return {
        ...item.data.data,
        value: productsResponse[productIndex].data.data.name,
        image: productsResponse[productIndex].data.data.image,
      };
    }));
  };

  useEffect(() => {
    const fetchIcon = async () => {
      const { data: iconData } = await axiosGet({ url: `/category-icons/${category.icon}` });

      setIcon(iconData?.data);
    };

    fetchIcon();
  }, [category]);

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <CategoryCardContainer ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <CategoryCard
        onClick={(e) => {
          if (deleteRef.current.contains(e.target)) return;
          if (fragmentRef.current.contains(e.target)) return;

          setShouldDeleteShow(!shouldDeleteShow);
        }}
        shouldDeleteShow={shouldDeleteShow}
      >
        <EditButton
          ref={fragmentRef}
          onClick={async () => {
            setShouldCategoryModalShow(true);
            fetchItems();
            setTargetEditModal(category._id);
            setModalSelectedCategoryIcon(icon);
            setCategoryName(category.name);
          }}
        >
          <CategoryIcon src={icon.enabledImage} alt="icone" />
          {category.name}
        </EditButton>
      </CategoryCard>

      <CategoryDelete
        ref={deleteRef}
        onClick={() => setShouldModalShow(true)}
      >
        <img src={deleteIconIcon} alt="Deletar categoria" />
      </CategoryDelete>

      <StyledModal
        title="Deletar categoria"
        visible={shouldModalShow}
        onCancel={() => setShouldModalShow(false)}
        onOk={async () => {
          await axiosDelete({ url: `/categories/${category._id}` });

          setCategories((previousValue) => previousValue.filter(((oldCategory) => (
            oldCategory._id !== category._id
          ))));

          setShouldModalShow(false);
        }}
      >
        <p>
          Tem certeza que deseja deletar a categoria
          {' '}
          {category.name}
          ?
        </p>
      </StyledModal>
    </CategoryCardContainer>
  );
};

export default CategoryCardComponent;
