import React, { useState, useEffect } from 'react';

import {
  Card,
  Container,
  DropContainer,
  Text,
  Image,
  ButtonContainer,
  Button,
  ButtonText,
  DropDownButton,
  DropDownContainer,
} from './styles';
import { useAxios } from '../../services/api';
import { UserConsumer } from '../../state/UserContext';

import emailIcon from './Assets/email.svg';
import phoneIcon from './Assets/phone.svg';
import companyIcon from './Assets/bolsa.svg';
import deleteIcon from './Assets/delete.svg';
import createIcon from './Assets/create.svg';
import deleteBorderlessIcon from './Assets/ic-delete.svg';
import createBorderlessIcon from './Assets/ic-edit.svg';
import arrowIcon from './Assets/arrow.svg';
import UserModal from '../UserModal';
import WarnModal from '../WarnModal';

const InfoText = ({ text, src, dropdown }) => (
  <Text black opacity={0.8} dropdown={dropdown}>
    <Image src={src} />
    {text}
  </Text>
);

const OptionButton = ({
  src,
  dropdown,
  active,
  onClick,
  text,
}) => (
  <Button onClick={onClick} active={active} dropdown={dropdown}>
    <Image noMargin src={src} />
    <ButtonText>
      {text}
    </ButtonText>
  </Button>
);

function UserCard({
  info,
  roles,
  companies,
  machines,
}) {
  const {
    name,
    email,
    phone,
    type,
    companyId,
    _id,
  } = info;

  const [active, setActive] = useState(false);
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [showDropDown, setShowDropDown] = useState();
  const [mobile, setMobile] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name,
    email,
    phone,
    type,
  });

  const [axiosGet, axiosDelete] = useAxios('get', 'delete');

  const handleToggle = (event) => {
    if (mobile && event.target.tagName !== 'BUTTON') setShowDropDown(!showDropDown);
  };
  const handleEdit = () => setEditing(true);
  const handleDelete = () => setDeleting(true);

  const checkBreakpoint = () => setMobile(window.innerWidth < 600);

  useEffect(() => {
    checkBreakpoint();
    window.addEventListener('resize', checkBreakpoint);
    return () => {
      window.removeEventListener('resize', checkBreakpoint);
    };
  }, []);

  const reloadInfo = async () => {
    const resUser = await axiosGet({ url: `/users/${_id}` });
    const user = resUser.data.data;
    const resRole = await axiosGet({ url: `/roles/${user.roleId}` });
    const role = resRole.data.data;

    setUserInfo({
      name: user.name,
      email: user.email,
      phone: user.phone,
      type: role.name,
    });
  };

  if (deleted) return null;

  const companyName = companies?.find(({ value }) => value === companyId)?.label || 'vendmach';

  return (
    <Card
      onMouseOver={() => setActive(true)}
      onFocus={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={handleToggle}
    >
      <Container>
        <div style={{
          height: 'inherit',
          width: '100%',
          backgroundColor: 'white',
        }}
        >
          <div style={{ float: 'left', zIndex: 1 }}>
            <Text isTitle>
              {userInfo.name}
            </Text>
            <Text black opacity={0.5} italic>
              {userInfo.type}
            </Text>
          </div>
          <DropDownButton>
            <Image src={arrowIcon} upside={showDropDown} />
          </DropDownButton>
        </div>
        <DropDownContainer show={showDropDown}>
          <div style={{ width: '100%' }}>
            <InfoText text={userInfo.email} src={emailIcon} />
            <InfoText text={userInfo.phone} src={phoneIcon} />
            <InfoText text={companyName} src={companyIcon} />
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <UserConsumer>
              {([loggedUserInfo]) => {
                const { update, remove } = loggedUserInfo?.permissions.user;
                return (
                  <>
                    {update && (
                      <OptionButton text="Editar" onClick={handleEdit} src={createBorderlessIcon} dropdown />
                    )}
                    {remove && (
                      <OptionButton text="Excluir" onClick={handleDelete} src={deleteBorderlessIcon} dropdown />
                    )}
                  </>
                );
              }}
            </UserConsumer>
          </div>
        </DropDownContainer>
      </Container>
      {!showDropDown && (
        <>
          <DropContainer>
            <InfoText text={userInfo.email} src={emailIcon} />
            <InfoText text={userInfo.phone} src={phoneIcon} />
          </DropContainer>
          <DropContainer>
            <InfoText text={companyName} src={companyIcon} />
            <InfoText text="" src={null} />
          </DropContainer>
          <ButtonContainer>
            <UserConsumer>
              {([loggedUserInfo]) => {
                const { update, remove } = loggedUserInfo?.permissions.user;
                return (
                  <>
                    {update && (
                      <OptionButton onClick={handleEdit} active={active} src={createIcon} />
                    )}
                    {remove && (
                      <OptionButton onClick={handleDelete} active={active} src={deleteIcon} />
                    )}
                  </>
                );
              }}
            </UserConsumer>
          </ButtonContainer>
        </>
      )}
      <td>
        <UserModal
          active={editing}
          setActive={setEditing}
          options={{ title: 'Editar Usuário', buttonValue: 'Salvar' }}
          type="update"
          user={info}
          roles={roles}
          companies={companies}
          machines={machines}
          accept={reloadInfo}
        />
      </td>
      <td>
        <WarnModal
          active={deleting}
          setActive={setDeleting}
          title="Excluir Usuário?"
          text={(
            <>
              Tem certeza que deseja excluir o usuário
              {' '}
              <span>{name}</span>
              ?
            </>
          )}
          accept={async () => {
            try {
              await axiosDelete({ url: `/users/${_id}` });
              setDeleted(true);
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </td>
    </Card>
  );
}

export default UserCard;
