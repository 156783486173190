import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from 'react';
import FormSelect from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  ModalBase,
  ModalContent,
  FormInput,
  FormButton,
  CloseButton,
  Image,
  Title,
  SubTitle,
  FileButton,
  FileText,
  Textarea,
  colourStyles,
  Label,
} from './styles';
import { useErrorContext } from '../../state/ErrorContext';
import LazyLoader from '../LazyLoader';

import closeIcon from './Assets/close.svg';

const ModalContext = createContext(false);

function Select({
  value,
  setValue,
  options,
  ...props
}) {
  return (
    <FormSelect
      value={value}
      onChange={setValue}
      isSearchable={false}
      options={options}
      styles={colourStyles}
      {...props}
    />
  );
}

function SearchSelect({
  value,
  setValue,
  loadOptions,
  defaultOptions,
  isSearchable,
  ...props
}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    defaultOptions('', (options) => {
      setItems(options);
    });
  }, [defaultOptions]);

  return (
    <AsyncSelect
      value={value}
      isSearchable={isSearchable}
      onChange={setValue}
      defaultOptions={items}
      loadOptions={loadOptions}
      styles={colourStyles}
      {...props}
    />
  );
}

function Input({
  value = '',
  setValue = () => { },
  type = 'text',
  ...props
}) {
  return (
    <FormInput
      value={value}
      type={type}
      onChange={(e) => setValue(e.target.value)}
      required
      {...props}
    />
  );
}

function TextArea({
  value = '',
  setValue = () => { },
  ...props
}) {
  return (
    <Textarea
      value={value}
      onChange={(e) => setValue(e.target.value)}
      required
      {...props}
    />
  );
}

function FileInput({
  value = '',
  setValue = () => {},
  placeholder,
  children,
}) {
  const [text, setText] = useState(placeholder);
  const inputRef = useRef(null);

  const handleFile = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    const fileName = e.target.files[0].name;

    setValue(e.target.files[0]);
    setText(fileName);
  };

  useEffect(() => {
    if (value === '') {
      inputRef.current.value = '';
    } else {
      inputRef.current.files = value;
    }
  }, [value]);

  return (
    <div>
      <input onChange={handleChange} ref={inputRef} type="file" hidden required />
      <FileButton type="button" onClick={handleFile}>
        {children}
        <FileText>
          {text}
        </FileText>
      </FileButton>
    </div>
  );
}

function Button({ type = 'submit', children, ...props }) {
  const [loading] = useContext(ModalContext);

  return (
    <FormButton opacity={loading ? 0.5 : 1} type={type} {...props}>
      {!loading && children}
      {loading && <LazyLoader width="min-content" size="small" />}
    </FormButton>
  );
}

function Modal({
  active,
  setActive,
  children,
  handleSubmit,
}) {
  const [, setError] = useErrorContext();

  const [loading, setLoading] = useState(false);

  const handleCloseModal = (e) => {
    if (e.target.id === 'shade') setActive(false);
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await handleSubmit(e);
      setActive(false);
    } catch (err) {
      setError({
        type: 'modal/post',
        message: err.response?.data?.message || err.message,
        status: err.response?.status || false,
        url: null,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase id="shade" active={active} onClick={handleCloseModal}>
      <ModalContext.Provider value={[loading, setLoading]}>
        <ModalContent noValidate onSubmit={submitHandler}>
          <CloseButton type="button" onClick={() => setActive(false)}>
            <Image src={closeIcon} />
          </CloseButton>
          {children}
        </ModalContent>
      </ModalContext.Provider>
    </ModalBase>
  );
}

Modal.Title = Title;
Modal.SubTitle = SubTitle;
Modal.Label = Label;
Modal.Select = Select;
Modal.SearchSelect = SearchSelect;
Modal.FileInput = FileInput;
Modal.Input = Input;
Modal.TextArea = TextArea;
Modal.Button = Button;

export default Modal;
