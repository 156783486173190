import React, { useState } from 'react';

import {
  Container,
  TitleContainer,
  Title,
  Alternator,
  Header,
  Tab,
  Text,
  Indicator,
} from './styles';
import MachineList from '../MachineList';
import TeamList from '../TeamList';
import DiscountTab from '../DiscountTab';

function GroupLists({
  companyId,
  groupName,
  groupId,
  headerTabs,
}) {
  const [listIndex, setListIndex] = useState(0);

  const componentArr = [MachineList, /*TeamList*/, DiscountTab];

  const CompToRender = componentArr[listIndex];

  const handleIndexChange = (index) => {
    if (listIndex !== index) setListIndex(index);
  };

  return (
    <Container>
      <TitleContainer>
        <Title>{groupName}</Title>
      </TitleContainer>
      <Alternator>
        <Header>
          {headerTabs.map((tab, i) => (
            <Tab key={tab} onClick={() => handleIndexChange(i)}>
              <Text active={listIndex === i}>{tab}</Text>
              <Indicator active={listIndex === i} />
            </Tab>
          ))}
        </Header>
        <CompToRender groupId={groupId} groupName={groupName} companyId={companyId} />
      </Alternator>
    </Container>
  );
}

export default GroupLists;
