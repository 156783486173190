import styled from 'styled-components';

import colors from '../../../../styles/Colors';

export const CardContainer = styled.div`
  box-shadow: ${({ isFromModal }) => (!isFromModal && `0 0 3px 0 ${colors.secondaryBlack}` : 'none')};

  ${({ isFromModal }) => isFromModal && (`
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  `)}

  width: ${({ isFromModal }) => (isFromModal ? '100%' : '40%')};
  margin-bottom: 20px;
  position: relative;
  display: flex;
  padding: 10px;

  &:hover {
    ${({ isFromModal }) => !isFromModal && 'box-shadow: 0 0 6px 0 ${colors.secondaryBlue};'}
  }

  @media(max-width: 1024px) {
    width: 100%;
  }
`;

export const ChildContent = styled.div`
  width: 50%;
  display: flex;

  &:first-child {
    flex-direction: column;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;

export const Name = styled.span`
  color: ${colors.blue};
`;

export const Number = styled.span`
  color: ${colors.grey};
`;

export const ActionButton = styled.button`
  border: none;
  background: transparent;
  margin-left: 10px;
  outline: none;

  transition: 0.2s;

  &:hover {
    transform: scale(1.04);
  }
`;

export const Icon = styled.img``;
