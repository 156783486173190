import React, { useState } from 'react';

import {
  Header,
  StyledCollapse,
  StyledPanel,
  FinalButton,
  InputContainer,
  Label,
  Input as StyledInput,
} from './styles';

const Input = ({
  name,
  value,
  setValue,
  label,
  shoudClick,
  setClicked,
}) => (
  <InputContainer
    onClick={(event) => {
      if (shoudClick) {
        setClicked(true);
      } else {
        event.stopPropagation();
      }
    }}
  >
    <Label htmlFor={name}>{label}</Label>
    <StyledInput name={name} value={value} onChange={(e) => setValue(e.target.value)} />
  </InputContainer>
);

const HeaderComponent = ({
  name,
  setName,
  capacity,
  setCapacity,
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <Header>
      <Input
        shoudClick={!clicked}
        setClicked={setClicked}
        name="name"
        value={name}
        setValue={setName}
        label="Nome do slot"
      />
      <Input
        shoudClick={!clicked}
        setClicked={setClicked}
        name="capacity"
        value={capacity}
        setValue={setCapacity}
        label="Capacidade"
      />
    </Header>
  );
};

const SlotsCardCreate = ({
  defaultSlot = {},
  setSlots,
  editing,
  onCancel,
}) => {
  const [activeKey, setActiveKey] = useState(editing ? ['1'] : []);
  const [name, setName] = useState(defaultSlot.name || '');
  const [capacity, setCapacity] = useState(defaultSlot.size || '');

  const handleSubmit = async () => {
    const newSlot = { ...(defaultSlot || { quantity: 0 }), name, size: Number(capacity) };

    setSlots((prevSlots) => ([
      ...prevSlots,
      newSlot,
    ]), newSlot);
    setActiveKey([]);
    setName('');
    setCapacity('');
  };

  return (
    <StyledCollapse
      onChange={() => !editing && setActiveKey(activeKey.length ? [] : ['1'])}
      activeKey={activeKey}
    >
      <StyledPanel
        key={['1']}
        showArrow={false}
        header={(
          <HeaderComponent
            name={name}
            setName={setName}
            capacity={capacity}
            setCapacity={setCapacity}
          />
        )}
      >
        <FinalButton onClick={handleSubmit} type="primary">OK</FinalButton>
        <FinalButton onClick={() => {
          if (onCancel) {
            onCancel();
            return;
          }
          setActiveKey([]);
          setName('');
          setCapacity('');
        }}
        >
          Cancelar
        </FinalButton>
      </StyledPanel>
    </StyledCollapse>
  );
};

export default SlotsCardCreate;
