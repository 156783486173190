import { Switch } from 'antd';
import React, { useState } from 'react';
import { useAxios } from '../../services/api';
import QuestionModal from '../QuestionModal';
import {
 Card, Header, Type, Title,
} from './styles';

function QuestionCard({ question }) {
  const [displayQuestion, setDisplayQuestion] = useState(question);
  const [modalActive, setModalActive] = useState(false);

  const [axiosPatch] = useAxios('patch');

  const changeDisplayQuestionProperty = (newDisplayQuestion) => {
    setDisplayQuestion((prevDisplayQuestion) => ({
      ...prevDisplayQuestion,
      ...newDisplayQuestion,
    }));
  };

  const handleCardClick = (event) => {
    if (event.target.classList.contains('clickable')) {
      setModalActive(true);
    }
  };

  const updateQuestion = async (newQuestion) => {
    const res = await axiosPatch({
      url: `/questions/${question._id}`,
      body: newQuestion,
    });

    setDisplayQuestion(res.data.data);
  };

  const typeMap = {
    'selection-box': 'Caixa de seleção',
    'multiple-choice': 'Múltipla escolha',
  };

  return (
    <>
      <Card className="clickable" onClick={handleCardClick}>
        <Header>
          <Type className="clickable">{typeMap[displayQuestion.type] || 'Caixa de seleção'}</Type>
          <Switch
            size="small"
            checked={displayQuestion.active}
            onChange={(value) => {
              changeDisplayQuestionProperty({ active: value });
              updateQuestion({ active: value });
            }}
          />
        </Header>
        <Title className="clickable">{displayQuestion.content}</Title>
      </Card>
      <QuestionModal
        modalTitle="Edição de pergunta"
        active={modalActive}
        setActive={setModalActive}
        handleSubmit={updateQuestion}
        question={displayQuestion}
      />
    </>
  );
}

export default QuestionCard;
