import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { Radio } from 'antd';
import { DatePicker as DatePickerComponent} from 'antd';
import Modal from '../Modal';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-start;
  `;

export const NewButton = styled.button`
  background-color: ${Colors.blue};
  padding: .3rem 2.6rem;
  outline: none;
  border: none;
  border-radius: .2rem;
  font-style: italic;
  color: #fff;
  white-space: nowrap;
  opacity: 1;
  cursor: pointer;
  margin-left: 2.5rem;
  @media(max-width: 600px) {
    margin-left: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
   @media(max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: left;
  flex-wrap: wrap;
`;

export const HeaderTitle = styled.h3`
  margin-bottom: 0; 
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  color: ${Colors.secondaryBlack};
  font-weight: bold;
  min-height: 1.3rem;
  font-size: 16px;

  /* @media(max-width: 600px) {
    font-size: 14px;
    white-space: normal;
  } */
`;

export const HeaderSubtitle = styled.h3`
  color: ${Colors.grey};
  font-style: italic;
  font-size: 0.7rem;
  /* @media(max-width: 600px){
    font-size: 0.6rem;
  } */
`;

export const CouponContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 16rem));
  justify-content: flex-start;
  justify-items: center;
  align-items: start;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: #f5f5f5; */
  padding: 16px;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 1rem;
  }
`;

export const DateContainer = styled.div`
   display: flex;
  flex-direction: column;
  width: 30%;
  @media(max-width: 1024px) {
    width: 100%;
  }
`;

export const DatesWrapper = styled.div`
 display: flex;
  gap: 1rem;
  align-items: center;

  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const RadioButton = styled(Radio.Button)`
  color: #016ec2;
  &.ant-radio-group-solid, &.ant-radio-button-wrapper-checked {
    background-color: ${Colors.secondaryBlue} !important;
    border-color: ${Colors.secondaryBlue} !important;
  }

  &:not(.ant-radio-button-wrapper-checked):hover {
    color: ${Colors.secondaryBlue} !important;
  }
`;

export const DatePicker = styled(DatePickerComponent)`
  border: 1px solid #016ec2;
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 90%;

  .ant-picker-input > * {
    color: #016ec2;
  }

  .ant-picker-input > input::placeholder {
    color: #016ec2;
  }
`;

export const Label = styled(Modal.Label)`
  color: #016ec2;
`;

