import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import {
  PaginatedList,
  CustomerCard,
  UserModal,
  Title,
  SearchHeader,
  LazyLoader,
} from '../../components';
import {
  Panel,
  ContentContainer,
} from './styles';
import { useAxios } from '../../services/api';
import { stringifyFilter } from '../../utils';
import { useUserInfo } from '../../state/UserContext';

function Customers() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);

  const [userInfo] = useUserInfo();
 
  const [axiosGet] = useAxios('get');

  const [reloadList, setReloadList] = useState(() => () => { });

  const filterArr = stringifyFilter(filter || []);
  const filterUrl = filter ? `${filterArr.join('&')}` : '';

  const url = `/customers?userId=${userInfo?._id}&${filterUrl}&search=${search}`;

  return (
    <Panel>
      <Title title="Consumidores" />
      <ContentContainer>
        { userInfo?._id ?
          <>
          <SearchHeader
            filter={filter}
            setFilter={setFilter}
            setSearch={setSearch}
            filterOptions={['Ordenar A-Z']}
            type="user"
            hasButton={false}
          >
            {(active, setActive) => (
              <UserModal
                active={active}
                setActive={setActive}
                options={{ title: 'Novo Usuário', buttonValue: 'Finalizar' }}
                type="create"
                reloadList={reloadList}
              />
            )}
          </SearchHeader>
          <PaginatedList
            component={CustomerCard}
            url={url}
            options
            itemsPerPage={10}
            setReloadList={setReloadList}
          />
        </> :
         <LazyLoader></LazyLoader>}
      </ContentContainer>
    </Panel>
  );
}

export default Customers;
