import { Radio, Tag } from 'antd';
import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const RadioButton = styled(Radio.Button)`
  &.ant-radio-group-solid, &.ant-radio-button-wrapper-checked {
    background-color: ${Colors.secondaryBlue} !important;
    border-color: ${Colors.secondaryBlue} !important;
  }

  &:not(.ant-radio-button-wrapper-checked):hover {
    color: ${Colors.secondaryBlue} !important;
  }
`;

export const AlternativesInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const AlternativesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const AlternativeTag = styled(Tag)`
  margin-bottom: 5px;
`;
