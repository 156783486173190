import React, { useState, useEffect, useCallback } from 'react';
import { Switch } from 'antd';

import { Redirect } from 'react-router';
import {
  Card,
  Container,
  ButtonContainer,
  DropContainer,
  DropDownButton,
  Text,
  Icon,
  Button,
  Image,
  Stripe,
  DropConditional,
} from './styles';
import { useAxios } from '../../services/api';
import { useUserInfo } from '../../state/UserContext';

import groupIcon from './assets/group.svg';
import deleteBorderlessIcon from './assets/ic-delete.svg';
import createBorderlessIcon from './assets/ic-edit.svg';
import arrowIcon from './assets/arrow.svg';
import copyIcon from './assets/copy.svg';
import Modal from '../Modal';
import { MachineConsumer } from '../../state/MachineContext';
import WarnModal from '../WarnModal';
import { SlotsFormList } from '..';
import { useFirstRender } from '../../hooks/useFirstRender';

const OptionButton = ({
  src,
  dropdown,
  active,
  onClick,
}) => (
  <Button onClick={onClick} active={active} dropdown={dropdown}>
    <Image noMargin src={src} />
  </Button>
);

const DropDownItem = ({ title, value }) => (
  <>
    <Text black italic opacity={0.8} style={{ fontSize: '.6rem' }}>
      {title}
    </Text>
    <Text black style={{ marginLeft: '.6rem' }}>
      {value}
    </Text>
  </>
);

function MachineCard({
  info,
}) {
  const {
    _id,
    name,
    code,
    groupId,
    companyId,
    groupName,
    companyName,
    machineTypeName,
    type,
    available,
    slots,
    sampling,
    signage,
  } = info;
  const [showDropDown, setShowDropDown] = useState(false);
  const [disponibility, setDisponibility] = useState(available);
  const [mobile, setMobile] = useState(null);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const [axiosPatch, axiosGet, axiosDelete, axiosPost] = useAxios('patch', 'get', 'delete', 'post');

  const [userInfo] = useUserInfo();

  const firstRender = useFirstRender();

  const [redirect, setRedirect] = useState(false);

  const [machineName, setMachineName] = useState(name);
  const [machineCode, setMachineCode] = useState(code);
  const [machineGroup, setMachineGroup] = useState({ label: groupName, value: groupId });
  const [machineCompany, setMachineCompany] = useState({ label: companyName, value: companyId });
  const [machineType, setMachineType] = useState({ label: type, value: type });
  const [machineAvailable, setMachineAvailable] = useState(available);
  const [machineSampling, setMachineSampling] = useState(sampling);
  const [machineSignage, setMachineSignage] = useState(signage);
  const [machineSlots, setMachineSlots] = useState(slots);

  const [machineNameClone, setMachineNameClone] =  useState(machineName);
  const [machineCodeClone, setMachineCodeClone] = useState(machineCode);
  const [machineGroupClone, setMachineGroupClone] = useState(machineGroup);
  const [machineCompanyClone, setMachineCompanyClone] = useState(machineCompany);
  const [machineTypeClone, setMachineTypeClone] = useState(machineType);
  const [machineSamplingClone, setMachineSamplingClone] = useState(machineSampling);
  const [machineSignageClone, setMachineSignageClone] = useState(machineSignage);
  const [disponibilityClone, setDisponibilityClone] = useState(disponibility);
  const [machineSlotsClone, setMachineSlotsClone] = useState(machineSlots);
  const [isPromoter, setIsPromoter] = useState(false);

  let responseClone;
  const [idClone, setIdClone] = useState("");

  const [machine, setMachine] = useState({
    name,
    code,
    groupName,
    companyName,
    machineTypeName,
    available,
    slots,
  });

  useEffect(() => {
    const getRole = async () => {
      if (userInfo) {
        const request = await axiosGet({url: `roles/${userInfo?.roleId}`});
        const role = request.data.data.name;
        setIsPromoter(role === "Promoter");
      }
    }
    getRole();
  }, [userInfo]);

  const checkBreakpoint = () => setMobile(window.innerWidth < 900 ? window.innerWidth : false);

  useEffect(() => {
    checkBreakpoint();
    window.addEventListener('resize', checkBreakpoint);
    return () => {
      window.removeEventListener('resize', checkBreakpoint);
    };
  }, []);

  useEffect(() => {
    if(!firstRender) {
      axiosPatch({
        url: `/machines/${_id}`,
        body: { available: disponibility },
      });
    }
  }, [disponibility, available, _id, axiosPatch]);

  useEffect(() => {
    if(!firstRender) {
      axiosPatch({
        url: `/machines/${_id}`,
        body: { sampling: machineSampling },
      });
    }
  }, [machineSampling, _id, axiosPatch]);

  useEffect(() => {
    if(!firstRender) {
      axiosPatch({
        url: `/machines/${_id}`,
        body: { signage: machineSignage },
      });
    }
  }, [machineSignage, _id, axiosPatch]);

  useEffect(() => {
    if (machineCompany.value !== companyId) {
      setMachineGroup(null);
    }
  }, [machineCompany, companyId]);

  const loadOptions = useCallback((id) => (async (inputValue, callback) => {
    const res = await axiosGet({ url: `/groups?filter=${JSON.stringify({ companyId: id })}` });

    const options = res.data.data.map(({ _id: value, name: label }) => ({ label, value }));

    callback(options);
  }), [axiosGet]);

  const handleEdit = async () => {
    const changeObj = {};
    if (
      machineName === ''
      || machineCode === ''
      || machineGroup === null
      || machineCompany === null
      || machineType === null
      || machineSlots.length === 0
    ) {
      throw new Error('Campos faltando');
    }
    if (machineName !== name) changeObj.name = machineName;
    if (machineCode !== code) changeObj.code = machineCode;
    if (machineGroup.value !== groupId) changeObj.groupId = machineGroup.value;
    if (machineCompany.value !== companyId) changeObj.companyId = machineCompany.value;
    if (machineType.value !== type) changeObj.type = machineType.value;
    if (machineAvailable !== available) changeObj.available = machineAvailable;
    if (machineSampling !== sampling) changeObj.sampling = machineSampling;
    if (
      JSON.stringify(slots) !== JSON.stringify(machineSlots)
    ) changeObj.slots = machineSlots;

    if (Object.keys(changeObj).length === 0) {
      throw new Error('Campos não foram alterados');
    }


    await axiosPatch({
      url: `/machines/${_id}`,
      body: changeObj,
    });

    setMachine({
      ...machine,
      ...changeObj,
      groupName: machineGroup.label,
      companyName: machineCompany.label,
      machineTypeName: machineType.label,
    });
  };

  const handleDuplicate = async () => {
    const changeObj = {};
    if (
      machineNameClone === ''
      || machineCodeClone === ''
      || machineGroupClone === null
      || machineCompanyClone === null
      || machineTypeClone === null
      || machineSlotsClone.length === 0
    ) {
      throw new Error('Campos faltando');
    }
    if (machineNameClone !== name) changeObj.name = machineNameClone;
    if (machineCodeClone !== code) changeObj.code = machineCodeClone;
    if (machineGroupClone.value !== groupId) changeObj.groupId = machineGroupClone.value;
    if (machineCompanyClone.value !== companyId) changeObj.companyId = machineCompanyClone.value;
    if (machineTypeClone.value !== type) changeObj.type = machineTypeClone.value;
    if (disponibilityClone !== available) changeObj.available = disponibilityClone;
    if (machineSamplingClone !== sampling) changeObj.sampling = machineSamplingClone;
    if (
      JSON.stringify(slots) !== JSON.stringify(machineSlotsClone)
    ) changeObj.slots = machineSlotsClone;

    if (Object.keys(changeObj).length === 0) {
      throw new Error('Campos não foram alterados');
    }


    await axiosPatch({
      url: `/machines/${idClone}`,
      body: changeObj,
      success: () => window.location.reload(true),
    });

  };

  if (deleted) return null;

  if (redirect) return <Redirect to={redirect} />;

  const { update, remove, editableFields, create } = userInfo?.permissions.machine || {};

  const canEdit = (field) => update || editableFields?.includes(field);

  return (
    <Card
      showDropDown={showDropDown}
      options={update || remove || editableFields?.length > 0}
      onClick={(e) => {
        const { tagName } = e.target;
        if (
          tagName !== 'BUTTON'
          && tagName !== 'IMG'
          && tagName !== 'INPUT'
          && tagName !== 'SPAN'
          && tagName !== 'DIV'
          && tagName !== 'P'
          && mobile
        ) {
          if (!update && !remove) {
            if (mobile < 750) {
              setShowDropDown(!showDropDown);
            }
          } else {
            setShowDropDown(!showDropDown);
          }
        }
      }}
    >
      <Container>
        <div style={{ float: 'left', zIndex: 1 }}>
          <Text style={{ cursor: 'pointer' }} isTitle onClick={() => setRedirect(`/maquinas/${_id}`)}>
            {machine.name}
          </Text>
          <Text black opacity={0.5} italic>
            {machine.companyName}
          </Text>
        </div>
        <DropDownButton>
          <Image src={arrowIcon} upside={showDropDown} />
        </DropDownButton>
      </Container>
      <Container vanish>
        <Text black opacity={0.8}>
          <Icon src={groupIcon} />
          {machine.groupName}
        </Text>
        <Text black opacity={0.8}>
          <Icon src={groupIcon} />
          {machine.machineTypeName}
        </Text>
      </Container>
      { userInfo?.isAdmin && <Container vanish>
        <Text spaceBetween black opacity={0.8} style={{ margin: '5px auto' }}>
          Available
          <Switch checked={disponibility} onClick={setDisponibility} />
        </Text>
        <Text spaceBetween black opacity={0.8} style={{ margin: '5px auto' }}>
          Sampling
          <Switch checked={machineSampling} onClick={setMachineSampling} />
        </Text>
        <Text spaceBetween black opacity={0.8} style={{ margin: '5px auto' }}>
          Signage
          <Switch checked={machineSignage} onClick={setMachineSignage} />
        </Text>
      </Container>}
      <Container vanish style={{ textAlign: 'center' }}>
        <Text black opacity={0.5} italic style={{ margin: 'auto' }}>
          Código
        </Text>
        <Text black opacity={0.25} style={{ margin: 'auto' }}>
          {machine.code}
        </Text>
      </Container>
      <ButtonContainer>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          {((update || editableFields?.length > 0) && !isPromoter) && (
          <OptionButton
            src={createBorderlessIcon}
            onClick={() => setEditing(true)}
            active
          />
          )}
          {create && (
          <OptionButton
            src = {copyIcon}
            onClick={() => setDuplicate(true)}
            active
          />
          )}
          {remove && (
          <OptionButton
            src={deleteBorderlessIcon}
            onClick={() => setDeleting(true)}
            active
          />
          )}
        </div>
      </ButtonContainer>
      <Stripe />
      <DropContainer dropdown showDropDown={showDropDown}>
        <DropConditional>
          <DropDownItem title="Operação" value={machine.groupName} />
          <DropDownItem title="Tipo" value={machine.machineTypeName} />
          <DropDownItem title="Código" value={machine.code} />
          {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '.3rem 0',
              }}
            >
              <Text>
                Availables
              </Text>
              <Switch checked={disponibility} onClick={setDisponibility} />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '.3rem 0',
              }}
            >
              <Text>
                Sampling
              </Text>
              <Switch checked={machineSampling} onClick={setMachineSampling} />
            </div>
          </div> */}
        </DropConditional>
        <div style={{ display: 'flex', width: '100%' }}>
          {((update || editableFields?.length > 0) && !isPromoter) && (
          <OptionButton
            src={createBorderlessIcon}
            onClick={() => setEditing(true)}
            dropdown
          />
          )}
          {create && (
          <OptionButton
            src = {copyIcon}
            onClick={() => setDuplicate(true)}
            dropdown
          />
          )}
          {remove && (
          <OptionButton
            src={deleteBorderlessIcon}
            onClick={() => setDeleting(true)}
            dropdown
          />
          )}
          {}
        </div>
      </DropContainer>
      <td>
        <Modal handleSubmit={handleEdit} active={editing} setActive={setEditing}>
          <Modal.Title>Edição de Operação</Modal.Title>
          {canEdit('name') && (
            <Modal.Input value={machineName} setValue={setMachineName} placeholder="Nome da operação" />
          )}
          {canEdit('code') && (
            <Modal.Input value={machineCode} setValue={setMachineCode} placeholder="Código" />
          )}
          <MachineConsumer>
            {([machineInfo]) => (
              <>
                {canEdit('companyId') && (
                  <Modal.Select
                    value={machineCompany}
                    setValue={setMachineCompany}
                    options={machineInfo?.companies}
                    placeholder="Selecione a empresa"
                  />
                )}
                {canEdit('groupId') && (
                  <Modal.SearchSelect
                    isSearchable={false}
                    value={machineGroup}
                    onChange={setMachineGroup}
                    loadOptions={loadOptions(machineCompany.value)}
                    defaultOptions={loadOptions(machineCompany.value)}
                    placeholder="Nome do Grupo"
                  />
                )}
                {canEdit('type') && (
                  <Modal.Select
                    value={machineType}
                    setValue={setMachineType}
                    options={[{ value: 'Totemach', label: 'Totemach' }, { value: 'Vendmach', label: 'Vendmach' }]}
                    placeholder="Selecione o modelo"
                  />
                )}
              </>
            )}
          </MachineConsumer>
          {/* {canEdit('available') && (
            <div style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '.9rem 0',
            }}
            >
              <Text>
                Availables
              </Text>
              <Switch checked={machineAvailable} onClick={setMachineAvailable} />
            </div>
          )}
          {canEdit('sampling') && (
            <div style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '.9rem 0',
            }}
            >
              <Text>
                Sampling
              </Text>
              <Switch checked={machineSampling} onClick={setMachineSampling} />
            </div>
          )} */}
          {canEdit('slots') && (
            <SlotsFormList slots={machineSlots} setSlots={setMachineSlots} />
          )}
          <Modal.Button>Finalizar</Modal.Button>
        </Modal>
      </td>
      <td>
      <Modal handleSubmit={handleDuplicate} active={duplicating} setActive={setDuplicating}>
          <Modal.Title>Edição de Clone</Modal.Title>
          {canEdit('name') && (
            <Modal.Input value={machineNameClone} setValue={setMachineNameClone} placeholder="Nome da Operação" />
          )}
          {canEdit('code') && (
            <Modal.Input value={machineCodeClone} setValue={setMachineCodeClone} placeholder="Código" />
          )}
          <MachineConsumer>
            {([machineInfo]) => (
              <>
                {canEdit('companyId') && (
                  <Modal.Select
                    value={machineCompanyClone}
                    setValue={setMachineCompanyClone}
                    options={machineInfo?.companies}
                    placeholder="Selecione a empresa"
                  />
                )}
                {canEdit('groupId') && (
                  <Modal.SearchSelect
                    isSearchable={false}
                    value={machineGroupClone}
                    onChange={setMachineGroupClone}
                    loadOptions={loadOptions(machineCompanyClone.value)}
                    defaultOptions={loadOptions(machineCompanyClone.value)}
                    placeholder="Nome do Grupo"
                  />
                )}
                {canEdit('type') && (
                  <Modal.Select
                    value={machineTypeClone}
                    setValue={setMachineTypeClone}
                    options={[{ value: 'Totemach', label: 'Totemach' }, { value: 'Vendmach', label: 'Vendmach' }]}
                    placeholder="Selecione o modelo"
                  />
                )}
              </>
            )}
          </MachineConsumer>
          {canEdit('slots') && (
            <SlotsFormList slots={machineSlotsClone} setSlots={setMachineSlotsClone} />
          )}
          <Modal.Button>Finalizar</Modal.Button>
        </Modal>
      </td>
      <td>
      <WarnModal
          active={duplicate}
          setActive={setDuplicate}
          title="Duplicar Operação"
          text={(
            <>
              Tem certeza que deseja duplicar a Operação
              {' '}
              <span>{machine.name}</span>
              {' '}
              ?
            </>
          )}
          accept={async () => {
              try {
                responseClone = await axiosPost({ url: `/machines/clone/${_id}` });
                setIdClone(responseClone.data.machine._id);
                setMachineNameClone(responseClone.data.machine.name);
                setMachineCodeClone(responseClone.data.machine.code);
                setMachineCompanyClone(machineCompany);
                setMachineGroupClone(machineGroup);
                setMachineTypeClone(machineType);
                setMachineSlotsClone(machineSlots);
                setDisponibilityClone(disponibility);
                setMachineSamplingClone(machineSampling);
                setDuplicating(true);
              } catch (err) {
                console.log(err);
              }
            }
          }
      />
      </td>
      <td>
        <WarnModal
          active={deleting}
          setActive={setDeleting}
          title="Excluir Operação?"
          text={(
            <>
              Tem certeza que deseja excluir a Operação
              {' '}
              <span>{machine.name}</span>
              {' '}
              das
              {' '}
              <span>suas operações</span>
              ?
              {' '}
              Essa ação não pode ser desfeita e afetará todo o sistema.
            </>
          )}
          accept={async () => {
            try {
              await axiosDelete({ url: `/machines/${_id}` });
              setDeleted(true);
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </td>
    </Card>
  );
}

export default MachineCard;
