import styled, { createGlobalStyle } from 'styled-components';

import backgroundPNG from '../assets/background.png';

export const Background = styled.div`
  position: relative;

  &::after {
    content: "";
    background-image: url(${backgroundPNG});
    opacity: 0.03;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    background-size: auto;
  }
`;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    -webkit-font-smoothing: antialiased;
    color: #016ec2;
    background: #f5f5f5;
  }
  body, input, button {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }
  #root {
    margin: 0 0;
    min-height: 100vh;
  }
  button {
    cursor: pointer;
  }

  .ant-dropdown {
    width: min-content;
  }

  .ant-switch-checked {
    background-color: #3fa9f5 !important;
  }

  .ant-collapse-content-box {
    .ant-btn-primary {
      background-color: #3fa9f5 !important;
      border-color: #3fa9f5 !important;
    }
  }
`;
