import React, { useState, useEffect } from 'react';

import {
  FilterContainer,
  FilterItem,
  FilterSelect,
  colourStyles
} from './styles';
import DateFilterModal from './DateFilterModal';

const subtractDayInterval = (date, interval) => {
  const subtractedDate = new Date(date);

  subtractedDate.setDate(subtractedDate.getDate() - interval);
  subtractedDate.setHours(0, 0, 0, 0);

  return subtractedDate;
};

function FilterHeader({ width, setFilter = () => {} , groupList = [], onChangeGroup}) {
  const defaultDays = [7, 15, 30];

  const [selectedPeriod, setSelectedPeriod] = useState({
    start: subtractDayInterval(new Date(), defaultDays[0]),
    end: new Date(),
    interval: defaultDays[0],
  });

  const [selectedOption, setSelectedOption] = useState(defaultDays[0]);

  const [modalActive, setModalActive] = useState(false);

  const handleSelectSubmit = (days) => {
    if (days.value === 0) {
      setModalActive(true);
    }
    if (selectedPeriod.interval !== days.value) {
      setSelectedPeriod({
        start:subtractDayInterval(new Date(), days.value),
        end: new Date(),
        interval: days.value,
      });
      setSelectedOption(days.value);
    }
  }

  const handleModalSubmit = (startDate, endDate) => {
    setSelectedPeriod({
      start: startDate.toDate(),
      end: endDate.toDate(),
      interval: null,
    });
  };

  useEffect(() => {
    setFilter({
      start: selectedPeriod.start,
      end: selectedPeriod.end,
    });
  }, [selectedPeriod]);

  return (
    <FilterContainer width={width}>
      <FilterSelect
        defaultValue={{value: selectedOption, label: `${selectedOption} dias`}}
        isSearchable={false}
        onChange={handleSelectSubmit}
        placeholder="Escolha o intervalo"
        options={[...defaultDays.map((period) => ({value: period, label: `${period} dias`})), {value: 0, label: `Personalizado`}]}
        styles={colourStyles}
      />
      <FilterSelect
        styles = {colourStyles}
        defaultValue={{value: "", label:"Todas as Operações"}}
        isSearchable={false}
        onChange={(option) => (onChangeGroup(option.value))}
        placeholder="Escolha a Operação"
        options={[{value: "", label:"Todas as Operações"}, ...groupList.map(group => ({value: group.groupName, label: group.groupName}))]}
      />
      <DateFilterModal
        onSubmit={handleModalSubmit}
        active={modalActive}
        setActive={setModalActive}
      />
    </FilterContainer>     
  );
}

export default FilterHeader;
