import styled, { css } from 'styled-components';

import Colors from '../../styles/Colors';

const Card = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  outline: 1px solid transparent;
  background-color: white;
  border-bottom: 1px solid #ccc;
  height: 4.625rem;
  max-height: 4.625rem;
  margin-bottom: 1px;

  @media only screen and (min-width: 600px) {
    &:hover {
      outline: 1px solid ${Colors.blue};
    };
  }
`;

const Container = styled.td`
  transition: padding .5s ease-in-out;
  padding: .6rem 1.2rem ${({ open }) => (open ? '6rem' : '.6rem')};
  height: inherit;
  width: 100%;
  position: relative;
  background-color: white;
`;

const DropContainer = styled(Container)`
  @media only screen and (max-width: 600px) {
    display: ${({ dropdown }) => (dropdown ? 'unset' : 'none')};
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  max-width: 100%;
  opacity: ${({ opacity }) => opacity || 1};

  ${({ textWrap }) => (textWrap ? css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  ` : css`
  `)}

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const TableText = styled.div`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  max-width: 100%;
  opacity: ${({ opacity }) => opacity || 1};
  display: flex;
  align-items: center;

  p {
    width: 100%;
    max-width: 100%;
    margin: 0;
    ${({ textWrap }) => (textWrap ? css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    ` : css`
    `)}
  }

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const Image = styled.img`
  /* background-color: transparent; */
  transition: transform .5s ease-out;
  width: ${({ freeWidth, $width }) => (!freeWidth ? '1.125rem' : ($width || '1.75rem'))};
  /* transform: ${({ upside }) => upside && 'rotate(180deg)'}; */
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
  height: 100%;
`;

const ButtonContainer = styled(DropContainer)`
  text-align: right;
  width: 50%;
`;

const Button = styled.button`
  outline: none;
  border: 1px solid ${Colors.secondaryBlue};
  background-color: transparent;
  margin: .2rem .3rem;
  display: ${({ active, dropdown }) => {
    if (dropdown) return 'unset';
    return active ? 'unset' : 'none';
  }};
  border: ${({ dropdown }) => dropdown && `1px solid ${Colors.secondaryBlue}`};
  /* padding: ${({ dropdown }) => dropdown && '.3rem 0'}; */
  border-radius: .2rem;
  width: 2rem;
  height: 2rem;
`;

const DropDownButton = styled.button`
  border: none;
  background-color: transparent;
  display: none;
  float: right;
  outline: none;

  @media only screen and (max-width: 600px) {
    display: unset;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: max-height .5s ease-out;
  max-height: ${({ show }) => (show ? '12rem' : '0rem')};
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
`;

export {
  Card,
  Container,
  DropContainer,
  Text,
  TableText,
  Image,
  ButtonContainer,
  Button,
  DropDownButton,
  DropDownContainer,
};
