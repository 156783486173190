import styled from 'styled-components';

import Colors from '../../styles/Colors';
import logo from '../../assets/logo.png';

export const Content = styled.div`
`;

export const Message = styled.div`
  color: ${Colors.blue};
  width: 300px;
  background-color: #E8F0FE;
  padding: 10px;
  border-radius: 15px;
`;

export const LogoContainer = styled.div`
  display: flex;

  width: 100%;

  justify-content: center;

  margin-bottom: 25px;
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'Logo',
})`
  width: 85px;
  height: auto;

  /* vertical-align: middle */
`;
