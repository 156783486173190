import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ModalBase = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 103;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${Colors.mainBlack};
`;

const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 30rem;
  max-height: 40rem;
  margin: auto;
  padding: 0 2.6rem;
  background-color: ${Colors.mainWhite};
  overflow: auto;
  text-align: center;
  border-radius: .3rem;

  @media only screen and (max-width: 600px){
  width: 85%;
  max-width: 22rem;
  } 
`;

const Title = styled.h2`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${Colors.secondaryBlue};
  margin: 1.2rem 0;
`;

const List = styled.section`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem ;
  overflow: auto;
`;

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 10rem;
  max-width: 10rem;
`;

const Image = styled.img`
  width: 100%;
  height: 80%;
  object-fit: contain;
`;

const CardDesc = styled.h3`
  color: ${Colors.secondaryBlack};
  font-weight: bolder;
  font-size: .7rem;
  margin: ${({ billboard }) => (billboard ? '2rem 0 .3rem' : '.3rem 0')};
`;

const CheckboxContainer = styled.div`
  position: absolute;
  top: .3rem;
  left: .3rem;
  > label {
    height: min-content;
    > span {
      > span {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        ::after {
          left: 30%;
          top: 46%;
          width: .4rem;
          height: .7rem;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`;

const Button = styled.button`
  border: none;
  outline: none;
  font-style: italic;
  color: ${({ blue }) => (blue ? 'white' : Colors.secondaryBlue)};
  background-color: ${({ blue }) => (blue ? Colors.blue : 'white')};
  padding: .3rem;
  margin: 0 .3rem;
  border-radius: .3rem;
`;

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  border-radius: .3rem;
  border: 1px solid ${Colors.secondaryBlue};
`;

export {
  ModalBase,
  ModalContent,
  Title,
  List,
  Card,
  Image,
  CardDesc,
  CheckboxContainer,
  ButtonContainer,
  Button,
  MediaContainer,
};
