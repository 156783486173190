import React, { useEffect, useState, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import HeatmapChart from '../../components/HeatmapChart/index';
// import { heatMapData } from '../../utils/mockedValues';

import {
  Container,
  InfoCard,
  InfoCardContainer,
  InfoCardLabel,
  InfoCardValue,
  InfoContainer,
  TopSeller,
  Last3Content,
  TopSellerMachines,
} from './styles';

import useDebounce from '../../hooks/useDebounce';
import Colors from '../../styles/Colors';
import { DoughnutChart, Title } from '../../components/index';
import FilterHeader from '../Home/FilterHeader';
import { useAxios } from '../../services/api';
import { useUserInfo } from '../../state/UserContext';

dayjs.locale('pt-br');

function DateGraph({ revenueSorted, response }) {
  return (
    <div className='line-graph-container'>
      <Line
        data={{
          labels: revenueSorted?.map(({ day }) => dayjs(day).format('DD-MMM-YY').toLowerCase()),
          datasets: [
            {
              label: 'Receita',
              type: 'line',
              data: response?.revenueByDay?.map(({ revenue }) => revenue),
              backgroundColor: `${Colors.blue}82`,
              borderColor: Colors.blue,
              fill: 'origin',
              yAxisID: 'y',
              pointRadius: 0,
              pointHoverRadius: 4,
              lineTension: 0.1,
              radius: 3,
              borderWidth: 1,
            },
            {
              label: 'Transações',
              type: 'line',
              data: response?.trasactionsByDay?.map(({ count }) => count),
              backgroundColor: `${Colors.green}82`,
              borderColor: Colors.green,
              fill: 'origin',
              yAxisID: 'y1',
              pointRadius: 0,
              pointHoverRadius: 4,
              lineTension: 0.1,
              radius: 3,
              borderWidth: 1,
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              grace: '10%',
              count: 7,
              ticks: {
                beginAtZero: true,
                callback: (value) => `R$ ${value.toFixed(2).toString().replace('.', ',')}`,
                count: 7,
              },
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              grace: '10%',
              id: 'y-axis-2',
              count: 7,
              ticks: {
                beginAtZero: true,
                count: 7,
                callback: (value) => `${value.toFixed(0).toString()}`,
              },
              grid: {
                display: false,
              },
            },
            x: {
              ticks: {
                maxTicksLimit: 7,
              },
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
        }}
      />
    </div>
  );
}

const Reports = () => {
  const [userInfo] = useUserInfo();

  const [response, setResponse] = useState({});
  const [responseHeatmap, setResponseHeatmap] = useState({});
  const [filter, setFilter] = useState({});

  let products = [];
  let companies = [];
  let groups = [];

  const [filteredGroup, setFilteredGroup] = useState("");
  const [allGroups, setAllGroups] = useState([])

  const debouncedFilter = useDebounce(filter, 500);

  const [axiosGet] = useAxios('get');

  useEffect(() => {
    if (debouncedFilter.start && debouncedFilter.end) {
      let queryURL = `/report?startDate=${debouncedFilter.start.toISOString()}&endDate=${debouncedFilter.end.toISOString()}`
      if (filteredGroup !== "") {
        queryURL += `&groupName=${filteredGroup}`
      }
      axiosGet({
        url: queryURL,
        setState: setResponse,
        process: async (data) => {
          console.log(data);
          if (products.length === 0 || groups.length === 0 || companies.length === 0) {
            const groupNames = await axiosGet({url: `/groups`});
            const productNames = await axiosGet({url: "/products"});
            const companiesNames = await axiosGet({url: `/companies`});
            companies = companiesNames.data.data;
            products = productNames.data.data;
            groups = groupNames.data.data;
          }

          return {
            ...data,
            allSellingProducts: data.allSellingProducts.map((product) => ({
              ...product,
              productName: products.filter((prod) => prod._id === product.productId)[0]?.name,
            })),
            allSellingGroups: data.allSellingGroups.map((group) => ({
              ...group,
              groupName: groups.filter((gr) => gr._id === group.groupId)[0]?.name,
              companyName: companies.filter((comp) => comp._id === group.companyId)[0]?.name,
            })),
          };
        },
      });
    }
  }, [debouncedFilter, filteredGroup]);

  useEffect(() => {
    if (debouncedFilter.start && debouncedFilter.end) {
      let queryURL = `/report/heatmap?startDate=${debouncedFilter.start.toISOString()}&endDate=${debouncedFilter.end.toISOString()}`
      if (filteredGroup !== "") {
        queryURL += `&groupName=${filteredGroup}`
      }
      axiosGet({
        url: queryURL,
        setState: setResponseHeatmap,
        process: async (data) => {
          console.log("Heatmap: ", data);
          return {
            ...data
          };
        },
      });
    }
  }, [debouncedFilter, filteredGroup]);

  useEffect(() => {
    if (filteredGroup === "") {
      setAllGroups(sortBy(response?.allSellingGroups || [], 'profit'))
    }
  }, [response]);

function transformHeatMapData(data) {
  const ans = [];
  if ( data !== undefined){
    for (let i = 0; i < 8; i++) {
      ans.push({
        name: data.map(elem => elem.periods[i].period)[0],
        data: data.map(elem => elem.periods[i].transactionCount)
      })
    }
  }
  return ans;
}

  const heatMapData = transformHeatMapData(responseHeatmap?.heatmap);

  const sortBy = (array, key) => [...array].sort((a, b) => {
      if (a[key] > b[key]) return -1;
      if (a[key] < b[key]) return 1;
      return 0;
    });

  const memoizedRevenue = useMemo(() => {
    const revenueSorted = response?.revenueByDay?.sort(({ day: day1 }, { day: day2 }) => {
      if (new Date(day1) < new Date(day2)) return -1;
      if (new Date(day1) > new Date(day2)) return 1;
      return 0;
    });

    return revenueSorted;
  }, [response]);

  const sortedProducts = sortBy(response?.allSellingProducts || [], 'profit');
  const sortedGroups = sortBy(response?.allSellingGroups || [], 'profit');
  const sortedQuantityProducts = sortBy(response?.allSellingProducts || [], 'quantity');

  const MAX_SHOWN_PRODUCTS = 50;

  const currencyDisplay = (value) => {
    let formatted = Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
    return formatted;
  }

  function currencyRound(value) {
    const ans = parseFloat(value >= 10000 ? (
      `${Math.floor(value / 1000)}`
    ) : `${value}`)
    
    return (value >=10000 ? ans + `K` : currencyDisplay(ans));
  }



  return (
    <Container>
      <Title title="Relatórios" />
      <FilterHeader width="90%" setFilter={setFilter} groupList={allGroups} onChangeGroup={setFilteredGroup}/>

      <InfoCardContainer>
        <InfoCard isActive>
          <InfoCardLabel isActive>Transações</InfoCardLabel>
          <InfoCardValue>{response?.transactionCount}</InfoCardValue>
        </InfoCard>

        <InfoCard isActive>
          <InfoCardLabel isActive>Receita (R$)</InfoCardLabel>
          <InfoCardValue>
            {' '}
            {currencyRound(response?.revenue ? response.revenue : 0)}
          </InfoCardValue>
        </InfoCard>

        <InfoCard isActive>
          <InfoCardLabel isActive>Ticket médio (R$)</InfoCardLabel>
          <InfoCardValue>
            {' '}
            {currencyDisplay(response?.averageTicket ? response.averageTicket : 0)}
          </InfoCardValue>
        </InfoCard>
      </InfoCardContainer>
      <Last3Content>
        <InfoContainer padding>
          <h3>Faturamento</h3>
          <DateGraph revenueSorted={memoizedRevenue} response={response} />
        </InfoContainer>

        <InfoContainer>
          <h3>Forma de pagamento</h3>
          <DoughnutChart
            labels={response?.paymentMethods?.map(({ paymentType }) => paymentType || 'Desconhecido')}
            label="Forma de pagamento"
            data={response?.paymentMethods?.map(({ count }) => count)}
            colors={[
              Colors.blue,
              '#33c4e3',
              '#006494',
              Colors.green,
              '#008C76',
            ]}
          />
        </InfoContainer>

        <InfoContainer flexStart overflow>
          <h3>Produtos mais vendidos</h3>
          {sortedQuantityProducts?.slice(0, MAX_SHOWN_PRODUCTS).map((product, index) => {
            const { productName, quantity } = product;

            return (
              <TopSeller
                marginBottom
                key={productName}
                isTheLast={index + 1 === sortedQuantityProducts?.slice(0, MAX_SHOWN_PRODUCTS).length}
              >
                <div>
                  <span>
                    {index + 1}
                    º
                  </span>
                  <p>{productName}</p>
                </div>

                <div>{quantity}</div>
              </TopSeller>
            );
          })}
        </InfoContainer>

        <InfoContainer flexStart overflow>
          <h3>Receita por Produto</h3>
          {sortedProducts?.slice(0, MAX_SHOWN_PRODUCTS).map((product, index) => {
            const { productName, profit } = product;
            //TODO: Ajustar para ate 7 digitos
            const textProfit = currencyRound(profit);

            return (
              <TopSeller
                marginBottom
                key={productName}
                isTheLast={index + 1 === sortedProducts?.slice(0, MAX_SHOWN_PRODUCTS).length}
              >
                <div>
                  <span>
                    {index + 1}
                    º
                  </span>
                  <p>{productName}</p>
                </div>

                <div>R$ {textProfit}</div>
              </TopSeller>
            );
          })}
        </InfoContainer>

        <InfoContainer>
          <h3>Mapa de Calor</h3>
          <div className='line-graph-container'>
            <HeatmapChart data={heatMapData}/>
          </div> 
        </InfoContainer>


        <InfoContainer flexStart overflow>
          <h3>Receita por PDV</h3>
          {sortedGroups?.map((groupInfo, index) => {
            const {
              groupName, companyName, profit,
            } = groupInfo;

            const sum = response?.allSellingGroups
              .map(({ profit: eachProfit }) => eachProfit)
              .reduce((a, b) => a + b, 0);

            return (
              <TopSellerMachines
                marginBottom
                key={groupName}
                isTheLast={index + 1 === response?.allSellingGroups.length}
              >
                <div>
                  <span>
                    {index + 1}
                    ª
                  </span>
                  <div>
                    <h4>{groupName}</h4>
                    {userInfo?.isAdmin && (
                      <p>
                        {companyName}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <span className='faturamento-total'>(R$ {currencyRound(profit)})</span>
                  <p>
                    {Math.round((profit / sum)*1000)/10}
                    %
                  </p>
                </div>
              </TopSellerMachines>
            );
          })}
        </InfoContainer>
      </Last3Content>
    </Container>
  );
};

export default Reports;