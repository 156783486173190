/* eslint-disable import/no-cycle */
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { withRouter } from 'react-router';

import { useAxios } from '../services/api';
import { LazyLoader } from '../components';
import NotFoundPage from '../pages/404';

const MachineDetailContext = createContext(false);
const { Consumer: MachineDetailConsumer } = MachineDetailContext;

function MachineDetail({ children, location }) {
  const [machineInfo, setMachineInfo] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [axiosGet] = useAxios('get');

  const reloadInfo = useCallback(() => {
    axiosGet({
      url: `/machines/${location.pathname.split('/')[2]}`,
      setState: setMachineInfo,
      process: (data) => data,
      success: setSuccess,
      error: setError,
    });
  }, [location.pathname, axiosGet]);

  useEffect(() => {
    reloadInfo();
  }, [reloadInfo]);

  if (!error && !success) return <LazyLoader page />;

  if (error) return <NotFoundPage />;

  return (
    <MachineDetailContext.Provider value={[machineInfo, reloadInfo]}>
      {children}
    </MachineDetailContext.Provider>
  );
}

const MachineDetailProvider = withRouter(MachineDetail);

export const useMachineDetailContext = () => useContext(MachineDetailContext);

export default MachineDetailContext;

export {
  MachineDetailProvider,
  MachineDetailConsumer,
};
