import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const NewButton = styled.button`
  background-color: ${Colors.blue};
  padding: .3rem 2.6rem;
  outline: none;
  border: none;
  border-radius: .2rem;
  font-style: italic;
  color: #fff;
  white-space: nowrap;
  opacity: 1;
  cursor: pointer;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
