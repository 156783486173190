import React, {
  useState,
  useEffect,
} from 'react';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import {
  Container,
  ButtonLink,
  OpenNav,
  ButtonLink2,
  Header,
} from './styles';
import { useUserInfo } from '../../state/UserContext';

import Account from './assets/account.svg';
import Customer from './assets/users.svg';
import Business from './assets/bolsa.svg';
import Group from './assets/groups.svg';
import Machine from './assets/machines.svg';
import DNS from './assets/dns.svg';
import Products from './assets/reader.svg';
import Reports from './assets/reports.svg';
import Sales from './assets/sales.svg';
import Exit from './assets/exit.svg';

import Logo from './assets/logo.svg';
import logoM from './assets/Logo_M.svg';

function NavBar({ setLogged }) {
  const [visible, setVisible] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [, , removeCookies] = useCookies(['token']);
  const [userInfo] = useUserInfo();

  useEffect(() => {
    if (userInfo) {
      const conditionalNavLink = () => (
        userInfo.isAdmin ? (
          { image: Business, name: 'empresas' }
        ) : (
          {
            image: Group, name: 'operacoes', label: 'Grupos', link: `empresas/${userInfo.companyId}`,
          }
        )
      );

      const conditionalPath = () => (
        userInfo.isAdmin ? 'produtos' : 'itens'
      );

      setRoutes([
        ...(userInfo.isAdmin ? [
          { image: Account, label: 'Usuários', name: 'usuarios' }] : []),
        ...(userInfo.permissions?.group.create ? [ conditionalNavLink() ] : []),
        ...(userInfo.isAdmin ? [
          { image: Customer, label: 'Consumidores', name: 'consumidores' }] : []),
        { image: Machine, label: 'Operações', name: 'maquinas' },
        ...(userInfo.permissions?.ad.list ? [
          { image: DNS, label: 'Anúncios', name: 'anuncios' }
        ] : []),
        ...(userInfo.isAdmin || (userInfo.myCompany?.canCreateProduct && userInfo.permissions?.product.list ) ? [
          { image: Products, name: conditionalPath() },
          { image: Sales, label:'Transações', name: 'vendas' },
          { image: Reports, label: 'Relatórios', name: 'relatorios' },
        ] : []),
      ]);
    }
  }, [userInfo]);

  function close() {
    setVisible(false);
  }

  function open(e) {
    if (e) {
      e.preventDefault();
    }

    setVisible(true);
  }

  const logoutUser = () => {
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    setLogged(false);
  };

  return (
    <>
      <OpenNav type="button" onClick={visible ? close : open}>
        <img id="logoM" src={logoM} alt="logo m" />
      </OpenNav>
      <Header />
      <Container>
        <Drawer
          title={(
            <Link to="/" onClick={close} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonLink2
                onClick={close}
              />
              <img style={{ marginLeft: 15, marginTop: '0.2rem' }} alt="admin" src={Logo} />
            </Link>
          )}
          placement="left"
          className="example"
          closable={false}
          onClose={close}
          visible={visible}
          zIndex={101}
          key="placement"
          width={300}
          drawerStyle={{ borderColor: 'red', borderWidth: 25 }}
          headerStyle={{ borderBottomStyle: 'none', marginTop: 10 }}
          bodyStyle={{
            display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column',
          }}
          footer={(
            <ButtonLink onClick={logoutUser} style={{ marginLeft: 15 }}>
              <img src={Exit} alt="Sair" />
              Sair
            </ButtonLink>
          )}
          footerStyle={{
            borderTopStyle: 'none',
            marginBottom: 10,
          }}
        >
          {routes.map((route) => (
            <Link to={`/${route.link || route.name}`} key={route.name} onClick={close}>
              <ButtonLink>
                <img className={route.name} src={route.image} alt={route.name} />
                {route.label || capitalizeFirstLetter(route.name)}
              </ButtonLink>
            </Link>
          ))}
        </Drawer>
      </Container>
    </>
  );
}

export default NavBar;
