import React, { useState, useEffect } from 'react';
import {
 Input, Row, Col, notification,
} from 'antd';
import { useMachineDetailContext } from '../../state/MachineDetailContext';
import { useAxios } from '../../services/api';
import {
 CardsContainer, 
 Container, 
 NewButton, 
 LockContainer,
} from './styles';
import Modal from '../Modal';
import LockCard from '../LockCard';



function Locks() {
  const [lockList, setLockList] = useState([]);
  const [lockNumber, setLockNumber] = useState('');
  const [lockName, setLockName] = useState('');
  const [machine] = useMachineDetailContext();
  const [shouldCreateModalShow, setShouldCreateModalShow] = useState(false);
  const [axiosPatch, axiosGet, axiosDelete, axiosPost] = useAxios('patch', 'get', 'delete', 'post');

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (msg) => {
    api.open({
      message: 'Confirmação',
      description: msg,
      duration: 5,
    });
  };

  const createLock = async () => {
    try {
      await axiosPost({
        url: '/machine-locks/',
        body: {
          number: lockNumber,
          description: lockName,
          active: true,
          machineId: machine._id,
        }
      })
      loadLockList();
    } catch (error) {
      console.error(error);
    }
  }

  async function loadLockList() {
    axiosGet({
      url: `/machine-locks/?machineId=${machine._id}`,
      setState: setLockList,
    })
  }

  useEffect(() => {
    const getLocks = async () => {
      try {
        loadLockList()
      } catch (error) {
        console.error(error);
      }
    }
    getLocks();
  }, [])

  useEffect(() => {
    setLockNumber(machine.lock_number ?? '');
  }, []);

  return (
    <Container>
      <CardsContainer>
        {contextHolder}
          {/* <Col span={12}>
            <Input
              name="lockNumber"
              value={lockNumber}
              onChange={(e) => setLockNumber(e.target.value)}
              placeholder="Número do equipamento"
            />
          </Col> */}
            <NewButton onClick={() => setShouldCreateModalShow(true)}>
              Adicionar Trava
            </NewButton>
        <Modal
        handleSubmit={createLock}
        active={shouldCreateModalShow}
        setActive={setShouldCreateModalShow}
        >
          <Modal.Title>Criação de Trava</Modal.Title>
          <Modal.Label>Nome da Trava:</Modal.Label>
          <Modal.Input
            value={lockName}
            setValue={setLockName}
          />
          <Modal.Label>Código da Trava:</Modal.Label>
          <Modal.Input
            value={lockNumber}
            setValue={setLockNumber}
          />
          <Modal.Button>Finalizar</Modal.Button>
        </Modal>
        
        <LockContainer>
          {lockList.map((lock) => (
            <>
              <LockCard key={lock._id} lock={lock} openNotification={openNotification} loadLockList={loadLockList}/>
            </>
          ))}
        </LockContainer>
      </CardsContainer>
    </Container>
  );
}

export default Locks;