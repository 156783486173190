import React, { useState } from 'react';

import { useAxios } from '../../services/api';
import { Switch, Tooltip } from 'antd';
import { useUserInfo } from '../../state/UserContext';
import moment from 'moment';

import {
  HeaderTitleContainer,
  HeaderTitle,
  HeaderSubtitle,
  HeaderImageContainer,
  Card,
  Type,
  TopCard,
  TopCardIcon,
  DatePickerContainer,
  DatePicker,
  Label,
} from './styles';
import Modal from '../Modal';

import fullStockIcon from './assets/fullStock2.svg'
import securityStockIcon from './assets/securityStock2.svg'
import minimalStockIcon from './assets/minimalStock2.svg'
import fullExpirationIcon from './assets/fullExpiration.svg'
import alertExpirationIcon from './assets/alertExpiration.svg'
import expiredIcon from './assets/Expired.svg'

import { useEffect } from 'react';
import Colors from '../../styles/Colors';
import { useFirstRender } from '../../hooks/useFirstRender';

const SlotsCard = ({
  slot,
  items,
  machine,
  slots,
  setSlots,
  reloadMachineDetails,
}) => {
  const dateFormat = 'YYYY/MM/DD';
  const dateFormatView = 'DD/MM/YYYY';
  const [selectedItem, setSelectedItem] = useState({value: slot?.itemId?._id, label: slot?.itemId?.productId?.name});
  const [amount, setAmount] = useState(slot.quantity);
  const [minimumStock, setMinimumStock] = useState(slot.minimumStock || Math.ceil(slot.size * 0.2));
  const [safetyStock, setSafetyStock] = useState( slot.safetyStock || Math.ceil(slot.size * 0.5) ); 
  const [shouldModalShow, setShouldModalShow] = useState(false);
  const [userInfo] = useUserInfo();
  const [axiosPatch] = useAxios('patch');
  const [slotAvailable, setSlotAvailable] = useState(slot.slotAvailable);
  const [expirationDate, setExpirationDate] = useState( slot.expirationDate);
  const [initialStartString, setInitialStartString] = useState({
    value: expirationDate ? moment(expirationDate, dateFormat) : ""
  });
  const [expirationIcons, setExpirationIcons] = useState({
    value: '',
    tooltip: ''
  })
  const [stockIcon, setStockIcon] = useState({
    value: '',
    tooltip: ''
  })

  const firstRender = useFirstRender();

  useEffect(() => {
    if (!expirationDate) {
      return
    }
    const expirationMoment = moment(expirationDate, dateFormat);
    const nowMoment = moment({hour: 0});
    if (nowMoment.isAfter(expirationMoment)) { 
      setExpirationIcons({value: expiredIcon, tooltip: 'Produto fora da validade' });
    } else if (expirationMoment.diff(nowMoment, 'days') <= 10) {
      setExpirationIcons({value: alertExpirationIcon, tooltip: 'Produto próximo da validade' });
    } else {
      setExpirationIcons({value: fullExpirationIcon, tooltip: 'Produto dentro da validade' });
    }
  }, [slot])

  useEffect(() =>{
    if (!minimumStock || !safetyStock){
      return
    }
    if( amount <= minimumStock){
      setStockIcon({value: minimalStockIcon, tooltip: 'Estoque baixo' });
    } else if( amount < safetyStock){
      setStockIcon({value: securityStockIcon, tooltip: 'Estoque de segurança'});
    } else {
      setStockIcon({value: fullStockIcon, tooltip: 'Estoque cheio'});
    } 
  }, [slot])

  useEffect(() => {
    if (!firstRender) {
      handleSubmit();
    } 
  }, [slotAvailable])
  
  const handleSubmit = async () => {
    const newSlots = slots.map((eachSlot) => {
      if (eachSlot._id === slot._id) {
        return {
          ...eachSlot,
          quantity: amount,
          itemId: selectedItem.value,
          minimumStock: minimumStock,
          safetyStock: safetyStock,
          slotAvailable: slotAvailable,
          expirationDate: expirationDate,
        };
      }
      return eachSlot;
    });

    const body = {
      slots: newSlots,
    };

    await axiosPatch({
      url: `/machines/${machine._id}`,
      body,
      success: (res, data) => {
        setSlots(data.slots);
        reloadMachineDetails();
      },
    });

  };
 
  return (
    <>
      <Card>
          <TopCard>
            <Type>{slot.name}</Type>
            <TopCardIcon>
              { userInfo?.myCompany.hasStockControll &&
                <>
                <Tooltip title={expirationIcons.tooltip}>
                  <img src={expirationIcons.value}/>
                </Tooltip>
                <Tooltip title={stockIcon.tooltip} >
                  <img src={stockIcon.value}/>
                </Tooltip>
                </>
              }
              <Switch
                    size="small"
                    checked={slotAvailable}
                    onChange={() => setSlotAvailable(!slotAvailable)}
                  />
              </TopCardIcon>
            </TopCard>
            <HeaderTitleContainer onClick={() => setShouldModalShow(true)}>
            <HeaderTitle>{slot?.itemId?.productId?.name}</HeaderTitle>
            <HeaderSubtitle>
              Quantidade
              {' '}
              {slot.quantity}
            </HeaderSubtitle>
            <HeaderImageContainer>
              <img src={slot?.itemId?.productId?.image} />
            </HeaderImageContainer>
          </HeaderTitleContainer>
      </Card>
      <Modal
      handleSubmit={handleSubmit}
      active={shouldModalShow}
      setActive={setShouldModalShow}
    >
      <Modal.Title>Edição de Slot</Modal.Title>
      <Modal.Select
        isSearchable
        defaultValue={selectedItem}
        value={selectedItem}
        setValue={setSelectedItem}
        options={[{label: "Sem produto/Indisponível", value: ""}, ...items.map(item => ({label: item.name, value: item._id}))]}
        placeholder="Selecione o Produto"
      />
      <Modal.Label>Quantidade</Modal.Label>
      <Modal.Input
         value={amount}
         setValue={setAmount}
         min={0}
         max={slot.size}
         type={"number"} />
      <Modal.Label>Estoque de Segurança</Modal.Label>
      <Modal.Input
         value={safetyStock}
         setValue={setSafetyStock}
         min={0}
         max={slot.size}
         type={"number"} />
       <Modal.Label>Estoque Mínimo</Modal.Label>
       <Modal.Input
          value={minimumStock}
          setValue={setMinimumStock}
          min={0}
          max={slot.size}
          type={"number"} />
      <DatePickerContainer>
        <Label>Data de Validade: </Label>
        <DatePicker
          format={dateFormatView}
          {...initialStartString}
          placeholder="dd/mm/aaaa"
          onChange={(date) => {
            const selectedDate = moment(date);
            const formattedDate = selectedDate.format(dateFormat);

            setExpirationDate(formattedDate);

            if (!date) {
              return setInitialStartString({value: ""});
            }

            return setInitialStartString({
              value: moment(date),
            });
          }}
        />
      </DatePickerContainer>
      <Modal.Button>Finalizar</Modal.Button>
    </Modal>
  </>
  );
};

export default SlotsCard;
