import React, {
  useState,
  useEffect,
  memo,
  useCallback,
} from 'react';
import Select from 'react-select';

import {
  ModalBase,
  ModalContent,
  Button,
  Image,
  Title,
  Input,
  SubmitButton,
  colourStyles,
  SelectDropDown,
} from './styles';
import { useAxios } from '../../services/api';
import { useErrorContext } from '../../state/ErrorContext';
import { useUserInfo } from '../../state/UserContext';
import Modal from '../Modal';

import closeIcon from './Assets/close.svg';

function UserModal({
  active,
  setActive,
  options: {
    title,
    buttonValue,
  },
  user,
  type,
  roles,
  companies,
  machines,
  accept = () => {},
  reloadList,
}) {
  const [userType, setUserType] = useState(null);
  const [company, setCompany] = useState(null);
  const [machine, setMachine] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const [axiosGet] = useAxios('get');

  const [, setError] = useErrorContext();
  const [userInfo] = useUserInfo();

  const [axiosPost, axiosPatch] = useAxios('post', 'patch');

  useEffect(() => {
    if (roles) {
      if (type === 'update') {
        const {
          roleId,
          companyId,
          name,
          email,
          phone,
          machineId,
        } = user;
        if (roleId && !userType) {
          const roleOption = roles.find(({ value }) => value === roleId);
          setUserType(roleOption);
        }
        if (companyId && !company) {
          const companyOption = companies
            ? companies.find(({ value }) => value === companyId)
            : { value: userInfo.companyId, label: 'User Company' };
          setCompany(companyOption);
        }
        if (machineId && !machine) {
          const machineOption = machines.find(({ value }) => value === machineId);
          setMachine(machineOption);
        }
        if (email && userEmail === '') setUserEmail(email);
        if (name && userName === '') setUserName(name);
        if (phone && userPhone === '') setUserPhone(phone);
      }
      if (type === 'create') {
        if (userInfo !== null && !userInfo?.isAdmin && company === null) {
          setCompany({ value: userInfo.companyId, label: 'User Company' });
        }
      }
    }
  }, [
    roles,
    companies,
    user,
    company,
    type,
    userEmail,
    userPhone,
    userType,
    userName,
    machine,
    machines,
    userInfo,
  ]);

  const handleCloseModal = (e) => {
    if (e.target.id === 'shade') setActive(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (type === 'create') {
      try {
        if (
          userName === ''
          || userPhone === ''
          || userType === null
          || company === null
          || userEmail === ''
          || (userType?.label === 'Cliente' && machine === null)
        ) {
          throw new Error('Campos faltando');
        }
        const userObj = {
          name: userName,
          phone: userPhone,
          roleId: userType.value,
          companyId: company.value,
          email: userEmail,
          ...(machine ? { machineId: machine?.value } : {}),
        };

        await axiosPost({
          url: '/users', body: userObj, success: reloadList,
        });

        setActive(false);

        setUserName('');
        setUserPhone('');
        setUserEmail('');
        setUserType(null);
        setCompany(null);
      } catch (err) {
        setError({
          type: 'modal/post',
          message: err.response?.data?.message || err.message,
          status: err.response?.status || false,
          url: null,
        });
      }
    } else if (type === 'update') {
      try {
        const changeObj = {};
        if (userName !== user.name) changeObj.name = userName;
        if (userEmail !== user.email) changeObj.email = userEmail;
        if (userPhone !== user.phone) changeObj.phone = userPhone;
        if (userType?.value !== user.roleId) changeObj.roleId = userType.value;
        if (company?.value !== user.companyId) changeObj.companyId = company.value;
        if (machine?.value !== user.machineId) changeObj.machineId = machine.value;
        if (Object.keys(changeObj).filter(([, value]) => !!value).length > 0) {
          await axiosPatch({ url: `/users/${user._id}`, body: changeObj });
          accept();
        }
        setActive(false);
      } catch (err) {
        setError({
          type: 'modal/patch',
          message: err.response?.data?.message || err.message,
          status: err.response?.status || false,
          url: null,
        });
      }
    }
  };

  const loadOptions = useCallback((companyId) => (async (inputValue, callback) => {
    const res = await axiosGet({ url: `/machines?filter=${JSON.stringify({ companyId })}` });

    const options = res.data?.data.map(({ _id, name }) => ({ label: name, value: _id }));

    callback(options);
  }), [axiosGet]);

  const showMachineSelect = (userType?.label === 'Cliente' && company !== null);

  return (
    <ModalBase id="shade" active={active} onClick={handleCloseModal}>
      <ModalContent onSubmit={handleSubmit}>
        <Button type="button" onClick={() => setActive(false)}>
          <Image src={closeIcon} />
        </Button>
        <Title>
          {title}
        </Title>
        <Select
          value={userType}
          isSearchable={false}
          onChange={setUserType}
          placeholder="Tipo do Usuário"
          options={roles}
          styles={colourStyles}
        />
        {userInfo?.isAdmin && (
          <Select
            value={company}
            isSearchable={false}
            onChange={setCompany}
            placeholder="Selecione a empresa"
            options={companies}
            styles={colourStyles}
          />
        )}

        <SelectDropDown active={showMachineSelect}>
          {showMachineSelect && (
            <Modal.SearchSelect
              isSearchable={false}
              value={machine}
              onChange={setMachine}
              loadOptions={loadOptions(company.value)}
              defaultOptions={loadOptions(company.value)}
              placeholder="Selecione a Máquina"
            />
          )}
        </SelectDropDown>

        <Input value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Nome" type="text" />
        <Input value={userEmail} onChange={(e) => setUserEmail(e.target.value)} placeholder="E-mail" type="email" />
        <Input value={userPhone} onChange={(e) => setUserPhone(e.target.value)} placeholder="Número do Celular" type="text" />

        <SubmitButton type="submit">{buttonValue}</SubmitButton>
      </ModalContent>
    </ModalBase>
  );
}

export default memo(UserModal);
