import styled, { css } from 'styled-components';
import background from '../../assets/background.png';

import colors from '../../styles/Colors';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  &::after {
    content: "";
    background: url('${background}');
    opacity: 0.03;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    background-size: auto;
  }
`;

export const InfoCardContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 900px) {
    justify-content: space-between;
  }
`;

export const InfoCard = styled.div`
  width: 28vw;
  height: 28vw;
  max-width: 150px;
  max-height: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  padding: 5px;
  margin: 15px 0;

  position: relative;

  ${({ isActive }) => (isActive ? (`
    background-image: linear-gradient(#00acea, #0070bc);
    color: ${colors.mainWhite};
    `) : (`
    background: ${colors.background};
    color: #000;
    border: 1px solid ${colors.secondaryBlue};
    `))}

    @media(min-height: 1024px) {
      max-width: 250px;
      max-height: 250px;
    }
`;

export const InfoCardLabel = styled.span`
  color:  ${({ isActive }) => (isActive ? colors.mainWhite : '#000')};
  text-align: center;
  font-size: 90%;
  line-height: 100%;

  @media(min-height: 1024px) {
    font-size: 180%;
  }
`;

export const InfoCardValue = styled.span`
  font-size: 1.5em;
  font-weight: bold;

  @media(min-height: 1024px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 400px) {
    font-size: 0.8rem;
  }
`;

export const ChartSection = styled.section`
  background: ${colors.mainWhite};
  width: 100%;
  padding: 100px 30px 20px 30px;
  margin-top: -50px;

  display: flex;
  justify-content: center;

  > canvas {
    height: 250px !important;
  }
`;

export const Last3Content = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 0;

  @media only screen (max-width: 900px) {
    width: 90% !important;
  }
`;

export const PieSection = styled.section`
  background: ${colors.mainWhite};
  width: 90%;
  max-width: 350px;
  padding: 10px 0;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;

  margin-bottom: 50px;

  .recharts-surface {
    display: flex;
    justify-content: center;
    width: -webkit-fill-available !important;
  }

  .recharts-wrapper {
    width: -webkit-fill-available !important;
  }
`;

export const InfoContainer = styled.div`
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);

  /* max-width: 350px; */
  height: 320px;

  width: calc(50% - 1rem);
  background: #fff;
  margin: 0.5rem 0.5rem 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ flexStart }) => (flexStart ? 'flex-start' : 'space-around')};
  position: relative;
  flex-wrap: nowrap;


  @media (min-width: 900px) {
    margin-top: 0;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin: 0 0 1rem;
  }

  > h3 {
    color: black;
    opacity: 0.35;
    font-size: 0.875rem;
    font-weight: 600;
    width: calc(100% - 1.4rem);
  }

  > .line-graph-container {
    width: 100%;
    height: 100%;
  }

  ${({ padding }) => padding && css`
    padding: 1rem 1rem 1rem;
    > h3 {
      width: calc(100%);
    }
  `}

  ${({ overflow }) => overflow && css`
    overflow: scroll;
  `}
`;

export const TopSeller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.8rem;
  width: 90%;
  border-bottom: ${(props) => {
    if (!props.isTheLast) {
      return '0.1rem solid rgba(0, 0, 0, 0.16)';
    }
    return 'none';
  }};

  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 0.75rem;
  `}

  div {
    display: flex;
    align-items: center;
    color: ${colors.secondaryBlue};

    span {
      color: #4d4d4d;
    }

    p {
      color: ${colors.secondaryBlue};
      font-size: 1rem;
      margin: 0;
      height: auto;
      margin-left: 1.5rem;
    }
  }
`;

export const TopSellerMachines = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.8rem;
  width: 90%;
  border-bottom: ${(props) => {
    if (!props.isTheLast) {
      return '0.1rem solid rgba(0, 0, 0, 0.16)';
    }
    return 'none';
  }};

  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 0.625rem;
  `}

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    

    .faturamento-total {
      color: black;
      opacity: 0.35;
      margin-bottom: 1rem;
    }

    p {
      color: #3fa9f5;
    }
  }

  > div {
    display: flex;
    align-items: center;

    span {
      color: #4d4d4d;
    }

    div {
      display: flex;
      align-items: center;
      color: #3fa9f5;
      flex-direction: column;
      margin-left: 1.5rem;

      h4 {
        margin: 0;
        height: auto;
        font-size: 1rem;
        color: ${colors.secondaryBlue};
        width: 100%;
      }

      p {
        font-size: 1rem;
        margin: 0;
        height: auto;
        width: 100%;
        font-size: 0.75rem;
        font-style: italic;
        color: black;
        opacity: 0.35;
      }
    }
  }

`;
