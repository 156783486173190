import styled from 'styled-components';
import Colors from '../../styles/Colors';

const GroupsSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 75vh;
`;

const Header = styled.header`
  display: flex;
  justify-content: ${({ admin }) => (admin ? 'space-between' : 'flex-end')};
  width: 100%;
  padding: .8rem 1.2rem;
  position: relative;
  z-index: 101;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: ${({ link }) => (link ? 'none' : 'unset')};

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

const Image = styled.img`
  margin: .6rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: ${Colors.secondaryBlack};
  height: 2rem;
`;

const Dash = styled.div`
  width: 6.6rem;
  height: .2rem;
  background-color: ${Colors.secondaryBlue};
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-flow: column;
  background-color: ${Colors.background};
  align-items: center;
`;

const GroupList = styled.section`
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  flow-grow: 1;
  overflow: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
  }
`;

export {
  GroupsSection,
  Header,
  Button,
  Image,
  TitleContainer,
  Title,
  Dash,
  ContentContainer,
  GroupList,
};
