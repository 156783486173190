import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';

import { useAxios } from '../services/api';
import { useUserInfo } from './UserContext';

const MachineContext = createContext([]);
const { Consumer: MachineConsumer } = MachineContext;

function MachineProvider({ children }) {
  const [machineInfo, setMachineInfo] = useState(null);
  const [axiosGet] = useAxios('get');
  const [userInfo] = useUserInfo();

  useEffect(() => {
    const loadOptions = async () => {
      const [companies] = await Promise.all([
        (userInfo?.isAdmin ? axiosGet({ url: '/companies' }) : null),
      ]);

      const companyOptions = companies?.data?.data?.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }));

      setMachineInfo({
        companies: companyOptions,
      });
    };

    loadOptions();
  }, [axiosGet, userInfo]);

  return (
    <MachineContext.Provider value={[machineInfo, setMachineInfo]}>
      {children}
    </MachineContext.Provider>
  );
}

export const useMachineContext = () => useContext(MachineContext);

export default MachineContext;

export {
  MachineProvider,
  MachineConsumer,
};
