import React, { useState, useEffect } from 'react';

import { useAxios } from '../../services/api';
import QuestionCard from '../QuestionCard';
import QuestionModal from '../QuestionModal';
import { CardsContainer, Container, NewButton } from './styles';

function Questions() {
  const [createModalActive, setCreateModalActive] = useState(false);
  const [questions, setQuestions] = useState([]);

  const [axiosGet, axiosPost] = useAxios('get', 'post');

  const handleAddQuestion = async (question) => {
    await axiosPost({
      url: '/questions',
      body: question,
    });
    await axiosGet({
      url: '/questions',
      setState: setQuestions,
    });
  };

  useEffect(() => {
    axiosGet({
      url: '/questions',
      setState: setQuestions,
    });
  }, []);

  return (
    <Container>
      <div>
        <NewButton onClick={() => setCreateModalActive(true)}>
          Nova pergunta
        </NewButton>
        <QuestionModal
          modalTitle="Criação de pergunta"
          active={createModalActive}
          setActive={setCreateModalActive}
          handleSubmit={handleAddQuestion}
        />
      </div>
      <CardsContainer>
        {questions.map((question) => (
          <QuestionCard question={question} />
        ))}
      </CardsContainer>
    </Container>
  );
}

export default Questions;
