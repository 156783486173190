import styled from 'styled-components';

import Colors from '../../styles/Colors';

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
`;

const TitleContainer = styled.div`
  margin: 4.6rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  width: 100%;
  background-color: ${Colors.background};
  display: flex;
  flex-direction: column;
  flex-flow: column;
  align-items: center;
  height: 70vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-bottom: 1.3rem;
`;

const NewButton = styled.button`
  background-color: ${Colors.blue};
  padding: .3rem 2.6rem;
  outline: none;
  border: none;
  border-radius: .2rem;
  font-style: italic;
  color: #fff;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 .3rem;
  border: 1px solid ${(props) => (props.active ? Colors.blue : 'transparent')};
  border-radius: .2rem;
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  color: black;
  outline: none;
  padding: 0 .3rem;
  width: 100%;
  max-width: 10rem;
  box-sizing: border-box;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
`;

const Image = styled.img`
  cursor: pointer;
  background-color: transparent;
  margin: 0 .3rem;
`;

export {
  Panel,
  TitleContainer,
  Header,
  NewButton,
  OptionsContainer,
  InputContainer,
  Input,
  Button,
  Image,
  ContentContainer,
};
