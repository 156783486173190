import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const NewButton = styled.button`
  background-color: ${Colors.blue};
  padding: .3rem 2.6rem;
  outline: none;
  border: none;
  border-radius: .2rem;
  font-style: italic;
  color: #fff;
  white-space: nowrap;
  opacity: 1;
  cursor: pointer;
  margin-left: 1.6rem;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const HeaderTitle = styled.h3`
  margin-bottom: 0; 
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  color: ${Colors.secondaryBlack};
  font-weight: bold;
  min-height: 1.3rem;
  font-size: 16px;

  /* @media(max-width: 600px) {
    font-size: 14px;
    white-space: normal;
  } */
`;

export const HeaderSubtitle = styled.h3`
  color: ${Colors.grey};
  font-style: italic;
  font-size: 0.7rem;
  /* @media(max-width: 600px){
    font-size: 0.6rem;
  } */
`;

export const LockContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 16rem));
  justify-content: flex-start;
  justify-items: center;
  align-items: start;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: #f5f5f5; */
  padding: 16px;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 1rem;
  }
`;

