import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import {
  Container,
  List,
  Button,
  Image,
  Card,
  Text,
  TextContainer,
} from './styles';
import { url, useAxios } from '../../services/api';
import SearchHeader from '../SearchHeader';
import Modal from '../Modal';
import WarnModal from '../WarnModal';
import LazyLoader from '../LazyLoader';
import { stringifyFilter } from '../../utils';

import deleteIcon from './Assets/delete.svg';

function TeamList({ companyId, groupName, location }) {
  const [search, setSearch] = useState('');
  const [roles, setRoles] = useState(null);
  const [members, setMembers] = useState(null);

  const [axiosGet, axiosDelete, axiosPatch] = useAxios('get', 'delete', 'patch');

  const [user, setUser] = useState('');

  const [deleting, setDeleting] = useState(false);

  const [filter, setFilter] = useState(null);

  useEffect(() => {
    axiosGet({
      url: '/roles',
      setState: setRoles,
      process: (res) => {
        const optionsRoles = res.map((role) => {
          const { _id, name } = role;
          return { value: _id, label: name };
        });
        return optionsRoles;
      },
    });
  }, [axiosGet]);

  const loadList = () => {
    const groupId = location.pathname.split('/')[2];

    const searchStr = search === '' ? '' : `&search=${search}`;

    const newFilter = {
      ...(filter || {}),
      filter: {
        ...(filter?.filter || {}),
        $and: [
          ...(filter?.filter?.$and || []),
          { groups: groupId },
        ],
      },
    };

    const filterArr = stringifyFilter(newFilter);
    const filterUrl = `${filterArr.join('&')}`;

    axiosGet({ url: `/users?${filterUrl}${searchStr}`, setState: setMembers });
  };

  useEffect(loadList, [location.pathname, search, filter]);

  const handleDeleteUser = (userId) => {
    const groupId = location.pathname.split('/')[2];

    axiosDelete({ url: `/groups-members/${groupId}`, body: { userId }, success: loadList });
  };

  const loadOptions = async (inputValue, callback) => {
    const searchStr = (inputValue && inputValue === '') ? '' : `&search=${inputValue}`;

    const res = await axiosGet({ url: `/users?filter=${JSON.stringify({ companyId })}${searchStr}` });

    const users = res.data.data;

    callback(users.map(({ _id, name }) => ({ value: _id, label: name })));
  };

  const handleAddUser = async () => {
    const groupId = location.pathname.split('/')[2];

    await axiosPatch({ url: `/groups-members/${groupId}`, body: { userId: user.value }, success: loadList });
  };

  return (
    <Container>
      <WarnModal
        active={deleting}
        setActive={setDeleting}
        title="Excluir integrante?"
        text={(
          <>
            Tem certeza que deseja excluir o integrante
            {' '}
            <span>{deleting.name}</span>
            {' '}
            do time
            {' '}
            <span>{groupName}</span>
            ?
          </>
        )}
        accept={() => handleDeleteUser(deleting.id)}
      />
      <SearchHeader
        setSearch={setSearch}
        filter={filter}
        setFilter={setFilter}
        filterOptions={['Ordenar A-Z', 'Cargo']}
        currentData={members}
        type="groupMembers"
        exportFields={['name']}
      >
        {(active, setActive) => (
          <Modal handleSubmit={handleAddUser} active={active} setActive={setActive}>
            <Modal.Title>Novo Integrante</Modal.Title>
            <Modal.SearchSelect
              value={user}
              setValue={setUser}
              defaultOptions={loadOptions}
              loadOptions={loadOptions}
              placeholder="Nome"
            />
            <Modal.Button>Finalizar</Modal.Button>
          </Modal>
        )}
      </SearchHeader>
      {roles && members ? (
        <List>
          {members.map(({
            _id,
            name,
            roleId,
            avatar,
          }) => (
            <Card key={_id}>
              <div style={{ display: 'flex' }}>
                <Image avatar src={avatar} />
                <TextContainer>
                  <Text isTitle>{name}</Text>
                  <Text italic opacity={0.5} black>
                    {roles.find(({ value }) => value === roleId)?.label || 'deleted_role'}
                  </Text>
                </TextContainer>
              </div>
              <Button icon className="icon" onClick={() => setDeleting({ id: _id, name })}>
                <Image icon src={deleteIcon} />
              </Button>
            </Card>
          ))}
        </List>
      ) : <LazyLoader />}
    </Container>
  );
}

export default withRouter(TeamList);
