import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import {
  CompanyDetail,
  Users,
  Customers,
  Home,
  GroupDetail,
  Products,
  Companies,
  Items,
  Ads,
  Machines,
  MachineDetail,
  Reports,
  Sales,
} from '../pages';
import { MachineDetailProvider } from '../state/MachineDetailContext';

function LoggedRoutes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/usuarios" component={Users} />
      <PrivateRoute path="/consumidores" component={Customers} />
      <PrivateRoute path="/maquinas/:id" component={() => <MachineDetailProvider><MachineDetail /></MachineDetailProvider>} />
      <PrivateRoute path="/anuncios" component={Ads} />
      <PrivateRoute path="/maquinas" component={Machines} />
      <PrivateRoute path="/itens" admin={false} component={Items} />
      <PrivateRoute exact path="/empresas" admin component={Companies} />
      <PrivateRoute path="/empresas/:id" component={CompanyDetail} />
      <PrivateRoute path="/operacoes/:id" component={GroupDetail} />
      <PrivateRoute path="/produtos" component={Products} />
      <PrivateRoute path="/relatorios" component={Reports} />
      <PrivateRoute path="/vendas" component={Sales} />
    </Switch>
  );
}

export default LoggedRoutes;
