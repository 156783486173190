import React from 'react';
import Chart from 'react-apexcharts';

function HeatmapChart({data}) {
  return (
    <Chart
      options={{
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: ['#008FFB'],
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          labels: {
            style: {
              colors: ['#666', '#666', '#666', '#666', '#666', '#666', '#666'],
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ['#666', '#666', '#666', '#666', '#666', '#666', '#666'],
            },
          },
        },
      }}
      series={data}
      height="100%"
      width="100%"
      type="heatmap"
    />
  );
}

export default HeatmapChart;
