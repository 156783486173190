import styled from 'styled-components';
import { DatePicker as DatePickerComponent, TimePicker as TimePickerComponent } from 'antd';
import { Checkbox as CheckboxComponent } from 'antd';
import Colors from '../../styles/Colors';

const View = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const ContentContainer = styled.section`
  width: 100%;
  background-color: ${Colors.background};
  display: flex;
  flex-direction: column;
  flex-flow: column;
  align-items: center;
  height: 70vh;
`;

const AdList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
  /* grid-template-rows: 18rem; */
  justify-items: center;
  align-items: start;
  width: 95%;
  flex-grow: 1;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
`;

const Icon = styled.img`
  margin: 0;
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: ${({ width }) => width};
  opacity: ${({ opacity }) => opacity || 1};

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '.8rem' : '.6rem')};
  }
`;

const Label = styled.span`
  color: #016ec2;
  text-align: left;
  white-space: nowrap;
`;

const DateSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;


  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const DateSubContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const DatePicker = styled(DatePickerComponent)`
  border: 1px solid #016ec2;
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 90%;

  .ant-picker-input > * {
    color: #016ec2;
  }

  .ant-picker-input > input::placeholder {
    color: #016ec2;
  }
`;

const TimeTitle = styled.h3`
  font-weight: normal;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #016ec2;
`;

const TimePicker = styled(TimePickerComponent)`
  border: 1px solid #016ec2;
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;
  letter-spacing: 0.2rem;
  width: 90%;
  margin-bottom: 10px;

  .ant-picker-input > * {
    color: #016ec2;
    letter-spacing: 0.2rem;
  }

  .ant-picker-input > input::placeholder {
    color: #016ec2;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  
`;

const CheckboxGroup = styled(CheckboxComponent.Group)`
  > * {
    color: #016ec2;
  }
`;

const Checkbox = styled(CheckboxComponent)`
  color: #016ec2;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media(max-width: 1024px) {
    width: 90%;
  }
`;

const DayPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DayOfTheWeekLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const SwitchContainer = styled.div`
  width: '100%'; 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;
const TextContainer = styled.div`
  width: '100%'; 
  display: 'flex'; 
  justify-content: 'flex-start'; 
  text-align: left;
`;

export {
  View,
  ContentContainer,
  AdList,
  Icon,
  Text,
  DateSection,
  DatePicker,
  TimePicker,
  DateContainer,
  DateSubContainer,
  TimeTitle,
  Label,
  CheckboxContainer,
  CheckboxGroup,
  Checkbox,
  DatePickerContainer,
  DayPickerContainer,
  DayOfTheWeekLabel,
  SwitchContainer,
  TextContainer
};
