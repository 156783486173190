import styled from 'styled-components';

const Container = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ page }) => (page ? '100vh' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Container,
};
