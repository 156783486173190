import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import { useAxios } from '../../services/api';
import vendmachPng from '../../assets/vendmach.png';
import {
  List,
  Image,
  Card,
  Text,
} from './styles';

function MachineList({ groupId }) {
  const [machines, setMachines] = useState(null);
  const [redirect, setRedirect] = useState(null);

  // console.log(machines);
  const imageMap = {
    Vendmach: vendmachPng,
  };

  const [axiosGet] = useAxios('get');

  useEffect(() => {
    axiosGet({
      url: `/machines?filter=${JSON.stringify({ groupId })}`,
      setState: setMachines,
      process: async (data) => {
        const newData = data.map((machine) => ({
          ...machine,
          image: imageMap[machine.type],
        }));

        return newData;
      },
    });
  }, [groupId, axiosGet]);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <List>
      {machines?.map(({ _id, image, name }) => (
        <Card onClick={() => setRedirect(`/maquinas/${_id}`)} key={name}>
          <Image src={image} />
          <Text>{name}</Text>
        </Card>
      ))}
    </List>
  );
}

export default MachineList;
