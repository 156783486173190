import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Redirect } from 'react-router';

import InputMask from "react-input-mask";

import {
  ProductsSection,
  ProductsList,
  ContentContainer,
  Icon,
  Header,
  Button,
  Title,
  Image,
  TitleContainer,
  Dash,
  Ghost,
  ModalContainer,
} from './styles';
import {
  SearchHeader,
  Modal,
  ProductCard,
  LazyLoader,
} from '../../components';
import { useAxios } from '../../services/api';
import { stringifyFilter } from '../../utils';
import { useUserInfo } from '../../state/UserContext';

import imageIcon from './Assets/photo_plus.svg';
import imageInfoIcon from './Assets/photo_info.svg';
import backIcon from './Assets/back.svg';

function ProductList({
  products,
  setProducts,
  showGhost,
  updateInterface,
  setShouldUpdate,
}) {
  return (
    <ProductsList>
      {products.map((props) => (
        <ProductCard
          setProducts={setProducts}
          key={props._id}
          updateInterface={updateInterface}
          setShouldUpdate={setShouldUpdate}
          {...props}
        />
      ))}
      {showGhost && (
        <Ghost>
          <LazyLoader />
        </Ghost>
      )}
    </ProductsList>
  );
}

const ProductListMemoized = ProductList;

function Products() {
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [company, setCompany] = useState(null);
  const [ncm, setNcm] = useState(null);
  const [cst, setCst] = useState(null);
  const [inputBarCode, setInputBarCode] = useState('');
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const [companyOptions, setCompanyOptions] = useState([]);

  const [filter, setFilter] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [showGhost, setShowGhost] = useState(false);
  const [canCreateProduct, setCanCreateProduct] = useState(false);

  const [userInfo] = useUserInfo();

  const [axiosGet, axiosPost] = useAxios('get', 'post');

  const isAdmin = userInfo?.isAdmin;

  useEffect(() => {
    if (!userInfo) return;

    const getCreatePermission = async () => {
      if (isAdmin) {
        return setCanCreateProduct(true);
      }

      const { data: roleData } = await axiosGet({ url: `/roles/${userInfo.roleId}` });
      if (roleData.data.name === 'Manager') {
        return setCanCreateProduct(true);
      }
    };

    getCreatePermission();
  }, [isAdmin]);

  useEffect(() => {
    if (!isAdmin) {
      const navbarImg = document.querySelector('#logoM');
      navbarImg.style.display = 'none';
      return () => { navbarImg.style.display = 'block'; };
    }
    return () => { };
  }, [isAdmin]);

  const updateInterface = async () => {
    if (!products.length || !shouldUpdate) return;

    const { data: itemsData } = await axiosGet({ url: '/items' });

    const result = products.map((eachProduct) => {
      const itemIndex = itemsData.data.findIndex(
        (eachItem) => eachItem.productId === eachProduct._id,
      );

      if (itemIndex !== -1) {
        const specificItem = itemsData.data[itemIndex];

        return {
          ...eachProduct,
          price: specificItem.price,
          itemCompanyId: specificItem.companyId,
          isItem: true,
          itemId: specificItem._id,
        };
      }

      return eachProduct;
    });

    setProducts(result);
    setShowGhost(false);
    setShouldUpdate(false);
  };

  useEffect(() => {
    updateInterface();
    setShouldUpdate(true);
  }, [products]);

  const loadList = useCallback(() => {
    setProducts([]);
    const searchStr = search === '' ? '' : `search=${search}`;

    const filterArr = stringifyFilter(filter || []);
    const filterUrl = filter ? `${filterArr.join('&')}` : '';

    const fetch = async () => {
      const { data: productsData } = await axiosGet({ url: `/products?${searchStr}${filterUrl ? '&' : ''}${filterUrl}`, setState: setProducts });

      setProducts(productsData.data);
      setShouldUpdate(true);
      updateInterface(productsData);
    };

    fetch();
  }, [setProducts, setShowGhost, filter, search, axiosGet]);

  useEffect(() => {
    loadList();
  }, [search, filter, loadList]);

  useEffect(() => {
    if (!isAdmin) return;

    const fetchCompanies = async () => {
      const res = await axiosGet({
        url: '/companies',

      });

      setCompanyOptions(res.data.data.map((eachCompany) => ({
        value: eachCompany._id,
        label: eachCompany.name,
      })));
    };

    fetchCompanies();
  }, [axiosGet, userInfo]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      name === ''
      || description === ''
      || image === null
    ) {
      throw new Error('Campos faltando');
    }
    if (userInfo?.isAdmin && company === null) {
      throw new Error('Campos faltando');
    }
    if (inputBarCode && !(/^\d{13}$/.test(inputBarCode))) {
      throw new Error('Código de barras inválido');
    }
    if (name.length > 40) {
      throw new Error('O nome do produto não pode ter mais de 40 caracteres');
    }
    const data = new FormData();

    data.set('name', name);
    data.set('description', description);
    data.set('companyId', userInfo.isAdmin ? company.value : userInfo?.companyId);

    data.append('image', image);

    if (inputBarCode) {
      data.set('barcode', inputBarCode);
    }
    if (ncm){
      data.set('ncm', ncm);
    }
    if (cst){
      data.set('cst', cst);
    }
    console.log("data",data);
    setShowGhost(true);
    await axiosPost({ url: '/products', body: data, success: loadList });
    setShowGhost(false);
  };
  
  if (redirect) return <Redirect to={redirect} />;

  return (
    <ProductsSection>
      <Header isAdmin={isAdmin}>
        {!isAdmin && (
          <Button type="button" onClick={() => setRedirect('/itens')}>
            <Image src={backIcon} />
          </Button>
        )}

        <TitleContainer>
          <Title>Produtos</Title>
          <Dash />
        </TitleContainer>
      </Header>
      <ContentContainer>
        <SearchHeader
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          filterOptions={['Ordenar A-Z']}
          type="product"
          buttonValue="Criar Novo"
          currentData={products}
          hasButton={canCreateProduct}
        >
          {(active, setActive) => (
            <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
              <Modal.Title>Criação de Produto</Modal.Title>

              <Modal.Input
                value={name}
                setValue={setName}
                placeholder="Nome do Produto"
              />

              <Modal.TextArea
                value={description}
                setValue={setDescription}
                placeholder="Descrição do Produto"
              />

              {userInfo?.isAdmin && (
                <Modal.Select
                  value={company}
                  setValue={setCompany}
                  options={companyOptions}
                  placeholder="Selecione a empresa"
                />
              )}

              <Modal.FileInput setValue={setImage} placeholder="Imagem do Produto">
                <Icon src={imageIcon} />
              </Modal.FileInput>

              {/* <Modal.FileInput placeholder="Informações">
                <Icon src={imageInfoIcon} />
              </Modal.FileInput> */}
              <ModalContainer>
                <InputMask
                  mask="9999999999999"
                  pattern="[0-9]*"
                  maskChar=""
                  type='tel'
                  size="large"
                  name="inputBarCode"
                  placeholder="Código de Barras"
                  value={inputBarCode}
                  onChange={(e) => setInputBarCode(e.target.value)}
                  >
                  {(inputProps) => <Modal.Input {...inputProps} />}
                  </InputMask>

                <InputMask
                  mask="99999999"
                  pattern="[0-9]*"
                  maskChar=""
                  type='tel'
                  size="large"
                  name="ncm"
                  placeholder="NCM"
                  value={ncm}
                  onChange={(e) => setNcm(e.target.value)}
                  >
                  {(inputProps) => <Modal.Input {...inputProps} />}
                  </InputMask>

                  <InputMask
                  mask="9999"
                  pattern="[0-9]*"
                  maskChar=""
                  type='tel'
                  size="large"
                  name="cst"
                  placeholder="CST"
                  value={cst}
                  onChange={(e) => setCst(e.target.value)}
                  >
                  {(inputProps) => <Modal.Input {...inputProps} />}
                  </InputMask>
                </ModalContainer>
              <Modal.Button>Finalizar</Modal.Button>
            </Modal>
          )}
        </SearchHeader>

        {products ? (
          <ProductListMemoized
            products={products}
            setProducts={setProducts}
            showGhost={showGhost}
            updateInterface={updateInterface}
            setShouldUpdate={setShouldUpdate}
          />
        ) : <LazyLoader />}
      </ContentContainer>
    </ProductsSection>
  );
}

export default Products;
