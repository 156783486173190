import styled from 'styled-components';

export const Container = styled.div`
  > canvas {
    height: 250px !important;
    width: 250px !important;
  }
`;

export const Bar = styled.div`
  width: ${({ width }) => (width ? `${width}%` : '50%')};
`;
