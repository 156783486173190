import styled from 'styled-components';

import Colors from '../../styles/Colors';

const Card = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  outline: 1px solid transparent;
  background-color: white;
  border-bottom: 1px solid #ccc;
  height: 4rem;
  margin-bottom: 1px;

  @media only screen and (min-width: 600px) {
    &:hover {
      outline: 1px solid ${Colors.blue};
    };
  }
`;

const Container = styled.td`
  transition: padding .5s ease-in-out;
  padding: .6rem 1.2rem ${({ open }) => (open ? '6rem' : '.6rem')};
  height: inherit;
  width: 100%;
  position: relative;
  background-color: white;
`;

const DropContainer = styled(Container)`
  @media only screen and (max-width: 600px) {
    display: ${({ dropdown }) => (dropdown ? 'unset' : 'none')};
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
`;

const ButtonContainer = styled(DropContainer)`
  text-align: right;
`;

const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 .3rem;
  display: ${({ active, dropdown }) => {
    if (dropdown) return 'unset';
    return active ? 'unset' : 'none';
  }};
  border: ${({ dropdown }) => dropdown && `1px solid ${Colors.secondaryBlue}`};
  padding: ${({ dropdown }) => dropdown && '.3rem 0'};
  border-radius: .2rem;
  flex-grow: 1;
`;

const ButtonText = styled.p`
  margin: 0;
  margin-left: 10px;
  color: ${Colors.secondaryBlue};
  display: inline-block;
`;

const DropDownButton = styled.button`
  border: none;
  background-color: transparent;
  display: none;
  float: right;
  outline: none;

  @media only screen and (max-width: 600px) {
    display: unset;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: max-height .5s ease-out;
  max-height: ${({ show }) => (show ? '6.6rem' : '0rem')};
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
`;

export {
  Card,
  Container,
  DropContainer,
  Text,
  Image,
  ButtonContainer,
  Button,
  ButtonText,
  DropDownButton,
  DropDownContainer,
};
