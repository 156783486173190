import React, {
  useCallback, createRef, useState, memo,
} from 'react';

import { Link } from 'react-router-dom';
import { api } from '../../services/api';
import { useErrorContext } from '../../state/ErrorContext';

import {
  Container,
  Input,
  SubmitButton,
  Form,
  BackgroundCorners,
} from '../../components/Auth';
import { getUrlParameter } from '../../utils/getUrlParam';

import {
  Message, Content, LogoContainer, Logo,
} from './styles';

import { useWindowSize } from '../../hooks/useWindowSize';

function ForgotPass(props) {
  const size = useWindowSize();
  const [key] = useState(
    getUrlParameter('key'),
  );
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [password2Input, setPassword2Input] = useState('');

  const [, setError] = useErrorContext();

  const inputRef = createRef();
  const RepeatpasswordRef = createRef();

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (props.location.search) {
      if (!(passwordInput && password2Input)) {
        return setError({
          type: 'modal/post',
          message: 'Campos vazios',
          status: false,
          url: null,
        });
      }

      if (passwordInput !== password2Input) {
        return setError({
          type: 'modal/post',
          message: 'Senhas não batem',
          status: false,
          url: null,
        });
      }

      const validationKey = props.location.search.split('?key=').join('');

      const { data } = await api.patch(`/forget-password/${validationKey}`, {
        newPassword: passwordInput,
        newPassword2: password2Input,
      });

      return setError({
        type: 'modal/post',
        message: 'Senhas alteradas com sucesso',
        status: false,
        url: null,
        isSuccess: true,
      });
    }
    try {
      if (!emailInput) {
        return setError({
          type: 'modal/post',
          message: 'Campos vazios',
          status: false,
          url: null,
        });
      }

      const { data } = await api.post('/forget-password', {
        email: emailInput,
      });

      if (!data.isError) {
        return setError({
          type: 'modal/post',
          message: `Cheque seu email: ${emailInput}`,
          status: false,
          url: null,
          isSuccess: true,
        });
      }
    } catch (error) {
      return setError({
        type: 'modal/post',
        message: 'Erro',
        status: false,
        url: null,
      });
    }

    setEmailInput('');
    setPasswordInput('');
    setPassword2Input('');
  }, [emailInput, passwordInput, password2Input]);

  return (
    <>
      <Container>
        <Content>
          <Link to="/">
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </Link>
          {key ? (
            <>
              <Message>
                Pronto, agora é só escolher sua nova senha e assim que
                terminar, acessar sua conta normalmente.
              </Message>
              <Form onSubmit={handleSubmit}>
                <Input
                  name="password"
                  placeholder="Nova senha"
                  ref={inputRef}
                  value={passwordInput}
                  onChange={(event) => setPasswordInput(event.target.value)}
                  type="password"
                  onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                />
                <Input
                  name="password"
                  placeholder="Repita a nova senha"
                  ref={RepeatpasswordRef}
                  value={password2Input}
                  onChange={(event) => setPassword2Input(event.target.value)}
                  type="password"
                  onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                />
                <SubmitButton type="primary" htmlType="submit" onClick={handleSubmit}>
                  Redefinir senha
                </SubmitButton>
              </Form>
            </>
          ) : (
            <>
              <Message>
                Digite o seu email de acesso e clique no link que te
                enviaremos em breve para redefinir sua senha.
              </Message>
              <Form onSubmit={handleSubmit}>
                <Input
                  name="email"
                  placeholder="E-mail"
                  ref={inputRef}
                  value={emailInput}
                  onChange={(event) => setEmailInput(event.target.value)}
                />
                <SubmitButton type="primary" htmlType="submit" onClick={handleSubmit}>
                  Enviar
                </SubmitButton>
              </Form>
            </>
          )}
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default memo(ForgotPass);
