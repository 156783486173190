import styled from 'styled-components';
import Colors from '../../styles/Colors';

const AdSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 90%;
  margin-left: 50px;

  @media only screen and (max-width: 600px){
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 90%;
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: min-content;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const TypeSection = styled.section`
  margin: 1rem 0;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: white;
`;

const Icon = styled.img`
  margin: 0 .5rem;
`;

const IntervalHeader = styled.header`
  display: flex;
  margin: .5rem 0;
  justify-content: space-between;
  align-items: center;
  width: 25rem;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const IntervalInputContainer = styled.div`
  border: 1px solid ${Colors.blue};
  position: relative;
  border-radius: .3rem;
  padding: .1rem .3rem;
  margin: 0 1.2rem;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px){
    margin: 0;
  }
`;

const IntervalInput = styled.input`
  border: none;
  background-color: white;
  color: black;
  outline: none;
  width: 2rem;
  max-width: 2rem;
  margin: 0 1rem;
  text-align: center;
`;

const Error = styled.p`
  color: red;
  margin: auto 0;
`;

const AdContainer = styled.section`
  display: grid;
  grid-template-columns: min-content min-content;
  max-height: ${({ maxHeight }) => `${maxHeight * 326}px`};
  overflow: hidden;
  transition: max-height 1s ease-in-out;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: ${({ maxHeight }) => `${((2 * maxHeight) + +(maxHeight !== 0)) * 326}px`};
  }
`;

const Unit = styled.span`
  position: absolute;
  right: .5rem;
  color: black;
`;

export {
  AdSection,
  Text,
  TypeSection,
  Icon,
  Button,
  IntervalHeader,
  IntervalInputContainer,
  IntervalInput,
  Error,
  AdContainer,
  Unit,
};
