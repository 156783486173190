import styled from 'styled-components';

import Colors from '../../styles/Colors';

export const Container = styled.div`
  width: fit-content;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 5.5rem;
  margin-bottom: 2rem;

  h1 {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
  }

  div {
    height: 0.25rem;
    width: 1.8rem;
    background: ${Colors.blue};
  }
`;

export const Padder = styled.div`
  height: 5.5rem;
  margin-bottom: 2rem;
`;
