import styled, { css } from 'styled-components';
import { DatePicker as DatePickerComponent, TimePicker as TimePickerComponent } from 'antd';
import Select from 'react-select';

import Colors from '../../styles/Colors';

export const Page = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media(min-width: 900px) {
    max-width: 80%;
  }
`;

export const BoxesContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;

  ${({ $wrap }) => $wrap && css`
    @media only screen and (max-width: 900px) {
      flex-direction: column;

      > div {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  `}
`;

export const FilterContainer = styled.div`
  width: ${({ width }) => width || '72%'};
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  gap: 5rem;
  @media(max-width: 900px) {
    width: 90%;
    gap: 0.5rem;
  }
`;

export const FilterItem = styled.button`
  border-radius: 50px;
  padding: .1rem .5rem;
  background-color: transparent;
  color: ${Colors.blue};
  border: 1px solid ${Colors.blue};
  height: min-content;
  margin: 0 .3rem;
  outline: none;

  ${({ active }) => active && css`
    background-color: ${Colors.blue};
    color: ${Colors.mainWhite};
    border: 1px solid transparent;
  `}
`;

export const DateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const DateSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const DatePicker = styled(DatePickerComponent)`
  border: 1px solid ${Colors.secondaryBlue};
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;

  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 10px;

  .ant-picker-input > * {
    color: ${Colors.secondaryBlue};
  }

  .ant-picker-input > input::placeholder {
    color: ${Colors.secondaryBlue};
  }
`;

export const TimeTitle = styled.h3`
  font-weight: normal;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 20px;
  color: ${Colors.secondaryBlue};
`;

export const TimePicker = styled(TimePickerComponent)`
  border: 1px solid ${Colors.secondaryBlue};
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;
  letter-spacing: 0.2rem;
  width: 100%;
  margin-bottom: 10px;

  .ant-picker-input > * {
    color: ${Colors.secondaryBlue};
    letter-spacing: 0.2rem;
  }

  .ant-picker-input > input::placeholder {
    color: ${Colors.secondaryBlue};
  }
`;

export const Label = styled.span`
  color: ${Colors.secondaryBlue};
  width: 100%;
  text-align: left;
  white-space: nowrap;
`;

export const FilterSelect = styled(Select)`
  width: 12rem;
  font-size: 0.8rem;
  @media(max-width: 900px) {
    width: 12rem;
    gap: 0.5rem;
    font-size: 0.7rem;
  }
`;

export const colourStyles = {
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    border: `1px solid ${Colors.secondaryBlue}`,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${Colors.secondaryBlue}`,
    },
    margin: '1.3rem 0',
  }),
  option: (base, state) => ({
    ...base,
    color: Colors.secondaryBlue,
    backgroundColor: state.isSelected ? '#eee' : base.backgroundColor,
    cursor: 'pointer',
  }),
  indicatorSeparator: () => { },
  dropdownIndicator: (base) => ({ ...base, '& svg': { fill: Colors.secondaryBlue } }),
  singleValue: (base) => ({ ...base, color: Colors.secondaryBlue }),
  placeholder: (base) => ({ ...base, color: Colors.secondaryBlue }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    marginTop: '0rem',
    borderRadius: '0rem',
  }),
};
