import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import LoggedRoutes from './LoggedRoutes';
import { UserProvider } from '../state/UserContext';
import {
  ForgotPass,
  NotFound,
  Redirect,
} from '../pages';
import { NavBar } from '../components';

function Routes() {
  return (
    <CookiesProvider>
      <UserProvider>
        <Router>
          <Switch>
            <Route exact path="/esqueci-senha" component={ForgotPass} />
            <Route exact path="/redirect" component={Redirect} />
            <Route path="/(|usuarios|consumidores|itens|empresas|operacoes|produtos|maquinas|anuncios|relatorios|vendas)">
              <LoggedRoutes />
            </Route>
            <Route>
              <NavBar />
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </UserProvider>
    </CookiesProvider>
  );
}

export default Routes;
