import React from 'react';
import { Switch } from 'antd';
import {
 List, SwitchContainer, Text,
} from './styles';
import { useMachineDetailContext } from '../../state/MachineDetailContext';
import { useAxios } from '../../services/api';

function PaymentTypeEnable() {
  const [machine] = useMachineDetailContext();
  const [axiosPatch] = useAxios('patch');

  const paymentTypes = [
    { value: 'credit', label: 'Crédito TEF' },
    { value: 'debit', label: 'Débito TEF' },
    { value: 'voucher', label: 'Voucher TEF' },
    { value: 'pix', label: 'Pagar com Pix' },
    { value: 'card', label: 'Cartão Cadastrado'},
  ];

  const getValue = (value) => machine[`has${value[0].toUpperCase()}${value.slice(1)}`];

  const setValue = async (value, newValue) => {
    await axiosPatch({
      url: `/machines/${machine._id}`,
      body: { [`has${value[0].toUpperCase()}${value.slice(1)}`]: newValue },
    });
  };

  return (
    <List>
      {paymentTypes.map(({ label, value }) => (
        <SwitchContainer>
          <Text>{label}</Text>
          <Switch
            defaultChecked={getValue(value)}
            onChange={(newValue) => setValue(value, newValue)}
          />
        </SwitchContainer>
      ))}
    </List>
  );
}

export default PaymentTypeEnable;
