import styled from 'styled-components';
import { Checkbox } from 'antd';

import Colors from '../../styles/Colors';

const Card = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  outline: 1px solid transparent;
  background-color: white;
  border-bottom: 1px solid #ccc;
  height: 4rem;

  @media only screen and (min-width: 750px) {
    &:hover {
      outline: 1px solid ${Colors.blue};
      .options {
        opacity: 1;
        button {
          pointer-events: all;
        }
      }
    };
  }
  @media only screen and (max-width: 750px) {
    cursor: pointer;
  }
`;

const Container = styled.td`
  transition: padding .5s ease-in-out;
  padding: .6rem 1.2rem ${({ open }) => (open ? '6rem' : '.6rem')};
  height: fit-content;
  width: 100%;
  position: relative;
  background-color: white;

  @media only screen and (max-width: 750px) {
    display: ${({ dropdown }) => (dropdown && 'none')};
  }
  @media only screen and (min-width: 750px) {
    &.options {
      opacity: 0;
      button {
        pointer-events: none;
      }
    }
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (max-width: 750px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  span {
    white-space: nowrap;
  }
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
`;

const Icon = styled.img`
  margin: 0 .3rem;
`;

const DropDownButton = styled.button`
  border: none;
  background-color: transparent;
  display: none;
  float: right;
  outline: none;

  @media only screen and (max-width: 750px) {
    display: unset;
  }
`;

const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
  float: right;
`;

const DropDownMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: max-height .5s ease-out;
  max-height: ${({ show }) => (show ? '14rem' : '0rem')};
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
`;


export {
  Card,
  Container,
  Text,
  StyledCheckbox,
  Button,
  Icon,
  DropDownButton,
  Image,
  DropDownMenu,
};
