import React, {
  useState,
  memo,
} from 'react';
import { useCookies } from 'react-cookie';

import {
  Container,
  Logo,
  Input,
  SubmitButton,
  Form,
  FowardPassword,
  Message,
  BackgroundCorners,
  Content,
} from '../../components/Auth';

import api, { useAxios } from '../../services/api';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useUserInfo } from '../../state/UserContext';

function Auth({ setLogged }) {
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [, setUserInfo] = useUserInfo();
  const [, setCookie] = useCookies(['token']);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const [axiosGet] = useAxios('get');

  const createError = (text) => {
    setError(true);
    setMessage(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await api.post('/login', { email, password });
      const { user, token } = res.data.data;

      const { data: roleData } = await api.get(`/roles/${user.roleId}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (roleData.data.name === 'Cliente') {
        setLoading(false);
        return createError('Você não pode logar como cliente no admin');
      }

      setCookie('token', token, { path: '/' });
      setCookie('user_id', user._id, { path: '/' });

      const resRole = await axiosGet({ url: `/roles/${user.roleId}` });
      const myCompany = resRole.data.data.name !== 'admin'
        ? await axiosGet({ url: `/companies/${user.companyId}` })
        : null;

      const vendmach = resRole.data.data.name === 'admin'
        ? (await axiosGet({ url: '/companies' })).data.data.find(({ name }) => (name === 'vendmach'))
        : null;

      setUserInfo({
        ...user,
        isAdmin: resRole.data.data.name === 'admin',
        permissions: resRole.data.data.permissions,
        myCompany: vendmach || myCompany.data.data,
        ...(vendmach ? { companyId: vendmach._id } : null),
      });

      setLoading(false);
      setLogged(true);
    } catch (err) {
      if (err.response?.status === 400 || err.response?.status === 404) createError('E-mail ou senha incorretos por favor refaça o login');
      else createError('O login não pode ser efetuado, consulte a equipe de desenvolvimento');
      setLoading(false);
    }

    return null;
  };

  return (
    <>
      <Container>
        <Content>
          <Logo />
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              name="password"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <SubmitButton type="primary" htmlType="submit" onClick={handleSubmit} loading={loading}>
              Entrar
            </SubmitButton>
          </Form>
          {message && (
            <Message error={error}>{message}</Message>
          )}
          <FowardPassword to="/esqueci-senha">
            Esqueceu a senha?
          </FowardPassword>
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default memo(Auth);
