import styled from 'styled-components';

const Wrapper = styled.div`
  margin: .6rem 0;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  opacity: 0.8;
  white-space: nowrap;
`;

export {
  Wrapper,
  Container,
  Text,
};
