import styled from 'styled-components';
import { DatePicker as DatePickerComponent } from 'antd';

import colors from '../../styles/Colors';

export const Header = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
`;

export const HeaderTitle = styled.h3`
  margin-bottom: 0; 
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  color: ${colors.secondaryBlack};
  font-weight: bold;
  min-height: 1.3rem;
  font-size: 16px;

  @media(max-width: 600px) {
    font-size: 14px;
    /* white-space: normal; */
  }
`;

export const HeaderSubtitle = styled.h3`
  color: ${colors.grey};
  font-style: italic;
  font-size: 0.7rem;
  @media(max-width: 600px){
    font-size: 0.6rem;
  }
`;

export const HeaderName = styled.h3`
  color: ${colors.secondaryBlack};
  font-size: .7rem;
  width: 100%;
  @media(max-width: 600px){
    font-size: 0.6rem;
  }
`;

export const GearIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${colors.mainWhite};
  width: 14rem;
  height: 16rem;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: .3rem;

  &:hover {
    outline: 1px solid ${colors.blue};

    .hover-button {
      margin-top: -2.2rem;
      max-height: 2.2rem;
    }
    .hover-price-button {
      margin-top: -4.1rem;
      max-height: 4.1rem;
    }
  }

  @media(max-width: 600px) {
    margin: 0.2rem;
    padding: 0.5rem;
    width: 11.5rem;
    height: 14rem;
  }
`;

export const HeaderImageContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  display:flex;
  justify-content: center;

  > img{
    width:100%;
    object-fit: contain;
  }
`;

export const Type = styled.span`
  padding: .1rem .3rem;
  background-color: ${colors.secondaryBlue};
  color: ${colors.mainWhite};
  border-radius: .3rem;
  font-size: .6rem;
  margin: .3rem 0;
`;

export const TopCard = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const TopCardIcon = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: right; 
  align-items: center;
  gap: .25rem;
`;


export const DatePicker = styled(DatePickerComponent)`
  border: 1px solid ${colors.blue};
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 90%;

  .ant-picker-input > * {
    color: ${colors.blue};
  }

  .ant-picker-input > input::placeholder {
    color: ${colors.blue};
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
  width: 50%;
  @media(max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

export const Label = styled.span`
  color: ${colors.blue};
  text-align: left;
  white-space: nowrap;
`;