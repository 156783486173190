import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import Colors from '../../styles/Colors';

import { Container } from './styles';

function DoughnutChart({
  data = [], label = '', labels = [], colors,
}) {
  return (
    <Container>
      <Doughnut
        data={{
          labels,
          datasets: [{
            label,
            data,
            backgroundColor: colors,
          }],
        }}
        options={{
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const totalSum = tooltipItem.dataset.data.reduce((a, b) => a + b, 0);
                  const percentage = (tooltipItem.raw / totalSum) * 100;

                  return `${tooltipItem.raw} (${percentage.toFixed(0)}%)`;
                },
                title: (tooltipItem) => tooltipItem[0].label,
              },
            },
          },
        }}
      />
    </Container>
  );
}

export default DoughnutChart;
