import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  height: max-content;
  margin: 1rem;
  padding: 1rem;
  border-radius: .3rem;

  &:hover {
    outline: 1px solid ${Colors.blue};

    .hover-button {
      margin-top: -2.2rem;
      max-height: 2.2rem;
    }
    .hover-price-button {
      margin-top: -4.1rem;
      max-height: 4.1rem;
    }
  }

  @media(max-width: 600px) {
    height: max-content;
  }
`;

export const ButtonModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2rem;
  align-items: center;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 .3rem;
  display: ${({ active, dropdown }) => {
    if (dropdown) return 'unset';
    return active ? 'unset' : 'none';
  }};
  border: ${({ active, dropdown }) => (active || dropdown) && `1px solid ${Colors.secondaryBlue}`};
  padding: ${({ active, dropdown }) => (active || dropdown) && '.3rem 0'};
  border-radius: .2rem;
  flex-grow: 1;
  width: 2rem;
  /* max-width: ${({ dropdown }) => !dropdown && '2rem'}; */
`;

export const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
`;