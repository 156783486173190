export default {
  background: '#f5f5f5',
  blue: '#3fa9f5',
  shadow: '#00000029',
  secondaryBlue: '#3fa9f5',
  green: '#21c25e',
  mainWhite: '#fff',
  mainBlack: 'rgba(0, 0, 0, 0.16)',
  secondaryBlack: '#4d4d4d',
  grey: '#919191',
  errorRed: '#c20101',
  darkBlue: '#016ec2',
  red: 'red',
  purple: 'purple',
  yellow: 'yellow',
  brown: 'brown',
};
