import React from 'react';

import { Container, Padder } from './styles';

export default function ({ title }) {
  return (
    <>
      <Container>
        <h1>{ title }</h1>
        <div />
      </Container>
      <Padder />
    </>
  );
}
