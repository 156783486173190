import styled, { css } from 'styled-components';

import Colors from '../../styles/Colors';

const Card = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  outline: 1px solid transparent;
  background-color: white;
  border-bottom: 1px solid #ccc;
  height: 4rem;
  margin-bottom: 1px;

  @media only screen and (min-width: 600px) {
    &:hover {
      outline: 1px solid ${Colors.blue};
    };
  }
`;

const Container = styled.td`
  transition: padding .5s ease-in-out;
  padding: .6rem 1.2rem ${({ open }) => (open ? '6rem' : '.6rem')};
  height: inherit;
  width: 100%;
  position: relative;
  background-color: white;
`;

const DropContainer = styled(Container)`
  @media only screen and (max-width: 600px) {
    display: ${({ dropdown }) => (dropdown ? 'unset' : 'none')};
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 .3rem;
  display: ${({ active, dropdown }) => {
    if (dropdown) return 'unset';
    return active ? 'unset' : 'none';
  }};
  border: ${({ dropdown }) => dropdown && `1px solid ${Colors.secondaryBlue}`};
  padding: ${({ dropdown }) => dropdown && '.3rem 0'};
  border-radius: .2rem;
  /* flex-grow: 1; */
  width: 2.2rem;
`;

const ButtonText = styled.p`
  margin: 0;
  margin-left: 10px;
  color: ${Colors.secondaryBlue};
  display: inline-block;
`;

const DropDownButton = styled.button`
  border: none;
  background-color: transparent;
  display: none;
  float: right;
  outline: none;

  @media only screen and (max-width: 600px) {
    display: unset;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: max-height .5s ease-out;
  max-height: ${({ show }) => (show ? '8rem' : '0rem')};
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
`;

const InfoContainer = styled.div`
  /* border-radius: 0.5rem; */
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);

  max-width: 800px;
  height: 320px;

  /* width: calc(50% - 1rem); */
  background: #fff;
  margin: 0.5rem 0.5rem 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ flexStart }) => (flexStart ? 'flex-start' : 'space-around')};
  position: relative;
  flex-wrap: nowrap;


  @media (min-width: 900px) {
    margin-top: 0;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin: 0 0 1rem;
  }

  > h3 {
    color: black;
    opacity: 0.35;
    font-size: 0.875rem;
    font-weight: 600;
    width: calc(100% - 1.4rem);
  }

  > .line-graph-container {
    width: 100%;
    height: 100%;
  }

  ${({ padding }) => padding && css`
    padding: 1rem 1rem 1rem;
    > h3 {
      width: calc(100%);
    }
  `}

  ${({ overflow }) => overflow && css`
    overflow: scroll;
  `}
`;

const TopSeller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.8rem;
  width: 90%;
  border-bottom: ${(props) => {
    if (!props.isTheLast) {
      return '0.1rem solid rgba(0, 0, 0, 0.16)';
    }
    return 'none';
  }};

  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 0.75rem;
  `}

  div {
    display: flex;
    align-items: center;
    color: ${Colors.secondaryBlue};

    span {
      color: #4d4d4d;
    }

    p {
      color: ${Colors.secondaryBlue};
      font-size: 1rem;
      margin: 0;
      height: auto;
      margin-left: 1.5rem;
    }
  }
`;

export {
  Card,
  Container,
  DropContainer,
  Text,
  Image,
  ButtonContainer,
  Button,
  ButtonText,
  DropDownButton,
  DropDownContainer,
  InfoContainer,
  TopSeller,
};
