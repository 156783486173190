import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useAxios } from '../../services/api';
import {
    Card,
    ButtonModal,
    Button,
    Image,
    ButtonContainer,
    Subtitle,
    RadioButton,
    DateContainer,
    DatesWrapper,
    Disabled,
    DatePicker,
    Label,
   } from './styles';
import Modal from '../Modal';
import { Header, HeaderSubtitle, HeaderTitle } from '../SlotsCard/styles';
import createBorderlessIcon from './assets/ic-edit.svg';

function DiscountCard({coupon, groupId, couponList}) {

    const [shouldEditModalShow,setShouldEditModalShow] = useState(false)
    const [axiosPatch, axiosGet, axiosDelete, axiosPost] = useAxios('patch', 'get', 'delete', 'post');
    const [inputCoupon, setInputCoupon] = useState({
        name: '',
        code: '',
        type: '',
        value: 0,
        startDate: '',
        endDate: '',
        couponQuantity: 0,
      });
      const [couponStartDate, setCouponStartDate] = useState({
        value: coupon ? (coupon.startDate ? moment(coupon.startDate) : '') : moment()}
      )
      const [couponEndDate, setCouponEndDate] = useState({
        value: coupon ? (coupon.endDate ? moment(coupon.endDate) : '') : moment()}
      )

    useEffect(() => {
        setInputCoupon(coupon);
    }, [coupon]);
    
    const handleSubmit = async () => {
        if (coupon) {

          const getDiff = (original, altered) => {
            const alteredEntries = Object.entries(altered)
              .filter(([key, value]) => (original[key] !== value));
            return Object.fromEntries(alteredEntries);
          };
    
          const changeObj = getDiff(coupon, inputCoupon);
    
          if (Object.keys(changeObj).length === 0) {
            throw new Error('Campos não foram alterados');
          }
          if (
            !inputCoupon.couponQuantity
          ) {
            throw new Error('Campos faltando');
          }
          const updatedArray = couponList.map((eachCoupon) => {
            if (eachCoupon._id === inputCoupon._id) {
                return {
                    ...eachCoupon,
                    couponQuantity: inputCoupon.couponQuantity,
                    startDate: inputCoupon.startDate,
                    endDate: inputCoupon.endDate,
                };
            }
            return eachCoupon;
          });

          await axiosPatch({
            url: `/groups/${groupId}`,
            body: { discounts: updatedArray },
          });
        }
        window.location.reload(); 
      };

    const OptionButton = ({
      src,
      dropdown,
      active,
      onClick,
    }) => (
      <Button onClick={onClick} active={active} dropdown={dropdown}>
        <Image noMargin src={src} />
      </Button>
    );
  
    const quantidade = coupon?.couponQuantity;
    const usados = coupon?.couponsAvailable;
    const remaining = (quantidade - usados); 

    return (
      <>
        <Card>
            <ButtonModal>
                <HeaderTitle>{coupon?.name}</HeaderTitle>
                <OptionButton
                  src={createBorderlessIcon}
                  onClick={() => setShouldEditModalShow(true)}
                  active
                />
            </ButtonModal>
          <HeaderSubtitle>{coupon?.code}</HeaderSubtitle>
          <Subtitle>Cupons restantes: {remaining}</Subtitle>
          <Subtitle>Cupons usados: {coupon?.couponsAvailable}</Subtitle>
            <ButtonContainer>
            
              </ButtonContainer>
          
        </Card>
        <Modal
          handleSubmit={handleSubmit}
          active={shouldEditModalShow}
          setActive={setShouldEditModalShow}
        >
          <Modal.Title>Edição de Cupom</Modal.Title>
           <Label>Quantidade:</Label>
            <Modal.Input
              value={inputCoupon?.couponQuantity}
              onChange={(e) => setInputCoupon({...inputCoupon, couponQuantity: e.target.value})}
            />
            <Disabled>Tipo do desconto: {coupon?.type}</Disabled>
            <Disabled style={{marginBottom: "1rem"}}>Valor do desconto: {coupon?.value}</Disabled>
                <DatesWrapper>
                <DateContainer>
                    <Label>Data Inicial</Label>
                    <DatePicker
                    {...couponStartDate}
                    format={'DD/MM/YYYY'}
                    onChange={(date) => {
                        const selectedDate = moment(date);
                        
                        if (!date) {
                          setInputCoupon({...inputCoupon, startDate : null});
                            return setCouponStartDate({value: ""});
                        }
                        setInputCoupon({...inputCoupon, startDate: selectedDate.toDate()});
              
                        return setCouponStartDate({
                            value: moment(date),
                        });
                    }}
                    />
                </DateContainer>
                <DateContainer>
                    <Label>Data Final</Label>
                    <DatePicker
                    {...couponEndDate}
                    format={'DD/MM/YYYY'}
                    onChange={(date) => {
                        const selectedDate = moment(date);
                        
                        if (!date) {
                            setInputCoupon({...inputCoupon, endDate : null});
                            return setCouponEndDate({value: ""});
                        }
                        setInputCoupon({...inputCoupon, endDate: selectedDate.toDate()});
                        
                        return setCouponEndDate({
                            value: moment(date),
                        });
                    }}
                    />
                </DateContainer>
                </DatesWrapper>
          <Modal.Button>Salvar</Modal.Button> 
        </Modal>
      </>
    )
  }

  export default DiscountCard;