import React, { useState, useEffect } from 'react';

import { useMachineDetailContext } from '../../state/MachineDetailContext';
import { useAxios } from '../../services/api';
import { CardsContainer, Container, Image, NewButton, } from './styles';

function QrCode() {
  const [qrcode, setQrCode] = useState({data:{qrcode:{image:''}}});
  const [machine] = useMachineDetailContext();

  const [axiosGet] = useAxios('get');

  const downloadQrcode = (src) => {
    //
  }

  useEffect(() => {
    const getQrCode = async () => {
      const { data: qrcode } = await axiosGet({ url: `/machines/qrcode/${machine._id}` });
      setQrCode(qrcode);
    }
    getQrCode();
  }, []);

  return (
    <Container>
      <CardsContainer>
        <Image
          src={qrcode.data.qrcode.image}
        />
      </CardsContainer>
      <CardsContainer>
        <NewButton onClick={() => downloadQrcode(qrcode.data.qrcode.image)}>
          Salvar QrCode em imagem
        </NewButton>
      </CardsContainer>
    </Container>
  );
}

export default QrCode;
