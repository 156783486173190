import React from 'react';
import { Spin } from 'antd';

import { Container } from './styles';

function LazyLoader({ page, size = 'large', width = '100%' }) {
  return (
    <Container width={width} page={page}>
      <Spin size={size} spinning />
    </Container>
  );
}

export default LazyLoader;
