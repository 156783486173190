import React, { useState, useCallback } from 'react';
import InputMask from "react-input-mask";

import {
  View,
  ContentContainer,
} from './styles';
import {
  Title,
  SearchHeader,
  Modal,
  PaginatedList,
  CompanyCard,
  SwitchContainer,
} from '../../components';
import { useAxios } from '../../services/api';
import { stringifyFilter } from '../../utils';

function Companies() {
  const [search, setSearch] = useState('');

  const [name, setName] = useState('');
  const [codigoRecebedor, setCodigoRecebedor] = useState(null);
  const [percentualRecebedor, setPercentualRecebedor] = useState(null);
  const [cnpj, setCnpj] = useState(null);
  const [communicazap_apikey, setCommunicaZap_apikey] = useState(null);
  const [canCreateAd, setCanCreateAd] = useState(false);
  const [canChooseAd, setCanChooseAd] = useState(false);
  const [canCreateProduct, setCanCreateProduct] = useState(false);
  const [block, setBlock] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [hasStockControll, setHasStockControll] = useState(false)

  const [companies, setCompanies] = useState([]);

  const [filter, setFilter] = useState(null);

  const [reloadList, setReloadList] = useState(() => () => { });

  const [axiosPost, axiosGet] = useAxios('post', 'get');

  const options = [
    { name: 'Cria Anúncios', state: canCreateAd, setState: setCanCreateAd },
    { name: 'Escolhe Anúncios', state: canChooseAd, setState: setCanChooseAd },
    { name: 'Cria Produtos', state: canCreateProduct, setState: setCanCreateProduct },
    // { name: 'Empresa bloqueada', state: block, setState: setBlock },
    { name: 'Emite Nota Fiscal', state: invoice, setState: setInvoice},
    { name: 'Controle de Estoque', state: hasStockControll, setState: setHasStockControll }
  ];

  const handleSubmit = async () => {
    if (name === '') {
      throw new Error('Campos faltando');
    }
    await axiosPost({
      url: '/companies',
      body: {
        name,
        canCreateAd,
        canChooseAd,
        canCreateProduct,
        block,
        communicazap_apikey,
        invoice,
        percentualRecebedor,
        codigoRecebedor,
        cnpj,
        hasStockControll,
      },
      success: reloadList,
    });
    setName('');
    setCanCreateAd(false);
    setCanChooseAd(false);
    setCanCreateProduct(false);
    setBlock(false);
    setCnpj('');
    setInvoice(false);
    setCommunicaZap_apikey('');
    setCodigoRecebedor('');
    setPercentualRecebedor('');
    setHasStockControll('');
  };

  const filterArr = stringifyFilter(filter || []);
  const filterUrl = filter ? `${filterArr.join('&')}` : '';

  const url = `/companies?${filterUrl}&search=${search}`;

  return (
    <View>
      <Title title="Empresas" />
      <ContentContainer>
        <SearchHeader
          filter={filter}
          setFilter={setFilter}
          setSearch={setSearch}
          filterOptions={['Ordenar A-Z']}
          currentData={companies}
          type="company"
        >
          {(active, setActive) => (
            <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
              <Modal.Title>Nova Empresa</Modal.Title>
              <Modal.Input value={name} setValue={setName} placeholder="Nome da empresa" />
              <Modal.Input value={communicazap_apikey} setValue={setCommunicaZap_apikey} placeholder='Communica Zap'/>
              <Modal.Input value = {codigoRecebedor} setValue={setCodigoRecebedor} placeholder='Código Recebedor'/>
              <InputMask
                mask="999"
                pattern="[0-9]*"
                maskChar=""
                type='tel'
                size="large"
                name="percentualRecebedor"
                placeholder="Percentual Recebedor"
                value={percentualRecebedor}
                onChange={(e) => setPercentualRecebedor(e.target.value)}
                >
                {(inputProps) => <Modal.Input {...inputProps} />}
              </InputMask>
              <SwitchContainer options={options} />
              { invoice && 
                <InputMask
                  mask="99999999999999"
                  pattern="[0-9]*"
                  maskChar=""
                  type='tel'
                  size="large"
                  name="cnpj"
                  placeholder="CNPJ"
                  value={cnpj}
                  onChange={(e) => setCnpj(e.target.value)}
                  >
                  {(inputProps) => <Modal.Input {...inputProps} />}
                </InputMask>
              }
              <Modal.Button>Finalizar</Modal.Button>
            </Modal>
          )}
        </SearchHeader>
        <PaginatedList
          component={CompanyCard}
          url={url}
          options
          itemsPerPage={10}
          setReloadList={setReloadList}
          process={useCallback(async (data) => {
            const groupsRes = await axiosGet({ url: '/groups' });
            const groups = groupsRes.data.data;

            const newData = data.map((company) => {
              const companyGroups = groups.filter((group) => group.companyId === company._id);
              return {
                ...company,
                groupQuantity: companyGroups.length,
              };
            });

            setCompanies(newData);

            return newData;
          }, [axiosGet])}
        />
      </ContentContainer>
    </View>
  );
}

export default Companies;
