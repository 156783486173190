import React, {
  useState, useEffect, useCallback,
} from 'react';
import { Switch } from 'antd';

import {
  Title,
  SearchHeader,
  AdCard,
  Modal,
} from '../../components';
import {
  View,
  ContentContainer,
  AdList,
  Icon,
  Text,
  DateContainer,
  DateSubContainer,
  DateSection,
  DatePicker,
  TimePicker,
  TimeTitle,
  Label,
  CheckboxContainer,
  CheckboxGroup,
  Checkbox,
  DatePickerContainer,
  DayPickerContainer,
  DayOfTheWeekLabel,
  SwitchContainer,
  TextContainer,
} from './styles';
import { useAxios } from '../../services/api';
import { useUserInfo } from '../../state/UserContext';
import imageIcon from './assets/photo_plus.svg';
import { stringifyFilter } from '../../utils';

function Ads() {
  const [name, setName] = useState('');
  const [type, setType] = useState(null);
  const [company, setCompany] = useState(null);
  const [media, setMedia] = useState(null);
  const [verified, setVerified] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);

  const [companyOptions, setCompanyOptions] = useState(null);

  const [userInfo] = useUserInfo();

  const [ads, setAds] = useState(null);

  const [axiosGet, axiosPost] = useAxios('get', 'post');

  const dayOfTheWeekOptions = [
    {label: "Dom", value: 0},
    {label: "Seg", value: 1},
    {label: "Ter", value: 2},
    {label: "Qua", value: 3},
    {label: "Qui", value: 4},
    {label: "Sex", value: 5},
    {label: "Sab", value: 6},
  ];

  const [shouldPersonalizeShow, setShouldPersonalizeShow] = useState(false);
  const [wholeDay, setWholeDay] = useState(true);
  const [checkedList, setCheckedList] = useState(dayOfTheWeekOptions.map(option => option.value));
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);


  const handleCheckboxSelection = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < dayOfTheWeekOptions.length);
    setCheckAll(list.length === dayOfTheWeekOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? dayOfTheWeekOptions.map(option => option.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const loadList = useCallback( async () => {
    setAds(null);
    const searchStr = search === '' ? '' : `search=${search}`;

    const filterArr = stringifyFilter(filter || []);
    const filterUrl = filter ? `${filterArr.join('&')}` : '';

    //axiosGet({ url: `/ads?${searchStr}${filterUrl ? '&' : ''}${filterUrl}`, setState: setAds }); 
    const response = await axiosGet({ url: `/ads?${searchStr}${filterUrl ? '&' : ''}${filterUrl}` }); // nova requisição para filtrar os ads fullscreen temporariamente
    setAds(response.data.data.filter((ad) => ad.type === 'fullscreen'));

  }, [setAds, filter, search, axiosGet]);

  useEffect(() => {
    loadList();
  }, [search, filter, loadList]);

  const handleSubmit = async () => {
    if (name === '' || !type || (userInfo?.isAdmin && !company) || !startDate || !endDate ) {
      throw new Error('Campos faltando');
    }
    if (type?.value !== 'billboard' && !media) {
      throw new Error('Imagem deve ser incluída');
    }
    if (media) {
      if (!['image/jpeg', 'image/png', 'video/mp4'].includes(media.type)) {
        throw new Error(`Tipo de arquivo (${media.type}) não suportado`);
      }
      if (media.size >= 20000000) { // 20MB
        throw new Error(
          `O arquivo tem ${(media.size / 1000000).toFixed(2)}MB, o máximo permitido é 20MB`,
        );
      }
    }
    const data = new FormData();

    data.append('name', name);
    data.append('type', type.value);
    data.append('companyId', company?.value || userInfo?.companyId);

    const getDate = (specificDate) => {
      if (specificDate) {
        const completeSpecificDate = new Date(specificDate);

        completeSpecificDate.setSeconds(0);
        completeSpecificDate.setHours(0);
        completeSpecificDate.setMinutes(0);

        return completeSpecificDate;
      }

      return null;
    };

    const finalStartDate = getDate(startDate);
    const finalEndDate = getDate(endDate);
    const finalStartTime = startTime === null ? "00:00" : startTime;
    const finalEndTime = endTime === null ? "00:00" : endTime;

    data.append('startDate', finalStartDate);
    data.append('endDate', finalEndDate);
    data.append('startTime', finalStartTime);
    data.append('endTime', finalEndTime);

    data.append('daysOfWeek', checkedList);

    data.append('verified', verified);
    if (media) data.append('media', media);

    await axiosPost({
      url: '/ads',
      body: data,
      success: () => {
        loadList();
        setName('');
        setType(null);
        setCompany(null);
        setMedia(null);
        setVerified(false);
      },
    });
  };

  useEffect(() => {
    if (userInfo?.isAdmin) {
      const loadCompanies = async () => {
        axiosGet({
          url: '/companies',
          setState: setCompanyOptions,
          process: (data) => data.map((eachCompany) => ({
            value: eachCompany._id,
            label: eachCompany.name,
          })),
        });
      };

      loadCompanies();
    }
  }, [userInfo, axiosGet]);
  
  return (
    <View>
      <Title title="Anúncios" />
      <ContentContainer>
        <SearchHeader
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          filterOptions={['Ordenar A-Z']}
          type="ad"
          buttonValue="Criar Novo"
          currentData={ads}
        >
          {(active, setActive) => (
            <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
              <Modal.Title>Criação de Anúncio</Modal.Title>
              {type?.value === 'billboard' ? (
                <Modal.TextArea value={name} setValue={setName} placeholder="Texto do Billboard" />
              ) : (
                <Modal.Input value={name} setValue={setName} placeholder="Nome do Anúncio" />
                )}
              <Modal.Select
                options={[
                  //{ value: 'pageTop', label: 'Topscreen' },
                  { value: 'fullscreen', label: 'Fullscreen' },
                  // { value: 'billboard', label: 'Billboard' },
                ]}
                value={type}
                setValue={setType}
                placeholder="Tipo do Anúncio"
              />
              {userInfo?.isAdmin && (
                <Modal.Select options={companyOptions} value={company} setValue={setCompany} placeholder="Selecione a empresa" />
              )}
              {type?.value !== 'billboard' && (
                <>
                  <Modal.FileInput setValue={setMedia} placeholder="Mídia do Anúncio">
                    <Icon src={imageIcon} />
                  </Modal.FileInput>
                  <TextContainer>
                    <Text italic width="100%" opacity={0.6} black>
                      <br></br>
                    Formatos aceitos: 
                      {' '}
                      <strong>jpeg</strong>
                      {''}
                      ,
                      {' '}
                      <strong>png</strong>
                      {' '}
                      ou
                      {' '}
                      <strong>mp4</strong>
                      . <br></br>
                      Tamanho máximo do arquivo: 
                      {' '}
                      <strong>20 mb.</strong>
                      <br></br>
                      Resolução máxima dos vídeos:
                      {' '}
                      <strong>Full HD.</strong> 
                      <br></br>
                    </Text>
                  </TextContainer>
                </>
              )}
                <SwitchContainer>
                  <Text width="min-content">Ativado</Text>
                  <Switch size="small" checked={verified} onChange={setVerified} />
                </SwitchContainer>

              <DateSection>
                <TimeTitle>Programação do anúncio</TimeTitle>
                <DateContainer>
                  <DateSubContainer>
                    <DatePickerContainer>
                      <Label>Data Inicial:</Label>
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder="dd/mm/aaaa"
                        onChange={(e) => setStartDate(e)}
                      />
                    </DatePickerContainer>
                    <DatePickerContainer>
                      <Label>Data Final:</Label>
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder="dd/mm/aaaa"
                        onChange={(e) => setEndDate(e)}
                      />
                    </DatePickerContainer>
                    <Checkbox value={shouldPersonalizeShow} onChange={() => setShouldPersonalizeShow(!shouldPersonalizeShow)}>Personalizar</Checkbox>
                  </DateSubContainer>
                  {shouldPersonalizeShow && (
                  <>
                    <DateSubContainer>
                      <DatePickerContainer>
                        <Label>Horário Inicial: </Label>
                        <TimePicker
                          format="HH:mm"
                          placeholder="__:__"
                          minuteStep={15}
                          onChange={(_, timeString) => setStartTime(timeString)}
                          disabled={wholeDay}
                          popupStyle={{fontSize: 12}}
                        />
                      </DatePickerContainer>
                      <DatePickerContainer>
                        <Label>Horário Final:</Label>
                        <TimePicker
                          format="HH:mm"
                          placeholder="__:__"
                          minuteStep={15}
                          onChange={(_, timeString) => setEndTime(timeString)}
                          disabled={wholeDay}
                          popupStyle={{fontSize: 12}}
                        />
                      </DatePickerContainer>

                      <Checkbox value={wholeDay} onChange={() => setWholeDay(!wholeDay)} checked={wholeDay}>Dia Inteiro</Checkbox>
                    </DateSubContainer>
                    <DayPickerContainer>
                      <DayOfTheWeekLabel>
                        <Label>Dias de Exibição:</Label>  
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                          Todos
                        </Checkbox>
                      </DayOfTheWeekLabel>
                      <CheckboxContainer>
                        <CheckboxGroup value={checkedList} options={dayOfTheWeekOptions} onChange={handleCheckboxSelection} />
                      </CheckboxContainer>
                    </DayPickerContainer>
                  </>)}
                </DateContainer>
              </DateSection>

              <Modal.Button>Finalizar</Modal.Button>
            </Modal>
          )}
        </SearchHeader>
        <AdList>
          { userInfo?.isAdmin ? 
            ads?.map((info) => <AdCard setAds={setAds} key={info._id} {...info} />)
          :
            ads?.filter((ad) => ad.companyId === userInfo?.companyId).map((info) => <AdCard setAds={setAds} key={info._id} {...info} />)
          }
        </AdList>
      </ContentContainer>
    </View>
  );
}

export default Ads;
