import styled from 'styled-components';
import colors from '../../styles/Colors';

export const SlotCardShow = styled.div`
  border: 1px solid ${colors.secondaryBlue};
  padding: 10px 10px;
  margin: 1rem 0;
  border-radius: 5px;
  position: relative;
  width: 13rem;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const SlotCardTitle = styled.p`
  color: ${colors.secondaryBlue};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.32px;
  text-align: left;
  margin: 0;
`;

export const SlotCardQuantity = styled.p`
  margin: 0;
  text-align: left;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.67;
  letter-spacing: 0.24px;
  text-align: left;
  color: black;
`;

export const ButtonContainer = styled.div`
  /* position: absolute; */
  /* right: 0; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  margin-top: 2rem;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 .3rem;
  border: 1px solid ${colors.secondaryBlue};
  padding: .3rem;
  width: 30px;
  border-radius: .2rem;
  flex-grow: 1;
  max-width: ${({ dropdown }) => !dropdown && '2rem'};
`;

export const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
`;

export const SlotList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 13rem));
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  height: min-content;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 1rem; */
  }
`;

