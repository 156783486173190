import styled, { css } from 'styled-components';

import Colors from '../../styles/Colors';

const Card = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  outline: 1px solid transparent;
  background-color: white;
  border-bottom: 1px solid #ccc;
  height: 4rem;
  position: relative;
  padding-bottom: ${({ showDropDown }) => showDropDown && '7rem'};
  transition: padding .5s ease-in-out;
  overflow: hidden;
  margin-bottom: 1px;

  @media only screen and (min-width: 600px) {
    &:hover {
      outline: 1px solid ${Colors.blue};
    };
  }
  @media only screen and (max-width: 750px) {
    padding-bottom: ${({ showDropDown }) => showDropDown && '11rem'};
    cursor: pointer;
  }
  @media only screen and (max-width: 900px) {
    cursor: ${({ options }) => options && 'pointer'};
  }
`;

const Container = styled.td`
  transition: padding .5s ease-in-out;
  padding: .6rem 1.2rem ${({ open }) => (open ? '6rem' : '.6rem')};
  z-index: 3;
  height: inherit;
  width: 100%;
  position: relative;
  background-color: white;

  @media only screen and (max-width: 750px) {
    display: ${({ vanish }) => vanish && 'none'};
  }
`;

const DropContainer = styled(Container)`
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: 0;
  height: max-content;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    display: ${({ dropdown }) => (dropdown ? 'unset' : 'none')};
  }

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: min-content;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  ${({ spaceBetween }) => spaceBetween && css`
    display: flex;
    width: 80%;
    justify-content: space-between;
  `}

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const Icon = styled.img`
  margin: 0 .3rem;
`;

const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 .3rem;
  display: ${({ active, dropdown }) => {
    if (dropdown) return 'unset';
    return active ? 'unset' : 'none';
  }};
  border: ${({ active, dropdown }) => (active || dropdown) && `1px solid ${Colors.secondaryBlue}`};
  padding: ${({ active, dropdown }) => (active || dropdown) && '.3rem 0'};
  border-radius: .2rem;
  flex-grow: 1;
  max-width: ${({ dropdown }) => !dropdown && '2rem'};
`;

const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
`;

const ButtonContainer = styled(Container)`
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const DropDownButton = styled.button`
  border: none;
  background-color: transparent;
  display: none;
  float: right;
  outline: none;

  @media only screen and (max-width: 750px) {
    display: unset;
  }
`;

const Stripe = styled.td`
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 2;
  height: 4.8rem;
  padding-bottom: .3rem;
  top: 0;
  left: 0;
`;

const DropConditional = styled.div`
  @media only screen and (min-width: 750px) {
    display: none;
  }
`;

export {
  Card,
  Container,
  ButtonContainer,
  DropContainer,
  DropDownButton,
  Text,
  Icon,
  Button,
  Image,
  Stripe,
  DropConditional,
};
