import styled from 'styled-components';

export default styled.div`
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);

  width: ${(props) => props.width};
  height: ${({ height }) => (height ? `${height}rem` : '5rem')};

  background: #fff;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 900px) {
    padding-top: 0.3rem;
    height: ${({ height }) => (height ? `${height}rem` : '10rem')};
  }

  h3 {
    color: black;
    position: absolute;
    font-size: ${({ fixedSize }) => (fixedSize ? '0.875rem' : '0.6rem')};
    left: 0.4rem;
    top: 0.3rem;
    font-weight: bold;
    opacity: 0.35;

    @media (min-width: 900px) {
      font-size: 0.875rem;
      left: 0.7rem;
      top: 0.6rem;
    }
  }

  p {
    color: #3fa9f5;
    font-size: 1.5rem;
    margin: 0;
  }
`;
