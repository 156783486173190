import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { LazyLoader, NavBar } from '../components';
import { useUserInfo } from '../state/UserContext';
import { checkToken } from '../services/api';
import { Auth, NotFound } from '../pages';

function PrivateRoute({
  path,
  exact,
  component: Comp,
  location,
  admin,
  ...props
}) {
  const [logged, setLogged] = useState(null);

  const [userInfo] = useUserInfo();

  const checkLogged = async () => {
    try {
      const isLogged = await checkToken();
      setLogged(isLogged);
    } catch (err) {
      setLogged(err);
    }
  };

  useEffect(() => {
    checkLogged();
  }, []);

  // Wait for info
  if (
    logged === null
    || (
      admin !== undefined
      && !userInfo
    )
  ) return <LazyLoader page />;

  // Check permissions
  if (admin !== undefined) {
    if (admin !== userInfo?.isAdmin) {
      return (
        <Route {...props}>
          <NavBar setLogged={setLogged} />
          <NotFound />
        </Route>
      );
    }
  }

  // Check logged
  if (!logged) return <Auth setLogged={setLogged} />;

  return (
    <Route {...props}>
      <NavBar setLogged={setLogged} />
      <Comp />
    </Route>
  );
}

export default PrivateRoute;
