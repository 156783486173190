import React, {
  useEffect,
  useState,
  memo,
  useRef,
} from 'react';
import { Checkbox } from 'antd';

import {
  ModalBase,
  ModalContent,
  Title,
  List,
  Card,
  Image,
  CardDesc,
  CheckboxContainer,
  ButtonContainer,
  Button,
  MediaContainer,
} from './styles';
import { url, useAxios } from '../../services/api';
import { useMachineDetailContext } from '../../state/MachineDetailContext';
import { useUserInfo } from '../../state/UserContext';

import videoIcon from './assets/video.svg';

function AdCard({ ad, i, handleToogle }) {
  const [videoSrc, setVideoSrc] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const [controls, setControls] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (ad.media.indexOf('.mp4') === -1) {
      setImageSrc(ad.media);
    } else {
      setVideoSrc(ad.media);
    }
  }, [ad.media]);

  const handleVideoClick = () => {
    videoRef.current.play();
    setControls(true);
  };

  return (
    <Card key={ad._id}>
      {ad.type !== 'billboard' && (
        <MediaContainer>
          {imageSrc && <Image src={imageSrc} />}
          {videoSrc && (
            <video
              poster={videoIcon}
              onClick={handleVideoClick}
              style={{ margin: '.3rem', height: '6rem', cursor: 'pointer' }}
              width="100%"
              preload="none"
              controls={controls}
              ref={videoRef}
              muted
              loop
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          )}
        </MediaContainer>
      )}
      <CardDesc billboard={ad.type === 'billboard'}>{ad.name}</CardDesc>
      <CheckboxContainer>
        <Checkbox checked={ad.checked} onChange={() => handleToogle(i)} />
      </CheckboxContainer>
    </Card>
  );
}

function AdModal({
  active, setActive, machine, type,
}) {
  const [axiosGet, axiosPatch] = useAxios('get', 'patch');
  const [ads, setAds] = useState(null);
  const [, reloadInfo] = useMachineDetailContext();
  const [userInfo] = useUserInfo();

  const handleCloseModal = (e) => {
    if (e.target.id === 'shade') setActive(false);
  };

  const handleToogle = (index) => {
    const newAds = [...ads];
    newAds[index].checked = !newAds[index].checked;

    setAds(newAds);
  };

  const handleSubmit = () => {
    const sendAds = ads
      .filter(({ checked }) => (checked))
      .map(({ _id }) => ({ adId: _id }));
    axiosPatch({
      url: `/machines/${machine._id}`,
      body: { ads: sendAds },
      success: () => {
        setActive(false);
        reloadInfo();
      },
    });
  };

  useEffect(() => {
    const machineAdsIds = machine.ads.map(({ adId }) => adId);
    axiosGet({
      url: '/ads',
      setState: setAds,
      process: (res) => res.map((ad) => ({
        checked: machineAdsIds.includes(ad._id),
        ...ad,
      })).filter((ad) => (ad.type === type) && (userInfo?.isAdmin ? (
        ad.companyId === userInfo?.companyId
        || ad.companyId === machine.companyId
      ) : (ad.companyId === userInfo?.companyId))),
    });
  }, [machine.ads, active, axiosGet, machine.companyId, userInfo]);

  return (
    <ModalBase id="shade" active={active} onClick={handleCloseModal}>
      <ModalContent>
        <Title>Selecione os anúncios</Title>

        <List>
            {ads?.map((ad, i) =>  (
              <AdCard
                key={ad._id}
                ad={ad}
                i={i}
                handleToogle={handleToogle}
                setAds={setAds}
              />
            ))
          } 
        </List>

        <ButtonContainer>
          <Button onClick={() => setActive(false)} type="button">Cancelar</Button>
          <Button onClick={handleSubmit} type="button" blue>Concluir</Button>
        </ButtonContainer>
      </ModalContent>
    </ModalBase>
  );
}

export default memo(AdModal);
