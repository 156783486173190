import React, { useState, useEffect, useCallback, useRef } from 'react';
import InputMask from "react-input-mask";

import { Redirect } from 'react-router';
import {
  Card,
  Container,
  Text,
  StyledCheckbox,
  Button,
  Icon,
  DropDownButton,
  Image,
  DropDownMenu,
} from './styles';
import { useAxios } from '../../services/api';
import WarnModal from '../WarnModal';
import SwitchContainer from '../SwitchContainer';

import deleteIcon from './Assets/delete.svg';
import blockIcon from './Assets/block.svg';
import activeBlockIcon from './Assets/active_block.svg';
import arrowIcon from './Assets/arrow.svg';
import editIcon from  './Assets/create.svg';

import { useFirstRender } from '../../hooks/useFirstRender';
import Modal from '../Modal';

function CompanyCard({
  info: {
    canCreateAd,
    canChooseAd,
    canCreateProduct,
    block,
    invoice,
    _id,
    name: companyName,
    groupQuantity,
    communicazap_apikey,
    percentualRecebedor,
    codigoRecebedor,
    cnpj,
    hasStockControll
  },
}) {
  const [createAd, setCreateAd] = useState(canCreateAd);
  const [chooseAd, setChooseAd] = useState(canChooseAd);
  const [createProduct, setCreateProduct] = useState(canCreateProduct);
  const [hasStockControl, setHasStockControl] = useState(hasStockControll)
  const [blocked, setBlocked] = useState(block);
  const [invoiceFlag, setInvoiceFlag] = useState(invoice);
  const [codecodigoRecebedor, setcodeCodigoRecebedor] = useState(codigoRecebedor);
  const [codepercentualRecebedor, setcodePercentualRecebedor] = useState(percentualRecebedor);
  const [codeCnpj, setCodeCnpj] = useState(cnpj);
  const [communicaZap, setCommunicaZap] = useState(communicazap_apikey);
  const [name, setName] = useState(companyName);
  const [axiosDelete, axiosPatch] = useAxios('delete', 'patch');

  const [clicked, setClicked] = useState(false);
  const firstRender = useFirstRender();

  const [deleting, setDeleting] = useState(null);
  const [deleted, setDeleted] = useState(null);
  const [editModal, setEditModal] = useState(false);

  const [showDropDown, setShowDropDown] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const [active, setActive] = useState(false);

  const [mobile, setMobile] = useState(null);

  const patchCompany = useCallback((key, value) => {
    axiosPatch({ url: `/companies/${_id}`, body: { [key]: value } });
  }, [_id, axiosPatch]);

  const generator = (stateController) => (e) => {
    if (active) {
      stateController(e.target.checked);
      setClicked(true);
    }
  };

  useEffect(() => {
    if (!firstRender) {
    patchCompany('block', blocked);
    }
  }, [blocked, patchCompany, block, firstRender]);

  const options = [
    { name: 'Cria Anúncios', state: createAd, setState: setCreateAd },
    { name: 'Cria Produtos', state: createProduct, setState: setCreateProduct },
    { name: 'Escolhe Anúncios', state: chooseAd, setState: setChooseAd },
    { name: 'Emite Nota Fiscal', state: invoiceFlag, setState: setInvoiceFlag},
    { name: 'Controle de Estoque', state: hasStockControl, setState: setHasStockControl }
  ];

  const handleToggle = (event) => {
    const { tagName } = event.target;
    if (
      tagName !== 'BUTTON'
      && tagName !== 'IMG'
      && tagName !== 'INPUT'
      && tagName !== 'SPAN'
      && tagName !== 'DIV'
    ) {
      setRedirect(`/empresas/${_id}`);
    }
  };

  const activateButtons = () => {
    setTimeout(() => {
      setActive(true);
    }, 0);
  };

  const checkBreakpoint = () => setMobile(window.innerWidth < 750);

  useEffect(() => {
    checkBreakpoint();
    window.addEventListener('resize', checkBreakpoint);
    return () => {
      window.removeEventListener('resize', checkBreakpoint);
    };
  }, []);

  if (deleted) return null;
  if (redirect) return <Redirect to={redirect} />;

  const handleEdit = async () => {
    if( companyName === name && 
        invoice === invoiceFlag &&
        codigoRecebedor === codecodigoRecebedor &&
        percentualRecebedor === codepercentualRecebedor &&
        cnpj === codeCnpj &&
        communicazap_apikey === communicaZap &&
        canCreateAd === createAd &&
        canChooseAd === chooseAd &&
        canCreateProduct === createProduct &&
        hasStockControll === hasStockControl
      ){
        throw new Error('Os campos não foram alterados.')
      }
    await axiosPatch({ url: `/companies/${_id}`, body: { 
        name,
        communicazap_apikey: communicaZap,
        invoice: invoiceFlag,
        percentualRecebedor: codepercentualRecebedor,
        codigoRecebedor: codecodigoRecebedor,
        cnpj: codeCnpj,
        canCreateAd: createAd,
        canChooseAd: chooseAd,
        canCreateProduct: createProduct,
        hasStockControll: hasStockControl,
      },
      success: () =>  window.location.reload(), 
    })
  };

  return (
    <Card
      onMouseEnter={activateButtons}
      onFocus={activateButtons}
      onMouseLeave={() => setActive(false)}
      onBlur={() => setActive(false)}
      onClick={(e) => {
        const { tagName } = e.target;
        if (
          tagName !== 'BUTTON'
          && tagName !== 'IMG'
          && tagName !== 'INPUT'
          && tagName !== 'SPAN'
          && tagName !== 'DIV'
          && mobile
        ) {
          setShowDropDown(!showDropDown);
        }
      }}
    >
      <Container text>
        <div>
          <div style={{ float: 'left' }}>
            <Button onClick={handleToggle}>
              <Text style={{ cursor: 'pointer' }} isTitle>{companyName}</Text>
            </Button>
            <Text opacity={0.5} black italic>
              {groupQuantity}
              {' '}
              {groupQuantity === 1 ? 'grupo' : 'grupos'}
            </Text>
          </div>
          <DropDownButton onClick={() => setShowDropDown(!showDropDown)}>
            <Image src={arrowIcon} upside={showDropDown} />
          </DropDownButton>
        </div>
        <DropDownMenu show={showDropDown}>
          {/* <SwitchContainer options={options} /> */}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Button style={{ float: 'right' }} onClick={() => active && setEditModal(true)}>
              <Icon src={editIcon} />
            </Button>
            <Button style={{ float: 'right' }} onClick={() => setBlocked(!blocked)}>
              <Icon src={blocked ? activeBlockIcon : blockIcon} />
            </Button>
            <Button style={{ float: 'right' }} onClick={() => setDeleting(true)}>
              <Icon src={deleteIcon} />
            </Button>
          </div>
        </DropDownMenu>
      </Container>
      {/* <Container className="options" dropdown>
        {options.map(({ name, state, setState }) => (
          <StyledCheckbox key={name} checked={state} onChange={generator(setState)}>
            {name}
          </StyledCheckbox>
        ))}
      </Container> */}
      <Container className="options" dropdown button>
        <Button style={{ float: 'right' }} onClick={() => active && setDeleting(true)}>
          <Icon src={deleteIcon} />
        </Button>
        <Button style={{ float: 'right' }} onClick={() => active && setBlocked(!blocked)}>
          <Icon src={blocked ? activeBlockIcon : blockIcon} />
        </Button>
        <Button style={{ float: 'right' }} onClick={() => active && setEditModal(true)}>
          <Icon src={editIcon} />
        </Button>
      </Container>
      <td>
        <Modal handleSubmit={handleEdit} active={editModal} setActive={setEditModal}>
          <Modal.Title>Edição de Empresa</Modal.Title>
            <Modal.Label>Nome da Empresa</Modal.Label>
              <Modal.Input value={name} setValue={setName} placeholder="Nome da empresa" />
              <Modal.Label>Communica Zap</Modal.Label>
              <Modal.Input value={communicaZap} setValue={setCommunicaZap} placeholder='Communica Zap'/>
              <Modal.Label>Código Recebedor</Modal.Label>
              <Modal.Input value = {codecodigoRecebedor} setValue={setcodeCodigoRecebedor} placeholder='Código Recebedor'/>
              <Modal.Label>Percentual Recebedor</Modal.Label>
              <InputMask
                mask="999"
                pattern="[0-9]*"
                maskChar=""
                type='tel'
                size="large"
                name="percentualRecebedor"
                placeholder="Percentual Recebedor"
                value={codepercentualRecebedor}
                onChange={(e) => setcodePercentualRecebedor(e.target.value)}
                >
                {(inputProps) => <Modal.Input {...inputProps} />}
              </InputMask>
              <SwitchContainer options={options}/>
                { invoiceFlag && 
                  <div>
                  <Modal.Label>CNPJ</Modal.Label>
                  <InputMask
                    mask="99999999999999"
                    pattern="[0-9]*"
                    maskChar=""
                    type='tel'
                    size="large"
                    name="cnpj"
                    placeholder="CNPJ"
                    value={codeCnpj}
                    onChange={(e) => setCodeCnpj(e.target.value)}
                    >
                    {(inputProps) => <Modal.Input {...inputProps} />}
                  </InputMask>
                  </div>
                }
              <Modal.Button>Salvar</Modal.Button>
        </Modal>
        <WarnModal
          active={deleting}
          setActive={setDeleting}
          title="Excluir Empresa?"
          text={(
            <>
              Tem certeza que deseja excluir a empresa
              {' '}
              <span>{companyName}</span>
              ?
            </>
          )}
          accept={async () => {
            try {
              await axiosDelete({ url: `/companies/${_id}` });
              setDeleted(true);
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </td>
    </Card>
  );
}

export default CompanyCard;
