import React, { useState } from 'react';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import ptBr from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

import {
  DateContainer,
  DateSubContainer,
  DatePicker,
  Label,
} from './styles';
import Modal from '../../components/Modal';

moment.locale('pt-br');

function DateFilterModal({ onSubmit, active, setActive }) {
  const [initialStartString, setInitialStartString] = useState({});
  const [initialEndString, setInitialEndString] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dateFormat = 'DD/MM/YYYY';

  const handleSubmit = () => {
    onSubmit(startDate, endDate);
  };

  return (
    <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
      <Modal.Title>Filtro por data</Modal.Title>
      <DateContainer>
        <DateSubContainer>
          <Label>Inicia em:</Label>

          <ConfigProvider
            locale={{
              ...ptBr,
              locale: 'pt',
            }}
          >
            <DatePicker
              format={dateFormat}
              {...initialStartString}
              placeholder="dd/mm/aaaa"
              onChange={(e) => {
                setStartDate(e);

                const updatedDate = new Date(e);
                const dd = updatedDate.getDate();
                const mm = updatedDate.getMonth() + 1;
                const yyyy = updatedDate.getFullYear();

                if (!e) {
                  return setInitialStartString({});
                }

                return setInitialStartString({
                  value: moment(`${dd / 10 >= 1 ? dd : `0${dd}`}/${mm / 10 >= 1 ? mm : `0${mm}`}/${yyyy}`, dateFormat),
                });
              }}
            />
          </ConfigProvider>
        </DateSubContainer>

        <DateSubContainer>
          <Label>Finaliza em:</Label>

          <ConfigProvider locale={ptBr}>
            <DatePicker
              format={dateFormat}
              {...initialEndString}
              placeholder="dd/mm/aaaa"
              onChange={(e) => {
                setEndDate(e);

                const updatedDate = new Date(e);
                const dd = updatedDate.getDate();
                const mm = updatedDate.getMonth() + 1;
                const yyyy = updatedDate.getFullYear();

                if (!e) {
                  return setInitialEndString({});
                }

                return setInitialEndString({
                  value: moment(`${dd / 10 >= 1 ? dd : `0${dd}`}/${mm / 10 >= 1 ? mm : `0${mm}`}/${yyyy}`, dateFormat),
                });
              }}
            />
          </ConfigProvider>
        </DateSubContainer>
      </DateContainer>
      <Modal.Button>Salvar</Modal.Button>
    </Modal>
  );
}

export default DateFilterModal;
