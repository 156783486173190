import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ModalBase = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 103;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${Colors.mainBlack};
`;

const ModalContent = styled.form`
  position: relative;
  width: 85%;
  max-width: 26.6rem;
  height: min-content;
  max-height: 40rem;
  margin: auto;
  padding: 0 2.6rem 2.6rem;
  background-color: ${Colors.mainWhite};
  overflow: auto;
  text-align: center;
`;

const Button = styled.button`
  outline: none;
  border: none;
  background-color: white;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

const Image = styled.img`
  margin: .6rem;
`;

const Title = styled.h2`
  color: ${Colors.secondaryBlue};
  text-transform: uppercase;
  font-weight: bold;
  margin: 2.6rem 0;
`;

const Input = styled.input`
  padding: .6rem;
  width: 100%;

  background-color: ${Colors.background};

  border-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.secondaryBlue};

  outline-style: none;

  color: ${Colors.secondaryBlue};

  margin: .6rem 0;

  &::placeholder {
    color: ${Colors.secondaryBlue};
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  outline: none;
  background-color: white;
  border: 1px solid ${Colors.secondaryBlue};
  padding: .5rem 0;
  color: ${Colors.secondaryBlue};
  border-radius: .2rem;
  margin-top: 2.6rem;
  &:hover {
    color: white;
    border: 1px solid white;
    background-color: ${Colors.secondaryBlue};
  }
`;

const colourStyles = {
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    border: `1px solid ${Colors.secondaryBlue}`,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${Colors.secondaryBlue}`,
    },
    margin: '1.3rem 0',
  }),
  option: (base, state) => ({
    ...base,
    color: Colors.secondaryBlue,
    backgroundColor: state.isSelected ? '#eee' : base.backgroundColor,
    cursor: 'pointer',
  }),
  indicatorSeparator: () => { },
  dropdownIndicator: (base) => ({ ...base, '& svg': { fill: Colors.secondaryBlue } }),
  singleValue: (base) => ({ ...base, color: Colors.secondaryBlue }),
  placeholder: (base) => ({ ...base, color: Colors.secondaryBlue }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    marginTop: '0rem',
    borderRadius: '0rem',
  }),
};

const SelectDropDown = styled.div`
  max-height: ${({ active }) => (active ? '10rem' : '0')};
  transition: max-height 1.5s ease-out;
`;

export {
  ModalBase,
  ModalContent,
  Button,
  Image,
  Title,
  Input,
  SubmitButton,
  colourStyles,
  SelectDropDown,
};
