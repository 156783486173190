import styled from 'styled-components';
import background from '../../assets/background.png';
import colors from '../../styles/Colors';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  &::after {
    content: "";
    background: url('${background}');
    opacity: 0.03;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    background-size: auto;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  width: 100%;
  height: 70vh;
  background-color: ${colors.background};
`;
