import React from 'react';

import {
  PaginatedList, SalesCard, SearchHeader, Title,
} from '../../components';

import { Container, ContentContainer } from './styles';

function Sales() {
  return (
    <Container>
      <Title title="Transações" />
      <ContentContainer>
        <SearchHeader
          filter={{}}
          setFilter={() => {}}
          setSearch={() => {}}
          filterOptions={[]}
          type="machine"
          hasButton={false}
        >
          {() => {}}
        </SearchHeader>
        <PaginatedList
          component={SalesCard}
          url="/sales"
          itemsPerPage={10}
          setReloadList={() => {}}
          process={async (data) => {
            console.log(data);
            return data;
          }}
        />
      </ContentContainer>
    </Container>
  );
}

export default Sales;
