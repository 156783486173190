import React from 'react';

import Title from '../../components/Title';
import DataBox from '../../components/DataBox';
import { useUserInfo } from '../../state/UserContext';

import {
  Page,
  DataContainer,
  BoxesContainer,
} from './styles';
import { DoughnutChart, HeatmapChart } from '../../components';
import FilterHeader from './FilterHeader';
import Colors from '../../styles/Colors';
import { heatMapData } from '../../utils/mockedValues';
import Machines from '../Machines';

function Home() {
  const [userInfo] = useUserInfo();
  const info = {
    deviceCount: 10,
    insertions: 245678,
    visits: 4526,
    mediumEngagement: 55,
  };
//A página de Home fica visível só para Admin, para demais roles a página inicial vira Operações (machines)
  if (!userInfo?.isAdmin) {
    return <Machines />
  }

  return (
    <Page>
      <Title title={`Olá${userInfo?.name ? `, ${userInfo?.name}` : ''}`} />
      <FilterHeader />
      <DataContainer>
        <BoxesContainer>
          <DataBox
            title="Máquinas"
            data={info.deviceCount}
            width={25}
          />
          <DataBox
            title="Inserções"
            data={info.insertions}
            width={75}
          />
        </BoxesContainer>
        <BoxesContainer>
          <DataBox
            title="Visitas"
            data={info.visits}
            width={50}
          />
          <DataBox
            title="Engajamento médio"
            data={`${info.mediumEngagement} seg`}
            width={50}
          />
        </BoxesContainer>
        <BoxesContainer $wrap>
          <DataBox
            title="Tipo de acesso"
            fixedSize
            width={50}
            height={20}
          >
            <DoughnutChart
              labels={['Smartphone', 'Desktop']}
              label="Device use"
              data={[354, 125]}
              colors={[
                Colors.blue,
                Colors.green,
              ]}
            />
          </DataBox>

          <DataBox
            title="Mapa de calor"
            fixedSize
            width={50}
            height={20}
          >
            <HeatmapChart data={heatMapData} />
          </DataBox>
        </BoxesContainer>
        <BoxesContainer $wrap>
          <DataBox
            title="Consumidor por Gênero"
            fixedSize
            width={50}
            height={20}
          >
            <DoughnutChart
              labels={['Masculino', 'Feminino']}
              label="Gênero"
              data={[354, 625]}
              colors={[
                Colors.blue,
                '#33c4e3',
              ]}
            />
          </DataBox>

          <DataBox
            title="Consumidor por Faixa Etária"
            fixedSize
            width={50}
            height={20}
          >
            <DoughnutChart
              labels={['17-25', '26-35', '36-45', '46-55']}
              label="Faixa Etária"
              data={[31, 35, 80, 55]}
              colors={[
                Colors.blue,
                '#33c4e3',
                '#006494',
                Colors.green,
              ]}
            />
          </DataBox>
        </BoxesContainer>
      </DataContainer>
    </Page>
  );
}

export default Home;
