import styled from 'styled-components';
import Colors from '../../styles/Colors';

const View = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
`;

const ContentContainer = styled.section`
  width: 100%;
  background-color: ${Colors.background};
  display: flex;
  flex-direction: column;
  flex-flow: column;
  align-items: center;
  height: 70vh;
`;

export {
  View,
  ContentContainer,
};
