import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import { useAxios } from '../../services/api';
import {
    Card,
    ButtonModal,
    Button,
    Image,
    ButtonContainer
   } from './styles';
import Modal from '../Modal';
import { Header, HeaderSubtitle, HeaderTitle } from '../SlotsCard/styles';
import WarnModal from '../WarnModal';
import deleteBorderlessIcon from './assets/ic-delete.svg';
import createBorderlessIcon from './assets/ic-edit.svg';

function LockCard({lock, openNotification, loadLockList}) {
    const [shouldEditModalShow,setShouldEditModalShow] = useState(false)
    const [deleting, setDeleting] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [lockNumber, setLockNumber] = useState(lock.number);
    const [lockName, setLockName] = useState(lock.description);
    const [lockActive, setLockActive] = useState(lock.active);
  
    const [axiosPatch, axiosGet, axiosDelete, axiosPost] = useAxios('patch', 'get', 'delete', 'post');
  
    const OptionButton = ({
      src,
      dropdown,
      active,
      onClick,
    }) => (
      <Button onClick={onClick} active={active} dropdown={dropdown}>
        <Image noMargin src={src} />
      </Button>
    );
  
    async function updateLock() {
      const changeObj = {}
      if (lockNumber !== lock.number) { changeObj.number = lockNumber; } 
      if (lockName !== lock.description) { changeObj.description = lockName; }
  
      if (Object.keys(changeObj).length === 0) {
        throw new Error('Campos não foram alterados');
      }
      
      try {
        await axiosPatch({
          url: `/machine-locks/${lock._id}`,
          body: changeObj,
        });
        openNotification('Dados salvos com sucesso.');
      } catch (error) {
        console.error(error);
      }
      loadLockList();
    }
  
    function handleSwitchActive() {
      axiosPatch({
        url: `/machine-locks/${lock._id}`,
        body: {
          active: !lockActive,
        },
      });
      setLockActive(!lockActive);
    }
  
    if (deleted) return null;
  
    return (
      <>
        <Card>
            <ButtonModal>
                <HeaderTitle>{lock.description}</HeaderTitle>
                <Switch size="small" checked={lockActive} onClick={handleSwitchActive} />
            </ButtonModal>
          <HeaderSubtitle>{lock.number}</HeaderSubtitle>
            <ButtonContainer>
              <OptionButton
                  src={createBorderlessIcon}
                  onClick={() => setShouldEditModalShow(true)}
                  active
                />
                <OptionButton
                    src={deleteBorderlessIcon}
                    onClick={() => setDeleting(true)}
                    active
                />
              </ButtonContainer>
          
        </Card>
        <Modal
          handleSubmit={updateLock}
          active={shouldEditModalShow}
          setActive={setShouldEditModalShow}
        >
          <Modal.Title>Edição de Trava</Modal.Title>
          <Modal.Label>Nome da Trava:</Modal.Label>
          <Modal.Input
            value={lockName}
            setValue={setLockName}
          />
          <Modal.Label>Código da Trava:</Modal.Label>
          <Modal.Input
            value={lockNumber}
            setValue={setLockNumber}
          />
          <Modal.Button>Salvar</Modal.Button> 
        </Modal>
        <WarnModal
            active={deleting}
            setActive={setDeleting}
            title="Excluir Máquina?"
            text={(
              <>
                Tem certeza que deseja excluir a trava
                {' '}
                <span>{lockName}</span>
                {' '}
                ?
                {' '}
                Essa ação não pode ser desfeita e afetará todo o sistema.
              </>
          )}
            accept={async () => {
              try {
                await axiosDelete({ url: `/machine-locks/${lock._id}` });
                setDeleted(true)
              } catch (err) {
                console.log(err);
              }
            }}
          />
      </>
    )
  }

  export default LockCard;