import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { DatePicker as DatePickerComponent} from 'antd';

import { Radio } from 'antd';
import Modal from '../Modal';

export const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  height: max-content;
  margin: 1rem;
  padding: 1rem;
  border-radius: .3rem;

  &:hover {
    outline: 1px solid ${Colors.blue};

    .hover-button {
      margin-top: -2.2rem;
      max-height: 2.2rem;
    }
    .hover-price-button {
      margin-top: -4.1rem;
      max-height: 4.1rem;
    }
  }

  @media(max-width: 600px) {
    height: max-content;
  }
`;

export const ButtonModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 .3rem;
  border: ${({ active, dropdown }) => (active || dropdown) && `1px solid ${Colors.secondaryBlue}`};
  padding: ${({ active, dropdown }) => (active || dropdown) && '.3rem 0'};
  border-radius: .2rem;
  flex-grow: 1;
  width: 3rem;
  /* max-width: ${({ dropdown }) => !dropdown && '2rem'}; */
`;

export const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
`;

export const Subtitle = styled.h2`
  color: ${Colors.secondaryBlack};
  font-size: .8rem;
  width: 100%;
  height: 1rem;
`;

export const Label = styled(Modal.Label)`
  color: #016ec2;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media(max-width: 1024px) {
    width: 100%;
  }
`;

export const DatePicker = styled(DatePickerComponent)`
  border: 1px solid #016ec2;
  border-radius: 2px;
  font-size: 0.9rem;
  opacity: 0.8;
  
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 90%;

  .ant-picker-input > * {
    color: #016ec2;
  }

  .ant-picker-input > input::placeholder {
    color: #016ec2;
  }
`;

export const DatesWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const RadioButton = styled(Radio.Button)`
  &.ant-radio-group-solid, &.ant-radio-button-wrapper-checked {
    background-color: ${Colors.secondaryBlue} !important;
    border-color: ${Colors.secondaryBlue} !important;
  }

  &:not(.ant-radio-button-wrapper-checked):hover {
    color: ${Colors.secondaryBlue} !important;
  }
`;

export const Disabled = styled.p`
  color: ${Colors.grey};
  width: 100%;
  margin: 0;
  margin-top: 1rem;
  text-align: left;
`;