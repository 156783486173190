import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Card = styled.button`
  display: flex;
  justify-content: flex-start;
  border: none;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  height: 8rem;
  margin: 1rem;
  margin-left: 0px;
  margin-right: 2rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: .3rem;
  position: relative;

  &:hover {
    .delete-button {
      margin-top: -2.2rem;
      max-height: 2.2rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Type = styled.span`
  padding: .1rem .3rem;
  background-color: ${Colors.secondaryBlue};
  color: ${Colors.mainWhite};
  border-radius: .3rem;
  font-size: .6rem;
  margin: .3rem 0;
`;

export const Title = styled.h2`
  color: ${Colors.secondaryBlack};
  font-weight: bolder;
  font-size: ${({ isText }) => (isText ? '.8rem' : '1rem')};
  width: 100%;
  margin-bottom: 0;
  margin-top: .6rem;
  max-width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
