import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-bottom: 1.3rem;
`;

const NewButton = styled.button`
  background-color: ${Colors.blue};
  padding: .3rem 2.6rem;
  outline: none;
  border: none;
  border-radius: .2rem;
  font-style: italic;
  color: #fff;
  white-space: nowrap;
  visibility: ${({ hasButton }) => (hasButton ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  cursor: ${({ show }) => (show ? 'pointer' : 'unset')};
`;

const OptionsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 .3rem; */
  border: 1px solid ${(props) => (props.active ? Colors.blue : 'transparent')};
  border-radius: .2rem;
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  color: black;
  outline: none;
  padding: 0 .3rem;
  width: 100%;
  max-width: 10rem;
  box-sizing: border-box;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
`;

const Image = styled.img`
  cursor: pointer;
  background-color: transparent;
  margin: 0 .3rem;
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const Options = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${Colors.mainWhite};
  max-width: ${({ active }) => (active ? '10rem' : '0rem')};
  max-height: ${({ active }) => (active ? 'unset' : '0rem')};
  transition: max-width .5s ease-in-out, max-height .5s ease-in-out;
  overflow: hidden;
  z-index: 4;
`;

const OptionButton = styled(Button)`
  width: 100%;
  color: #000000;
  opacity: 0.75;
  text-align: left;
  padding: 10px;
  white-space: nowrap;

  &:hover {
    background-color: #eee;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Filter = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  margin:  0 0 .6rem;
  max-height: 0rem;
  transition: max-height .7s ease-in-out;
  overflow: hidden;
  max-height: ${({ active }) => (active && '5rem')};
  gap: 10px;
`;

const FilterItem = styled.button`
  border-radius: 50px;
  padding: .1rem .5rem;
  background-color: ${Colors.mainWhite};
  color: ${Colors.secondaryBlue};
  border: 1px solid ${Colors.secondaryBlue};
  height: min-content;
  
  outline: none;

  ${({ active }) => active && `
    background-color: ${Colors.secondaryBlue};
    color: ${Colors.mainWhite};
    border: 1px solid ${Colors.mainWhite};
  `}
`;

export {
  Header,
  NewButton,
  OptionsContainer,
  InputContainer,
  Input,
  Button,
  Image,
  Text,
  Options,
  OptionButton,
  ContentContainer,
  Filter,
  FilterItem,
};
