import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import {
  PaginatedList,
  UserCard,
  UserModal,
  Title,
  SearchHeader,
} from '../../components';
import {
  Panel,
  ContentContainer,
} from './styles';
import { useAxios } from '../../services/api';
import { stringifyFilter } from '../../utils';
import { useUserInfo } from '../../state/UserContext';

function Users() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);
  const [roles, setRoles] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [machines, setMachines] = useState(null);

  const [userInfo] = useUserInfo();

  const [axiosGet] = useAxios('get');

  const [reloadList, setReloadList] = useState(() => () => { });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userInfo) return;

        console.log(userInfo);
        const promises = [
          (userInfo?.isAdmin ? axiosGet({ url: '/companies' }) : axiosGet({ url: `/companies/${userInfo?.companyId}` })),
          axiosGet({ url: '/roles' }),
          axiosGet({ url: '/machines' }),
        ];

        const [companiesRes, rolesRes, machinesRes] = await Promise.all(promises);

        if (companiesRes) {
          if (userInfo?.isAdmin) {
            const companyOptions = companiesRes.data.data.map(({ _id, name }) => ({
              value: _id,
              label: name,
            }));
            setCompanies(companyOptions);
          } else {
            setCompanies([{
              label: companiesRes.data.data.name,
              value: companiesRes.data.data._id,
            }]);
          }
        }

        const roleOptions = rolesRes.data.data.map(({ _id, name }) => ({
          value: _id,
          label: name.charAt(0).toUpperCase() + name.slice(1),
        }));
        setRoles(roleOptions);

        const machineOptions = machinesRes.data.data.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        setMachines(machineOptions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [axiosGet, userInfo]);

  const filterArr = stringifyFilter(filter || []);
  const filterUrl = filter ? `${filterArr.join('&')}` : '';

  const url = `/users?${filterUrl}&search=${search}`;

  return (
    <Panel>
      <Title title="Usuários" />
      <ContentContainer>
        <SearchHeader
          filter={filter}
          setFilter={setFilter}
          setSearch={setSearch}
          filterOptions={userInfo?.isAdmin ? ['Ordenar A-Z', 'Cargo', 'Empresa'] : ['Ordenar A-Z', 'Cargo']}
          type="user"
        >
          {(active, setActive) => (
            <UserModal
              active={active}
              setActive={setActive}
              options={{ title: 'Novo Usuário', buttonValue: 'Finalizar' }}
              type="create"
              roles={roles}
              companies={companies}
              reloadList={reloadList}
            />
          )}
        </SearchHeader>
        <PaginatedList
          component={UserCard}
          url={url}
          options
          itemsPerPage={10}
          otherProps={{ companies, roles, machines }}
          setReloadList={setReloadList}
          process={useCallback(async (data) => {
            const userPromises = data.map(({ roleId }) => axiosGet({ url: `/roles/${roleId}` }));
            const userResults = await Promise.all(userPromises);

            const newData = data.map((user, i) => {
              const id = userResults[i];
              return {
                ...user,
                type: id?.data?.data?.name || 'deleted_role',
              };
            });

            return newData;
          }, [axiosGet])}
        />
      </ContentContainer>
    </Panel>
  );
}

export default Users;
