import styled from 'styled-components';
import { Modal } from 'antd';

import colors from '../../../../styles/Colors';

export const CategoryCardContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const CategoryCard = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  background: #fff;

  display: flex;
  align-items: center;

  transition: left 0.3s;

  left: ${({ shouldDeleteShow }) => (shouldDeleteShow ? '80px' : 0)};
`;

export const CategoryIcon = styled.img`
  width: 40px;
  margin: 0 30px;
`;

export const CategoryDelete = styled.button`
  height: 100%;
  width: 80px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditButton = styled.button`
  font-size: 1.1rem;
  color: ${colors.secondaryBlue};
  background: transparent;
  border: none;
`;

export const StyledModal = styled(Modal)``;
