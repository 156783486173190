import React, { useState, useEffect, useRef } from 'react';

import {
  Card,
  Header,
  Type,
  Check,
  Title,
  Subtitle,
  Time,
  Image,
  DeleteButton,
  MediaContainer,
  TimeContainer,
} from './styles';
import WarnModal from '../WarnModal';

import checkmarkIcon from './assets/checkmark.svg';
import clockIcon from './assets/clock.svg';
import deleteIcon from './assets/delete.svg';
import videoIcon from './assets/video.svg';
import timerBlue from './assets/timerBlue.png';
import timerRed from './assets/timerRed.png';

function StaticAdCard({
  type,
  title,
  subtitle,
  time,
  img,
  verified,
  machine,
  startDate,
  endDate,
  handleDelete = () => { },
}) {
  const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAIO', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

  const [deleted] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const [startDateString, setStartDateString] = useState('');
  const [endDateString, setEndDateString] = useState('');

  const [controls, setControls] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (startDate) {
      const dateStartDate = new Date(startDate);

      const dd = dateStartDate.getDate();
      const mm = dateStartDate.getMonth();
      const yyyy = dateStartDate.getFullYear();
      const hours = dateStartDate.getHours();
      const minutes = dateStartDate.getMinutes();

      setStartDateString(`${dd}/${months[mm]}/${yyyy} - ${hours / 10 >= 1 ? hours : `0${hours}`}:${minutes / 10 >= 1 ? minutes : `0${minutes}`}`);
    }

    if (endDate) {
      const dateStartDate = new Date(endDate);

      const dd = dateStartDate.getDate();
      const mm = dateStartDate.getMonth();
      const yyyy = dateStartDate.getFullYear();
      const hours = dateStartDate.getHours();
      const minutes = dateStartDate.getMinutes();

      setEndDateString(`${dd}/${months[mm]}/${yyyy} - ${hours / 10 >= 1 ? hours : `0${hours}`}:${minutes / 10 >= 1 ? minutes : `0${minutes}`}`);
    }
  }, []);

  useEffect(() => {
    if (img.indexOf('.mp4') === -1) {
      setImageSrc(img);
    } else {
      setVideoSrc(img);
    }
  }, [img]);

  const handleVideoClick = () => {
    videoRef.current.play();
    setControls(true);
  };

  if (deleted) return null;

  return (
    <Card>
      <Header>
        <Type>{type}</Type>
        {verified && <Check src={checkmarkIcon} />}
      </Header>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>

      {startDateString && (
        <TimeContainer>
          <img src={timerBlue} alt="relógio" />
          {startDateString}
        </TimeContainer>
      )}
      {endDateString && (
        <TimeContainer>
          <img src={timerRed} alt="relógio" />
          {endDateString}
        </TimeContainer>
      )}

      {type !== 'Billboard' && (
        <MediaContainer>
          {imageSrc && <Image className="image" src={imageSrc} />}
          {videoSrc && (
            <video
              poster={videoIcon}
              onClick={handleVideoClick}
              style={{ margin: '.3rem', height: '6rem', cursor: 'pointer' }}
              width="100%"
              preload="none"
              controls={controls}
              ref={videoRef}
              muted
              loop
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          )}
        </MediaContainer>
      )}
      <Time>
        {time}
      </Time>

      <DeleteButton className="delete-button" onClick={() => setDeleting(true)}>
        <Check className="delete-button" src={deleteIcon} />
      </DeleteButton>
      <WarnModal
        active={deleting}
        setActive={setDeleting}
        title="Desvincular Anúncio?"
        text={(
          <>
            Tem certeza que deseja desvincular o anúncio
            {' '}
            <span>{title}</span>
            {' '}
            da máquina
            {' '}
            <span>{machine.name}</span>
            ?
          </>
        )}
        accept={handleDelete}
      />
    </Card>
  );
}

export default StaticAdCard;
