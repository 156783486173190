import styled from 'styled-components';
import colors from '../../styles/Colors';

export const MachinesView = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  width: 100%;
  height: 70vh;
  background-color: ${colors.background};
`;

export const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: .3rem 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: min-content;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

export const SelectDropDown = styled.div`
  max-height: ${({ active }) => (active ? '10rem' : '0')};
  transition: max-height 1.5s ease-out;
  /* overflow: hidden; */
`;

export const SlotCardShow = styled.div`
  border: 1px solid ${colors.secondaryBlue};
  padding: 15px 10px;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
`;

export const SlotCardTitle = styled.p`
  color: ${colors.secondaryBlue};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.32px;
  text-align: left;
  margin: 0;
`;

export const SlotCardQuantity = styled.p`
  margin: 0;
  text-align: left;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.67;
  letter-spacing: 0.24px;
  text-align: left;
  color: black;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 .3rem;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 .3rem;
  border: 1px solid ${colors.secondaryBlue};
  padding: .3rem;
  width: 30px;
  border-radius: .2rem;
  flex-grow: 1;
  max-width: ${({ dropdown }) => !dropdown && '2rem'};
`;

export const Image = styled.img`
  background-color: transparent;
  transition: transform .5s ease-out;
  transform: ${({ upside }) => upside && 'rotate(180deg)'};
  margin: ${({ noMargin }) => !noMargin && '0 .3rem'};
`;
