import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import Modal from '../Modal';
import {
  AlternativesContainer,
  RadioButton,
  AlternativesInputContainer,
  AlternativeTag,
} from './styles';

const initialQuestionValues = {
  content: '',
  type: 'selection-box',
  alternatives: [],
};

function QuestionModal({
  modalTitle = '',
  handleSubmit,
  question = initialQuestionValues,
  active,
  setActive,
}) {
  const [displayQuestion, setDisplayQuestion] = useState(question);
  const displayAlternatives = displayQuestion.alternatives || [];

  const [currentAlternativeValue, setCurrentAlternativeValue] = useState('');

  useEffect(() => {
    setDisplayQuestion(question);
  }, [question]);

  const changeDisplayQuestion = (newDisplayQuestion) => {
    setDisplayQuestion((prevDisplayQuestion) => ({
      ...prevDisplayQuestion,
      ...newDisplayQuestion,
    }));
  };

  const handleAddAlternative = () => {
    if (currentAlternativeValue === '') return;
    const newAlternatives = [...displayAlternatives, currentAlternativeValue];

    const newDisplayQuestion = {
      ...displayQuestion,
      alternatives: newAlternatives,
    };

    changeDisplayQuestion(newDisplayQuestion);
    setCurrentAlternativeValue('');
  };

  const handleRemoveAlternativeByIndex = (index) => {
    const newAlternatives = [...displayAlternatives];

    newAlternatives.splice(index, 1);

    changeDisplayQuestion({
      ...displayQuestion,
      alternatives: newAlternatives,
    });
  };

  const onSubmit = () => {
    handleSubmit(displayQuestion);
    setDisplayQuestion(question);
  };

  return (
    <Modal handleSubmit={onSubmit} active={active} setActive={setActive}>
      <Modal.Title>{modalTitle}</Modal.Title>
      <Modal.TextArea
        value={displayQuestion.content}
        setValue={(value) => changeDisplayQuestion({ content: value })}
        placeholder="Conteúdo da pergunta"
      />
      <AlternativesInputContainer>
        <Modal.Input
          value={currentAlternativeValue}
          setValue={(value) => setCurrentAlternativeValue(value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddAlternative();
              e.preventDefault();
            }
          }}
          onBlur={handleAddAlternative}
          placeholder="Adicionar alternativa"
        />
        <AlternativesContainer>
          {displayAlternatives.map((alternative, index) => (
            <AlternativeTag
              key={`${alternative}-${Math.random().toString()}`}
              closable
              onClose={() => handleRemoveAlternativeByIndex(index)}
            >
              {alternative}
            </AlternativeTag>
          ))}
        </AlternativesContainer>
      </AlternativesInputContainer>
      <Radio.Group
        buttonStyle="solid"
        value={displayQuestion.type}
        onChange={(event) => changeDisplayQuestion({ type: event.target.value })}
        style={{ margin: '10px' }}
      >
        <RadioButton value="selection-box">Caixa de seleção</RadioButton>
        <RadioButton value="multiple-choice">Múltipla escolha</RadioButton>
      </Radio.Group>
      <Modal.Button>Finalizar</Modal.Button>
    </Modal>
  );
}

export default QuestionModal;
