import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  flex-grow: 1;
  overflow: auto;
  padding: 2rem 0 0;
`;

const List = styled.div`
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    flex-grow: 1;
  }
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: white;
  @media only screen and (max-width: 1000px) {
    margin: .6rem;
  }
  @media only screen and (min-width: 600px) {
    display: ${({ icon }) => !icon || 'none'};
  }
`;

const Image = styled.img`
  margin: ${({ icon }) => (icon ? '0rem' : '.6rem')};
  ${({ avatar }) => avatar && `
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  `}
`;

const Card = styled.div`
  border-radius: .3rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1.3rem;
  margin: .6rem;
  transition: box-shadow .3s ease-in-out;
  border: 1px solid transparent;
  box-shadow: 0 0 .4rem 0 ${Colors.mainBlack};

  @media only screen and (min-width: 600px) {
    &:hover {
      border: 1px solid ${Colors.blue};

      .icon {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    border-radius: 0rem;
    margin: 0;
    width: 100%;
    box-shadow: none;
    padding: 0;
    box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid #ccc;
  }
`;

const Text = styled.p`
  color: ${({ black }) => (black ? 'black' : Colors.secondaryBlue)};
  font-style: ${({ italic }) => italic && 'italic'};
  margin: 0;
  font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  width: 100%;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;

  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    :first-child {
      width: 20rem;
    }
    :nth-child(2) {
      width: 8rem;
    }
  }

  @media only screen and (max-width: 600px) {
    font-size: ${({ isTitle }) => (isTitle ? '1rem' : '.8rem')};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export {
  Container,
  List,
  Button,
  Image,
  Card,
  Text,
  TextContainer,
};
