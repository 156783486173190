import React from 'react';
import { Switch } from 'antd';

import {
  Wrapper,
  Container,
  Text,
} from './styles';

function SwitchContainer({ options }) {
  return (
    <Wrapper>
      {options?.map(({ name, state, setState }, index) => (
        <Container key={name}>
          <Text>{name}</Text>
          <Switch checked={state} onClick={(val) => setState(val, index)} />
        </Container>
      ))}
    </Wrapper>
  );
}

export default SwitchContainer;
