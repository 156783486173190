import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router';

import {
  MachineSection,
  Button,
  Header,
  Image,
  Title,
  TitleContainer,
  Dash,
  Container,
  Alternator,
  NavHeader,
  Tab,
  Text,
  Indicator,
  MobileCollapse,
  MobilePanel,
  WebNavigation,
} from './styles';
import { useAxios } from '../../services/api';
import {
 AdList, Slots, Alerts, PaymentTypeEnable, Questions, QrCode, Locks,
} from '../../components';
import { useMachineDetailContext } from '../../state/MachineDetailContext';
import socket from '../../services/socket';

import backIcon from './assets/back.svg';
import doorIcon from './assets/door.svg';
import openDoorIcon from './assets/door-open.svg';
import productIcon from './assets/product.svg';
import switchIcon from './assets/switch.svg';
import wifiIcon from './assets/wifi.svg';
import { useUserInfo } from '../../state/UserContext';

function MachineDetail({ location }) {
  const [redirect, setRedirect] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [isPromoter, setIsPromoter] = useState(false);

  const [machine, reloadMachineDetails] = useMachineDetailContext();
  const [userInfo] = useUserInfo();

  const [axiosGet] = useAxios('get');

  useEffect(() => {
    const getRole = async () => {
      if (userInfo) {
        const request = await axiosGet({url: `roles/${userInfo?.roleId}`});
        const role = request.data.data.name;
        setIsPromoter(role === "Promoter");
      }
    }
    getRole();
  }, [userInfo]);

  useEffect(() => {
    const navbarImg = document.querySelector('#logoM');
    navbarImg.style.display = 'none';
    return () => { navbarImg.style.display = 'block'; };
  }, []);

  useEffect(() => {
    socket.on('doorSensor', (event) => {
      setIsOpen(event.isOpen);
    });
  }, []);

  useEffect(() => {
    if (machine) {
      axiosGet({
        url: `/groups/${machine.groupId}`,
        setState: setCompanyName,
        process: ({ name }) => name,
      });
    }
  }, [machine, axiosGet]);

  if (redirect) return <Redirect to={redirect} />;

  const componentArr = [
    ...(isPromoter ? [AdList] : [
    AdList,
    Slots,
    // Alerts,
    PaymentTypeEnable,
    // Questions,
    QrCode,
    Locks,])
  ];

  const headerTabs = [
    ...(isPromoter ? [`Anúncios`] : [
    `Anúncios`,
    `Slots (${machine.slots.length})`,
    // `Alertas (${machine.usersIds.length})`,
    'Pagamento',
    // 'Perguntas',
    'QrCode',
    'Travas',
    ])
  ];

  const CompToRender = componentArr[listIndex];

  const handleIndexChange = (index) => {
    if (listIndex !== index) setListIndex(index);
  };
   
  return (
    <MachineSection>
      <Header>
        <Button type="button" onClick={() => setRedirect('/maquinas')}>
          <Image src={backIcon} />
        </Button>

        <TitleContainer>
          <Title>{companyName}</Title>
          <Dash />
        </TitleContainer>
      </Header>

      <Container>
        <Title machineName>
          {machine?.name}
          {' '}
          {/* (Cº) */}
          <div style={{ display: 'flex', margin: '0 .4rem' }}>
            {/* <Image style={{ margin: '0 .4rem' }} src={switchIcon} /> */}
            <Image style={{ margin: '0 .4rem' }} src={wifiIcon} />
            <Image style={{ margin: '0 .4rem' }} src={isOpen ? openDoorIcon : doorIcon} />
            <Image style={{ margin: '0 .4rem' }} src={productIcon} />
          </div>
        </Title>
        <Alternator>

          <WebNavigation>
            <NavHeader>
              {headerTabs.map((tab, i) => (
                <Tab key={tab} onClick={() => handleIndexChange(i)}>
                  <Text active={listIndex === i}>{tab}</Text>
                  <Indicator active={listIndex === i} />
                </Tab>
              ))}
            </NavHeader>

            <CompToRender
              slotConfig={machine.slots}
              machine={machine}
              companyId={location.pathname.split('/')[2]}
              reloadMachineDetails={reloadMachineDetails}
            />
          </WebNavigation>

          <MobileCollapse expandIconPosition="right">
            {componentArr.map((ChildComponent, index) => (
              <MobilePanel key={index} header={headerTabs[index]}>
                <ChildComponent
                  slotConfig={machine.slots}
                  machine={machine}
                  companyId={location.pathname.split('/')[2]}
                  reloadMachineDetails={reloadMachineDetails}
                />
              </MobilePanel>
            ))}
          </MobileCollapse>
        </Alternator>
      </Container>
    </MachineSection>
  );
}

export default withRouter(MachineDetail);
