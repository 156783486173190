import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  max-height: min-content;
  margin: 1rem;
  padding: 1rem;
  border-radius: .3rem;
  position: relative;

  &:hover {
    .delete-button {
      max-height: 3rem;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Type = styled.span`
  padding: .1rem .3rem;
  background-color: ${Colors.secondaryBlue};
  color: ${Colors.mainWhite};
  border-radius: .3rem;
  font-size: .6rem;
  margin: .3rem 0;
`;

const Check = styled.img`
  margin: .3rem;
`;

const Title = styled.h2`
  color: ${Colors.secondaryBlack};
  font-weight: bolder;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 0;
  margin-top: .6rem;
`;

const Subtitle = styled.h2`
  color: ${Colors.secondaryBlack};
  font-size: .8rem;
  width: 100%;
`;

const Time = styled.p`
  color: ${Colors.secondaryBlack};
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
  text-align: right;
`;

const Image = styled.img`
  margin: .3rem;
  width: 100%;
  max-height: 7rem;
  object-fit: contain;
`;

const DeleteButton = styled.button`
  position: absolute;
  bottom: 0;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  background-color: white;
  transition: max-height .5s ease-in-out;
  max-height: 0;
  overflow: hidden;
`;

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.blue};
  margin: .3rem 0;
  height: 6rem;
  overflow: hidden;
`;

const TimeContainer = styled.div`
  float: left;
  width: 100%;
  color: #4d4d4d;

  img {
    margin-right: 10px;
  }
`;

export {
  Card,
  Header,
  Type,
  Check,
  Title,
  Subtitle,
  Time,
  Image,
  DeleteButton,
  MediaContainer,
  TimeContainer,
};
