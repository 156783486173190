import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useUserInfo } from '../../../state/UserContext';

import { useAxios } from '../../../services/api';
import { Modal } from '../../../components';

import { useErrorContext } from '../../../state/ErrorContext';

import Renderer from './Renderer';

import {
  NewCategoryButton,
  NewCategoryContainer,
  TagsInstruction,
  TypeAhead,
  ProductTag,
  DropdownComponent,
  DropdownMenu,
  DropdownButton,
  ArrowDown,
  IconButton,
} from '../styles';

function CategorySection({
  categories, categoryIcons, setCategories, categorySuggestions, setCategorySuggestions,
}) {
  const [axiosGet, axiosPost, axiosPatch] = useAxios('get', 'post', 'patch');
  const [, setError] = useErrorContext();

  const [userInfo] = useUserInfo();

  const [categoryName, setCategoryName] = useState('');
  const [categoryFile, setCategoryFile] = useState(null);
  const [categoryTypeahead, setCategoryTypeahead] = useState('');
  const [itemsToAdd, setItemsToAdd] = useState([]);

  const [modalSelectedCategoryIcon, setModalSelectedCategoryIcon] = useState({});

  const [shouldCategoryModalShow, setShouldCategoryModalShow] = useState(false);
  const [targetEditModal, setTargetEditModal] = useState('');

  useEffect(() => {
    if (!categoryTypeahead) return;

    const fetchProducts = async () => {
      const { data: productsData } = await axiosGet({
        url: `/products?search=${categoryTypeahead}`,
      });

      const { data: itemsData } = await axiosGet({
        url: `/items?search=${categoryTypeahead}`,
      });

      setCategorySuggestions(itemsData.data
        .map((specificItem) => {
          const productIndex = productsData.data.findIndex((sepecificProduct) => (
            sepecificProduct._id === specificItem.productId
          ));

          return {
            ...specificItem,
            image: productsData.data[productIndex].image,
            name: productsData.data[productIndex].name,
          };
        })
        .filter(
          (specificItem) => itemsToAdd.findIndex((suggestion) => (
            suggestion._id === specificItem._id
          )) === -1,
        )
        .map((specificItem) => ({
          value: specificItem.name,
          _id: specificItem._id,
        })));
    };

    fetchProducts();
  }, [categoryTypeahead]);

  return (
    <>
      <NewCategoryContainer>
        <NewCategoryButton
          type="primary"
          onClick={() => setShouldCategoryModalShow(true)}
        >
          Novo
        </NewCategoryButton>
      </NewCategoryContainer>

      <DndProvider backend={HTML5Backend}>
        <Renderer
          categories={categories}
          setCategories={setCategories}
          setTargetEditModal={setTargetEditModal}
          setShouldCategoryModalShow={setShouldCategoryModalShow}
          setCategoryName={setCategoryName}
          setItemsToAdd={setItemsToAdd}
          categoryIcons={categoryIcons}
          setModalSelectedCategoryIcon={setModalSelectedCategoryIcon}
        />
      </DndProvider>

      <Modal
        active={shouldCategoryModalShow}
        setActive={setShouldCategoryModalShow}
        handleSubmit={async () => {
          if (!targetEditModal) {
            if (
              !categoryName
              || !modalSelectedCategoryIcon.enabledImage
            ) {
              return setError({ message: 'Nome e imagem são obrigatórios' });
            }

            if (categories.findIndex(
              (category) => category.name.toLowerCase() === categoryName.toLowerCase(),
            ) !== -1) {
              return setError({ message: 'Já existe uma categoria com esse nome' });
            }

            const body = {
              name: categoryName,
              companyId: userInfo.companyId,
              items: JSON.stringify(itemsToAdd.map((itemToAdd) => itemToAdd._id)),
              icon: modalSelectedCategoryIcon,
            };

            const { data: categoryData } = await axiosPost({
              url: '/categories',
              body,
            });

            setCategorySuggestions([]);
            setCategoryName('');
            setCategoryFile(null);
            setCategoryTypeahead('');
            setItemsToAdd([]);
            setShouldCategoryModalShow(false);
            setModalSelectedCategoryIcon({});
            setTargetEditModal('');
            return setCategories([
              ...categories,
              categoryData.data,
            ]);
          }

          if (!categoryName) {
            return setError({ message: 'O campo "nome" é obrigatório' });
          }

          const indexSameName = categories.findIndex(
            (category) => category.name.toLowerCase() === categoryName.toLowerCase(),
          );

          if (
            indexSameName !== -1
            && categories[indexSameName]._id !== targetEditModal
          ) {
            return setError({ message: 'Já existe uma categoria com esse nome' });
          }

          const body = {
            name: categoryName,
            companyId: userInfo.companyId,
            items: JSON.stringify(itemsToAdd.map((itemToAdd) => itemToAdd._id)),
            icon: modalSelectedCategoryIcon,
          };

          const { data: categoryData } = await axiosPatch({
            url: `/categories/${targetEditModal}`,
            body,
          });

          const { data: updatedCategory } = categoryData;

          const updatedCategories = categories.map((category) => {
            if (category._id === updatedCategory._id) {
              return updatedCategory;
            }

            return category;
          });

          setCategories(updatedCategories);

          setCategorySuggestions([]);
          setCategoryName('');
          setCategoryFile(null);
          setCategoryTypeahead('');
          setItemsToAdd([]);
          setTargetEditModal('');
          setShouldCategoryModalShow(false);
          return null;
        }}
        onCancel={() => {
          setCategorySuggestions([]);
          setCategoryName('');
          setCategoryFile(null);
          setCategoryTypeahead('');
          setItemsToAdd([]);
          setTargetEditModal('');
          setShouldCategoryModalShow(false);
        }}
      >
        <Modal.Title>{targetEditModal ? 'Editar Categoria' : 'Nova Categoria'}</Modal.Title>
        <Modal.Input
          placeholder="Nome da categoria"
          value={categoryName}
          onChange={(e) => {
            setCategoryName(e.target.value);
          }}
        />

        <DropdownComponent
          overlay={(
            <DropdownMenu>
              {categoryIcons.map((categoryIcon) => (
                <IconButton
                  image={modalSelectedCategoryIcon._id === categoryIcon._id ? (
                    categoryIcon.enabledImage
                  ) : (
                    categoryIcon.disabledImage
                  )}
                  alt=""
                  key={categoryIcon._id}
                  onClick={() => {
                    setModalSelectedCategoryIcon(categoryIcon);
                  }}
                />
              ))}
            </DropdownMenu>
          )}
          trigger={['click']}
        >
          <DropdownButton isSelected={!!modalSelectedCategoryIcon._id}>
            {modalSelectedCategoryIcon._id ? (
              'Selecionado'
            ) : (
              'Escolha um ícone...'
            )}
            <ArrowDown />
          </DropdownButton>

        </DropdownComponent>

        <TagsInstruction>Adicione produtos a esta categoria</TagsInstruction>

        <TypeAhead
          placeholder="Nome do produto"
          value={categoryTypeahead}
          onChange={setCategoryTypeahead}
          options={categorySuggestions}
          onSelect={(name, fullObject) => {
            setItemsToAdd([...itemsToAdd, fullObject]);
            setCategoryTypeahead('');
          }}
          style={{
            width: '100%',
            marginBottom: 20,
          }}
        />

        {itemsToAdd.map((productToAdd) => (
          <ProductTag
            key={`product-to-add-${productToAdd._id}`}
            closable
            onClose={() => {
              setItemsToAdd(itemsToAdd.filter((eachProduct) => (
                eachProduct._id !== productToAdd._id
              )));
            }}
          >
            {productToAdd.value}
          </ProductTag>
        ))}

        <Modal.Button>Criar</Modal.Button>
      </Modal>
    </>
  );
}

export default CategorySection;
